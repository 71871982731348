import React from "react";
import { useLocation } from "react-router-dom";
export interface Props {title:string;name:string;setData:any;value:string}
export const SingleInputBox:React.FC<Props> = ({title,name,setData, value}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const handeChange = (e:any) => {
        const {name, value} = e.target;
        setData((prev:any)=>({...prev, [name] : value}))
    }
    return(
        <>
        <tr>
            <th>{title}</th>
            <td>
                <input 
                    type="text"
                    name={name}
                    onChange={handeChange}
                    id={name}
                    value={value}
                    placeholder={`사용자에게 보여줄 ${title}을(를) 입력해 주세요.`}
                    disabled={path[2]==='reporting'? true : false}
                />
            </td>
        </tr>
        </>
    )
}