import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { Paginate } from "../../../../../../components/paginate/paginate_admin";
import { useGetProductPermissionHistoryMutation } from "../../../../../../store/admin/adminContentsApiSlice";
import { ListFilter } from "../list-element/list-filter";
import { ListHeader } from "../list-element/list-header";
export interface Props {data : any; getList : any}
export const ObesityMedicine:React.FC<Props> = ({data, getList}) => {
    const navigate = useNavigate()
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx}>
                
                <td onClick={()=>navigate(`/dotsAdmin/products/O/${list?.idx}`)}>{list?.permissionMdate}</td>

                <td onClick={()=>navigate(`/dotsAdmin/products/O/${list?.idx}`)}>{list?.permissionSubjectKr?.length < 80 ? list?.permissionSubjectKr : list?.permissionSubjectKr.slice(0,80)+'...'}</td>

                <CheckBox_Admin idx={list?.idx} openYn={list?.productActiveStatus} getList={getList}/>

            </tr>
        ))}
        </tbody> 
        </>
    )
}