import React from "react";
import { useTranslation } from "react-i18next";

export const CellLine = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_1 active">
            <h4>{t("cdo_tab_1")}</h4>
            <p>
            {t("cdo_cellline_title_1")}
            </p>
            <div className="flexBox">
                <div className="left">
                    <ul className="checkList">
                        <li>{t("cdo_cellline_cotent_1")}</li>
                        <li>{t("cdo_cellline_cotent_2")}</li>
                        <li>
                        {t("cdo_cellline_cotent_3")}
                        </li>
                        <li>{t("cdo_cellline_cotent_4")}</li>
                        <li>{t("cdo_cellline_cotent_5")}</li>
                        {/* <li>{t("cdo_cellline_cotent_6")}</li> */}
                    </ul>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/cell_line_development.png" alt="세포주 개발"/>
                </div>
            </div>

            <div>
                <h5>
                    Process Flow
                    <span>Cell Line Development</span>
                </h5>
                <ul className="listBox">
                    <li>
                        <div>
                            <img src="/assets/images/CLD_1.png" alt="Expression vector construction"/>
                        </div>
                        <div>
                            <span>
                                Expression vector<br/>
                                construction
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/CLD_2_2.png" alt="Transfection & stable pool selection"/>
                        </div>
                        <div>
                            <span>
                                Transfection &<br/>
                                stable pool selection
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/CLD_3.png" alt="Single cell cloning ClonePix & LDC"/>
                        </div>
                        <div>
                            <span>
                                Single cell cloning<br/>
                                ClonePix & LDC  
                            </span>
                        </div>
                        
                        <div className="gray">
                            <span>&bull;&nbsp; Monoclonality</span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/CLD_4.png" alt="Fed-batch test"/>
                        </div>
                        <div>
                            <span>
                                Performance test<br></br>
                                via Fed-batch culture
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/CLD_5_2.png" alt="Stablilty test"/>
                        </div>
                        <div>
                            <span>RCB Generation</span>
                        </div>
                        <div className="gray">
                            <span>
                                &bull;&nbsp; Mycoplasma test <br></br>
                                &bull;&nbsp; Virus testing(MMV)
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/CLD_6.png" alt="Stablilty test"/>
                        </div>
                        <div>
                            <span>Stablilty test</span>
                        </div>
                    </li>
                </ul>
            </div>

        </div>
        </>
    )
}