import React, { useState, useEffect, useRef } from "react";

export const Test = () => {
    const scrollRef = useRef<any>([])
    const handleIntersection = (entries:any) => {
        entries.forEach((entry:any) => {
          const index = scrollRef.current.indexOf(entry.target);
          if (entry.isIntersecting) {
            entry.target.classList.add('active');
          } else {
            entry.target.classList.remove('active');
          }
        });
      };
    
      useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });
    
        scrollRef.current.forEach((element:any) => {
          observer.observe(element);
        });
    
        return () => observer.disconnect();
      }, []);
    return(
        <>
        <div style={{height : '1000px'}} ref={e=>scrollRef.current[0]=e}>1</div>
        <div ref={e=>scrollRef.current[1]=e}>2</div>
        <div ref={e=>scrollRef.current[2]=e}>3</div>
        <div ref={e=>scrollRef.current[3]=e}>4</div>
        <div ref={e=>scrollRef.current[4]=e}>5</div>
        <div ref={e=>scrollRef.current[5]=e}>6</div>
        </>
    )
}