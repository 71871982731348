import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Paginate } from "../../../../components/paginate/paginate_admin";
import { PaginateUser } from "../../../../components/paginate/paginate_user";
import { useGetFinanceSettlementByUserMutation, useUpdFinanceSettlementViewCntMutation } from "../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../util/cookie/cookies";

export const ElectronicNotice = () => {
    const {t} = useTranslation()
    const location = useLocation()
    const page = new URLSearchParams(location.search).get('page') || 1;
    const navigate = useNavigate()
    const lang = getCookie('BINEXLANG')
    const [getFinanceSettlementByUserApi] = useGetFinanceSettlementByUserMutation()
    const [updFinanceSettlementViewCntApi]  = useUpdFinanceSettlementViewCntMutation()
    const [data, setData] = useState<any>([])
    const [totalCount , setTotalCount] = useState<number>(0)
    const [param, setParam] = useState<any>({page : page, size : 10, useLang : ''})
    const getList=async()=>{
        const result : any = await getFinanceSettlementByUserApi(param)
        if(result.data.Result==='true'){setData(result.data.List); setTotalCount(result.data.totalCnt)}
    }
    const handlePage = (pageNumber: number) => {
        navigate(`/invest/electronicNotice?page=${pageNumber}`)
    }
    const handleTransView = async(id : string) => {
        const formData : any = new FormData()
        formData.append('idx', id)
        const result : any = await updFinanceSettlementViewCntApi(formData)
        if(result.data.result===true){
            navigate(`/invest/electronicNotice/${id}`)
        }
    }
    const calculateIndex = (currentPage:any, pageSize:number, totalCount:number, itemIndex:number) => {
        const lastIndex = totalCount - (currentPage - 1) * pageSize;
        return lastIndex - itemIndex;
    };
    useEffect(()=>{setParam((prev:any)=>({...prev, page : page, useLang : lang==='Kr'?'KR':'EN'}))}, [page, lang])
    useEffect(()=>{getList()},[param.page, param.useLang])
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>바이넥스의 <span>사업성과를 공개합니다.</span></h3>
                : 
                <h3>Binex discloses its <span>business performance</span></h3>
                }
                
                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>{t("invest_electronic_notice_1")}</th>
                                <th>{t("invest_electronic_notice_2")}</th>
                                <th>{t("invest_electronic_notice_3")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((list:any, index: number) => (
                            <tr key={list?.idx} onClick={()=>handleTransView(list?.idx)}>
                                <td>
                                    {calculateIndex(page, param.size, totalCount, index)}
                                </td>
                                <td>
                                    <p>{lang==='Kr'?list?.fs_subject_kr:list?.fs_subject_en}</p>
                                </td>
                                <td>{list?.fs_mdate}</td>
                                <td>{list?.fs_view_cnt}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pagerBox">
                    <Paginate page={page} size={10} totalCount={totalCount} setPage={handlePage}/>
                </div>
            </div>
        </div>
        </>
    )
}