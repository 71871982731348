import React, { useRef, useState } from "react";
import AnimateHeight from "react-animate-height";
import { useTranslation } from "react-i18next";
import { GoogleMaps } from "../../../../components/googleMap/googleMap";
import { LocationData } from "../../../../data/locationData";
import { getCookie } from "../../../../util/cookie/cookies";

export const Location = () => {
    const [state, setState] = useState<number>(1)
    const [tabState, setTabState] = useState<number>(0)
    const handleOpenMap = (index:any) => {setTabState(index)}
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3><span>바이넥스의 사업장</span>을 안내해드립니다.</h3>
                : 
                <h3>Cordially guiding you through <span>Binex’s facilities</span></h3>

                    
                }
                
                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>setState(1)}>
                            <button className={state===1?'active':''}>{t("location_tab_1")}</button>
                        </div>
                        <div onClick={()=>setState(2)}>
                            <button className={state===2?'active':''}>{t("location_tab_2")}</button>
                        </div>
                        <div onClick={()=>setState(3)}>
                            <button className={state===3?'active':''}>{t("location_tab_3")}</button>
                        </div>
                        <div onClick={()=>setState(4)}>
                            <button className={state===4?'active':''}>{t("location_tab_4")}</button>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={state===1?'active':''}>
                        <div className="left">
                            <GoogleMaps address="부산 사하구 다대로 368-3" google={null}/>
                        </div>
                        <div className="right">
                            <div className="tableWrap">
                                <table className="head_office">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span>{t("location_address")}</span>
                                            </th>
                                            <td>{t("location_content_1")}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_tel")}</span>
                                            </th>
                                            <td>032-850-3000</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_fax")}</span>
                                            </th>
                                            <td>051-790-4400</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>
                                                <span>{t("location_location")}</span>
                                            </th>
                                            <td>
                                                <div>
                                                    <span className="green">{t("location_content_2")}</span>
                                                    <span>
                                                        {/* <span className="green">11 : </span> */}
                                                        {t("location_content_3")}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <span className="green">{t("location_content_4")} : </span>
                                                        {t("location_content_5")}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span>
                                                        <span className="orange">{t("location_content_6")} : </span>
                                                        {t("location_content_7")}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <span className="orange">{t("location_content_8")} :</span>
                                                        {t("location_content_9")}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 바이넥스 본사 Fin --> */}

                    <div className={state===2?'active':''}>
                        <div className="left">
                            <GoogleMaps address="인천광역시 연수구 갯벌로 3" google={null}/>
                        </div>
                        <div className="right">
                            <div className="tableWrap">
                                <table className="songdo">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span>{t("location_address")}</span>
                                            </th>
                                            <td>{t("location_content_10")}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_tel")}</span>
                                            </th>
                                            <td>032-850-3000</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_fax")}</span>
                                            </th>
                                            <td>032-850-3299</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>
                                                <span>{t("location_location")}</span>
                                            </th>
                                            <td>
                                                <div>
                                                    <span className="green">{t("location_content_11")}</span>
                                                    <span>
                                                        <span className="blue">{t("location_content_12")} :</span>
                                                        {t("location_content_13")}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span>
                                                        <span className="skyblue">{t("location_content_14")} : </span>
                                                        {t("location_content_15")}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 송도공장 Fin --> */}

                    <div className={state===3?'active':''}>
                        <div className="left">
                            <GoogleMaps address="충북 청주시 흥덕구 오송읍 오송생명5로 147" google={null}/>
                        </div>
                        <div className="right">
                            <div className="tableWrap">
                                <table className="osong">
                                    <tbody>
                                        <tr>
                                            <th>
                                                <span>{t("location_address")}</span>
                                            </th>
                                            <td>{t("location_content_16")}</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_tel")}</span>
                                            </th>
                                            <td>032-850-3000</td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <span>{t("location_fax")}</span>
                                            </th>
                                            <td>043-711-3607</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>
                                                <span>{t("location_location")}</span>
                                            </th>
                                            <td>
                                                <div>
                                                    <span className="blue">{t("location_content_17")}</span>
                                                    <span>
                                                        <span className="green">{t("location_content_18")} : </span>
                                                        {t("location_content_19")}
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <span className="blue">{t("location_content_20")} : </span>
                                                        {t("location_content_21")}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <span className="ktx">{t("location_content_22")}</span>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    {/* <!-- 오송공장 Fin --> */}

                    <div className={state===4?'active':''}>
                        <div>
                            <ul className="table">
                                <li className="thead">
                                    <div className="tr">
                                        <span>{t("location_content_23")}</span>
                                        <span>{t("location_content_24")}</span>
                                        <span>{t("location_content_25")}</span>
                                        <span>{t("location_content_26")}</span>
                                    </div>
                                </li>
                                {LocationData.map((x:any,index:number)=> (
                                <li key={index} className={index===tabState?'active':''} onClick={()=>handleOpenMap(index)}>
                                    <div className="tr">
                                        <span>{lang === 'Kr'?x.main_kr:x.main_en}</span>
                                        <span>{lang === 'Kr'?x.address_kr:x.address_en}</span>
                                        <span>{x.tel}</span> 
                                        <span></span>
                                    </div>
                                    <AnimateHeight duration={500} height={tabState === index ? 'auto' : 0}>
                                        <div className="mapBox">
                                            <GoogleMaps address={x.address_en} google={null}/>
                                            {/* <iframe src={x.location} style={{border:'0'}} loading="lazy"></iframe> */}
                                        </div>
                                    </AnimateHeight>
                                </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {/* <!-- 지점안내 Fin --> */}
                </div>
            </div>
        </div>
        </>
    )
}