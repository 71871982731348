import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { getCookie } from "../../../../util/cookie/cookies";
import { ElectronicDisclosure } from "./electronicDisclosure";
import { ElectronicNotice } from "./electronicNotice";
import { ElectronicNoticeView } from "./electronicNoticeView";
import { FinancialInformation } from "./financialInformation";
import { InternalInformationManagement } from "./internalInformation";
import { GeneralInformation } from "./stockInformation/generalInformation";
import { StockPrice } from "./stockInformation/stockPrice";
import { StockStatus } from "./stockInformation/stockStatus";

export const InvestInfomation = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const {thirdUrl, id} = useParams()
    const [state, setState] = useState<{classname_1:string;classname_2:string,title_kr:string;title_en:string}>({classname_1 : '',classname_2:'',title_kr : '',title_en : ''})
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/invest/electronicDisclosure': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'public_announcement',title_kr:'전자공시', title_en : 'Public Announcement'}));break;
            case '/invest/electronicNotice': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'settlement_announcement',title_kr:'전자공고', title_en : 'Electric Notice'}));break;
            case `/invest/electronicNotice/${thirdUrl}`: setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'settlement_announcement_view boardView',title_kr:'전자공고', title_en : 'Electric Disclosure'}));break;
            case '/invest/financialInformation': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'financial_information',title_kr:'재무정보', title_en : 'Financial Information'}));break;
            case '/invest/stockInformation/stockPrice': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'stockPrice_information',title_kr:'주가정보', title_en : 'Stock Price Information'}));break;
            case '/invest/stockInformation/stockStatus': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'stock_status',title_kr:'주식현황', title_en : 'Stock Status'}));break;
            case '/invest/stockInformation/general': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'general_information',title_kr:'일반현황', title_en : 'General Information'}));break;
            case '/invest/internalInformationManagement': setState((prev)=>({...prev, classname_1: 'investment_information',classname_2:'inside_information',title_kr:'내부관리정보규정', title_en : 'Internal Information Control Regulation'}));break;
        }
    },[location])
    return(
        <>
        <div className={`subPage ${state.classname_1} ${state.classname_2}`}>
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?state.title_kr:state.title_en}</h2>
                </div>
            </div>

            <NavigationBox/>

            {location.pathname === '/invest/electronicNotice' ? <ElectronicNotice/> : ''}
            {location.pathname === '/invest/electronicDisclosure' ? <ElectronicDisclosure/> : ''}
            {location.pathname === `/invest/electronicNotice/${thirdUrl}` ? <ElectronicNoticeView/> : ''}
            {location.pathname === '/invest/financialInformation' ? <FinancialInformation/> : ''}
            {location.pathname === '/invest/stockInformation/stockPrice' ? <StockPrice/> : ''}
            {location.pathname === '/invest/stockInformation/stockStatus' ? <StockStatus/> : ''}
            {location.pathname === '/invest/stockInformation/general' ? <GeneralInformation/> : ''}
            {location.pathname === '/invest/internalInformationManagement' ? <InternalInformationManagement/> : ''}
        </div>
        </>
    )
}