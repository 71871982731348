import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../../../util/cookie/cookies";

export const InformantProtection = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    제보한 내용에 대해서 어떠한 불이익도 받지 않도록
                    <span> 비공개로 운영하여 철저하게 비밀을 유지하고 있습니다.</span>
                </h3>
                : 
                <h3>
                    To ensure the confidentiality and protection of reported information,
                    <span> we operate with utmost discretion, ensuring that no adverse consequences are faced by the reporter</span>
                </h3>
                }
                
                <div>
                    <h4>
                        <span>{t("report_protect_title_1")}</span>
                        {t("report_protect_content_1")}
                    </h4>
                    <ol className="numbList">
                        <li>
                            <span>①</span>
                            {t("report_protect_content_2")}
                        </li>
                        <li>
                            <span>②</span>
                            {t("report_protect_content_3")}
                        </li>
                        <li>
                            <span>③</span>
                            {t("report_protect_content_4")}
                        </li>
                    </ol>
                </div>
                <div>
                    <h4>
                        <span>{t("report_protect_title_2")}</span>
                        {t("report_protect_content_5")}
                    </h4>
                    <ol className="numbList">
                        <li>
                            <span>①</span>
                            {t("report_protect_content_6")}
                        </li>
                        <li>
                            <span>②</span>
                            {t("report_protect_content_7")}
                        </li>
                        <li>
                            <span>③</span>
                            {t("report_protect_content_8")}
                        </li>
                        <li>
                            <span>④</span>
                            {t("report_protect_content_9")}
                        </li>
                        <li>
                            <span>⑤</span>
                            {t("report_protect_content_10")}
                        </li>
                        <li>
                            <span>⑥</span>
                            {t("report_protect_content_11")}
                        </li>
                        <li>
                            <span>⑦</span>
                            {t("report_protect_content_12")}
                        </li>
                        <li>
                            <span>⑧</span>
                            {t("report_protect_content_13")}
                        </li>
                        <li>
                            <span>⑨</span>
                            {t("report_protect_content_14")}
                        </li>
                    </ol>
                </div>

                <div className="linkBox">
                    <div className="reporting_information_box" onClick={()=>navigate('/ethical/reportingAndprocedure/reportGuide')}>
                        <div>
                            <span>{t("report_protect_title_3")}</span>
                            <span>{t("report_protect_title_4")}</span>
                        </div>
                    </div>
                    <div className="report_box" onClick={()=>navigate('/ethical/reportingAndprocedure/report')}>
                        <div>
                            <span>{t("report_protect_title_5")}</span>
                            <span>{t("report_protect_title_6")}</span>
                        </div>
                    </div>
                    <div className="processing_procedure_box" onClick={()=>navigate('/ethical/reportingAndprocedure/processingProcedure')}>
                        <div>
                            <span>{t("report_protect_title_7")}</span>
                            <span>{t("report_protect_title_8")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}