import React from "react";
import { useNavigate } from "react-router-dom";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { useUpdFinanceSettlementActiveStatusMutation } from "../../../../../../store/admin/adminInvestApiSlice";
export interface Props {data: any; getList:any}
export const InvestNoticeList:React.FC<Props> = ({data,getList}) => {
    const navigate = useNavigate()
    const [updFinanceSettlementActiveStatusApi] = useUpdFinanceSettlementActiveStatusMutation()
    const handleChangeActiveStatus = async(idx: any, status : string) => {
        const formData = new FormData()
        formData.append('idx', idx)
        formData.append('settlementActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updFinanceSettlementActiveStatusApi(formData)
        if(result.data.result === true){getList()}
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx}>

                <td onClick={()=>navigate(`/dotsAdmin/invest/notice/${list?.idx}`)}>{list?.fsMdate}</td>

                <td onClick={()=>navigate(`/dotsAdmin/invest/notice/${list?.idx}`)}>{list?.fsSubjectKr?.length < 80 ? list?.fsSubjectKr : list?.fsSubjectKr.slice(0,80)+'...'}</td>

                <td>
                    <span className="toggleSwitch">
                        {/* <!-- id값과 for 값은 고유값으로 한쌍씩 같아야 토글애니메이션 작동합니다. --> */}
                        <input type="checkbox" id={list?.idx} className="toggleIpt" onChange={()=>handleChangeActiveStatus(list?.idx,list?.fsActiveStatus)} checked={list?.fsActiveStatus==='Y'?true:false} hidden/>
                        <label htmlFor={list?.idx} className="toggleSwitch">
                            <span className="toggleButton"></span>
                        </label>
                    </span>
                </td>
            </tr>
        ))}
        </tbody> 
        </>
    )
}