import React, { useCallback, useRef, useState } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const BusanFactory = () => {
    const slickRef = {
        slick1 : useRef<any>(null), slick2 : useRef<any>(null)
    }
    const [state, setState] = useState<boolean>(false)
    const prev1 = useCallback(()=>slickRef.slick1.current.slickPrev(), []);
    const next1= useCallback(()=>slickRef.slick1.current.slickNext(), []);
    const prev2 = useCallback(()=>slickRef.slick2.current.slickPrev(), []);
    const next2= useCallback(()=>slickRef.slick2.current.slickNext(), []);
    const [currentSlide, setCurrentSlide] = useState<{slide1:number,slide2:number}>({slide1 : 0, slide2 : 0})
    const setting1 = {
        dots : false,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        vertical: false,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange : (current : number, next : number) =>
        setCurrentSlide((prev)=>({...prev, slide1 : next}))
    }
    const setting2 = {
        dots : false,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        vertical: false,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange : (current : number, next : number) =>
        setCurrentSlide((prev)=>({...prev, slide2 : next}))
    }
    return(
        <>
        <div className="contentBox">
            <div>
                <h3><span>제조시설</span>(부산공장)</h3>
                <p>
                    바이넥스는 기존 부산공장 KGMP동을 운영하며 cGMP동을 추가 완공하였습니다.<br/>
                    최신의 제조장비 및 글로벌 GMP 기준에 적합한 제조/품질관리 시스템 구축, 생산성과 품질을 갖춘 제조시설을 운영하고 있습니다.
                </p>
                <div className="imgBox">
                    <img src="/assets/images/busan_factory.png" alt="제조시설(부산공장)"/>
                </div>

                <div className="tabBtnBox">
                    <div>
                        <div>
                            <button className={!state ? 'active' : ''} onClick={()=>setState(false)}>내용고형제 생산라인</button>
                        </div>
                        <div>
                            <button className={state ? 'active' : ''} onClick={()=>setState(true)}>점안제 생산라인</button>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={!state ? 'active' : ''}>
                        <h4>내용고형제 생산라인</h4>
                        <p>
                            내용고형제 생산라인은 보다 우수한 품질확보를 위해 일반/전문의약품 제제와 프로바이오틱스 제제를 분리 생산하고 최신 설비를 도입하여 자동화 시스템을 통해 지속적으로
                            관리하고 있습니다. 또한 시설환경관리시스템(EMS, BMS)을 통해 유동층과립건조코팅기, 이중정타정기, 자동필름코팅기 및 자동캡슐충전기 등 안정한 환경을 유지하여
                            균질하고 안전한 고품질 의약품을 생산하고 있습니다.
                        </p>

                        <div className="sliderWrap">
                            <ul>
                            <Slider {...setting1} ref={slickRef.slick1}>
                                {picNumber.map((pic:any) => (
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/manufacturing_facility_1_${pic}.png`} alt="부산공장"/>
                                    </div>
                                </li>
                                ))}
                            </Slider>
                            </ul>

                            <div className="btnBox">
                                <button className="prevBtn"onClick={prev1}></button>
                                <button className="nextBtn"onClick={next1}></button>
                            </div>
                            <div className="sliderPagerBox">
                                <span className="now">{currentSlide.slide1+1}</span>
                                <span>/</span>
                                <span>8</span>
                            </div>
                        </div>
                    </div>

                    <div className={state ? 'active' : ''}>
                        <h4>점안제 생산라인</h4>
                        <p>
                            점안제 생산라인은 고도로 검증된 제조시설을 운영하고 있으며, 체계적인 멸균 및 무균조작방식과 PMS운영 등 철저한 제조/품질관리가 이루어진 무균환경에서 안전하고
                            신뢰할 수 있는 생산체계를 갖추어 효과적이고 우수한 고품질 의약품을 생산하고 있습니다.
                        </p>
                        {/* <!-- <div className="imgBox">
                            <img src="/assets/images/manufacturing_facility.png" alt="제조시설(부산공장)">
                        </div> --> */}

                        <div className="sliderWrap">
                            <ul>
                            <Slider {...setting2} ref={slickRef.slick2}>
                                {picNumber.map((pic : any) => (
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/manufacturing_facility_2_${pic}.png`} alt="부산공장"/>
                                    </div>
                                </li>
                                ))}
                            </Slider>
                            </ul>

                            <div className="btnBox">
                                <button className="prevBtn" onClick={prev2}></button>
                                <button className="nextBtn" onClick={next2}></button>
                            </div>
                            <div className="sliderPagerBox">
                                <span className="now">{currentSlide.slide2+1}</span>
                                <span>/</span>
                                <span>8</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

const picNumber = [1,2,3,4,5,6,7,8]
