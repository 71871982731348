import React, { useEffect, useState } from "react";
import { LineSvgProps, ResponsiveLine } from '@nivo/line'

interface Props {data : any}

const MyResponsiveLine2:React.FC<Props> = ({ data }) => {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
    return(
        <ResponsiveLine
        data={data}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: 'point' }}
        yScale={{
            type: 'linear',
            min: 'auto',
            max: 'auto',
            stacked: true,
            reverse: false
        }}
        yFormat={(value:any) => {
            const formattedValue = value.toFixed(2).replace(/\.00$/, '');
            return formattedValue.replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={!isMobile ? null : {
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: 36,
            legendPosition: 'middle',
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            legendOffset: -40,
            legendPosition: 'middle',
        }}
        colors={'#0E56A7'}
        pointSize={10}
        pointColor={{ from: 'color', modifiers: [] }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-12}
        enableSlices={isMobile ? "x" : false}
        useMesh={true}
        legends={[
            {
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 0,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        tooltip={({point}) => (
            <div style={{ background: "#fff", padding: "10px", border: "1px solid #ccc" }}>
                일자: <strong>{point.data.xFormatted}</strong> <br /><br/>
                금액: <strong>{point.data.yFormatted}</strong> 
            </div>
        )}
    />
    )
}

export default MyResponsiveLine2