import { t } from "i18next";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const CI = () => {
    const [state, setState] = useState<number>(1)
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>BINEX CI, <span>"Victory to Advance"</span></h3>

                <div>
                    <div className="imgBox">
                        <img src="/assets/images/ci.png" alt="CI"/>
                    </div>
                    <h4>{t("ci_title")}</h4>
                    <p>
                    {t("ci_title_content_1")}
                    </p>
                    <p>{t("ci_title_content_2")}</p>
                </div>

                <div>
                    <h4>{t("ci_title_content_3")}</h4>
                    <div>
                        <div>
                            <span>{t("ci_content_1")}</span>
                            <div>
                                <img src="/assets/images/ci_leftAndRight.png" alt="CI 좌우조합"/>
                            </div>
                        </div>
                        
                        <div>
                            <span>{t("ci_content_2")}</span>
                            <div>
                                <img src="/assets/images/ci_upAndDown.png" alt="CI 좌우조합"/>
                            </div>
                        </div>
                    </div>

                    <div className="tabBtnBox">
                        <div>
                            <div onClick={()=>setState(1)}>
                                <button className={state===1?'active':''}>{t("ci_tab_1")}</button>
                            </div>
                            <div onClick={()=>setState(2)}>
                                <button className={state===2?'active':''}>{t("ci_tab_2")}</button>
                            </div>
                            <div onClick={()=>setState(3)}>
                                <button className={state===3?'active':''}>{t("ci_tab_3")}</button>
                            </div>
                            <div onClick={()=>setState(4)}>
                                <button className={state===4?'active':''}>{t("ci_tab_4")}</button>
                            </div>
                        </div>
                    </div>

                    <div className="tabContentBox">
                        <div className={state===1?'active':''}>
                            <div className="imgBox">
                                <img src="/assets/images/minimum_space_regulations.png" alt="최소공간 규정"/>
                            </div>
                            <p>* {t("ci_tab_content")}</p>
                        </div>
                        {/* <!-- 최소공간 규정 --> */}

                        <div className={state===2?'active':''}>
                            <div className="imgBox">
                                <img src="/assets/images/color_system.png" alt="컬러규정"/>
                            </div>
                        </div>
                        {/* <!-- 컬러규정 --> */}

                        <div className={state===3?'active':''}>
                            <div className="imgBox">
                                <img src="/assets/images/rules_for_using_backgroundColor.png" alt="바탕색 활용 규정"/>
                            </div>
                        </div>
                        {/* <!-- 바탕색 활용 규정 --> */}
                        
                        <div className={state===4?'active':''}>
                            <ul>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy1.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy2.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy3.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy4.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy5.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy6.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy7.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy8.png" alt="사용금지 규정"/>
                                </li>
                                <li>
                                    <img src="/assets/images/prohibited_use_policy9.png" alt="사용금지 규정"/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}