import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";

export const Employment = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                <div>
                    <div>
                        <h3><span>{t("employeement_title_1")}</span>{t("employeement_title_2")}</h3>
                        {lang === 'Kr' ? 
                        <p>
                            바이넥스는 자기 분야에서 <strong>전문성</strong>과 <strong>책임감</strong>을 갖추고,<br/>
                            <strong>상호협력</strong>을 실천하며, 열린 사고와 올바른 마인드로<br/>
                            <strong>공동의 목표를 달성</strong>하고자 하는 노력하는 인재를 지향합니다.
                        </p> : 
                        <p>Binex is seeking the future leaders who will participate in our journey to <br></br> become a global-leading company in biotechnology</p>
                            
                        }
                    </div>

                    <div>
                        <ul>
                            <li>
                                <h4>{t("employeement_content_title_1")}</h4>
                                <ul>
                                    <li>
                                        <div>
                                            <span>STEP 01</span>
                                            <span>{t("employeement_content_1_1")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>STEP 02</span>
                                            <span>{t("employeement_content_1_2")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>STEP 03</span>
                                            <span>{t("employeement_content_1_3")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>STEP 04</span>
                                            <span>
                                            {t("employeement_content_1_4")}
                                                <span>{t("employeement_content_1_5")}</span>
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>STEP 05</span>
                                            <span>{t("employeement_content_1_6")}</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div>
                                            <span>STEP 06</span>
                                            <span>{t("employeement_content_1_7")}</span>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div>
                                    <h4>{t("employeement_content_title_2")}</h4>
                                    <p>{t("employeement_content_2_1")}</p>
                                </div>
                            </li>
                            <li>
                                <h4>{t("employeement_content_title_3")}</h4>
                                <div>
                                    <a href="/(주)바이넥스_자사양식.xlsx" download>{t("employeement_file_1")}</a>
                                    <a href="/(주)바이넥스_자사양식.xlsx" download>{t("employeement_file_2")}</a>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div className="btnBox">
                        <button onClick={()=>window.open("https://www.saramin.co.kr/zf_user/company-info/view-inner-recruit?csn=Smx4T3NjYkQzVGZuM25PSFdEY29BQT09")}>
                            <span>{t("employeement_bar")}</span>
                        </button>
                    </div>
                </div>

            </div>
        </div>
        </>
    )
}