import React, { useState } from "react";
import { useSetWhistleblowCommentMutation, useUpdWhistleblowReplyStatusMutation } from "../../../store/admin/adminReportingApiSlice";
import { useParams } from "react-router-dom";
interface Props {status : string, refetch : Function, setActive : Function}
export const ReportStatusSelectBox:React.FC<Props> = ({status, refetch, setActive}) => {
    const {id} : any = useParams()
    const [isActive , setIsActive] = useState<boolean>(false)
    const [adminName, setAdminName] = useState<string>('')
    const [comment, setComment] = useState<string>('')
    const [nextStatus, setNextStatus] = useState<string>('')
    const [updWhistleblowReplyStatus] = useUpdWhistleblowReplyStatusMutation()
    const [setWhistleblowComment] = useSetWhistleblowCommentMutation()
    const handleChangeStatus = async(status : string) => {
        const confirm = window.confirm("상태를 변경하시겠습니까?")
        if(confirm){
            setIsActive(true)
            setNextStatus(status)
        }else{
            return;
        }
    }
     // 관리자 의견 남기기
     const handleComment = async(type :string) => {
        if(type === 'close') {setIsActive(false); return;}
        else{
            if(!adminName) {alert('관리자명을 기입해 주세요.'); return}
            if(!comment) {alert('처리 내용을 기입해 주세요.'); return}
            const formData : any = new FormData()
            formData.append('wbId', id)
            formData.append('wbStatus', nextStatus)
            formData.append('wbManagerName', adminName)
            formData.append('wbProcessContents', comment)
            const res : any = await setWhistleblowComment(formData)
            if(res.data.result === true) {
                setIsActive(false);
                const formData : any = new FormData()
                formData.append('whistleId', id)
                formData.append('whistleReplyStatus', nextStatus)
                const result : any = await updWhistleblowReplyStatus(formData)
                if(result.data.result === true) refetch();
                alert('상태가 변경되었습니다.')
            }
        }
    }
    return(
        <>
        <tr>
            <th>상태</th>
            <td>
                <div className="selectBox" style={{paddingRight: '25px', boxSizing:'border-box'}}>
                    <select value={status} onChange={(e)=>handleChangeStatus(e.target.value)} style={{width:'49%', boxSizing:'border-box'}}>
                        <option value={'W'} selected={status==='W'}>접수대기</option>
                        <option value={'R'} selected={status==='R'}>접수</option>
                        <option value={'I'} selected={status==='I'}>조사중</option>
                        <option value={'C'} selected={status==='C'}>완료</option>
                        <option value={'B'} selected={status==='B'}>반려</option>
                    </select>
                </div>
            </td>
        </tr>

        {isActive && 
        <div className="popUpBox active">
            <div className="admin_opinion">
                <div>
                    <h3>관리자 의견</h3>
                </div>
                <div className="managerBox">관리자 명 <input type="text" onChange={(e)=>setAdminName(e.target.value)} placeholder="관리자 이름을 입력해 주세요."></input></div>
                <textarea name="" onChange={(e)=>setComment(e.target.value)} placeholder="처리 내용에 대해 간단한 코멘트를 남겨주세요."></textarea>
                <div className="btnBox right">
                    <button className="grayBtn" onClick={()=>handleComment('close')}>닫기</button>
                    <button className="blueBtn" onClick={()=>handleComment('commit')}>저장</button>
                </div>
            </div>
        </div>
        }
        </>
    )
}