import React from "react";
import { useTranslation } from "react-i18next";

export const PracticeGuideline = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("ethical_practice_title")}</h3>
                <ul>
                    <li>
                        <h4>{t("ethical_practice_subTitle_1")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_1")}</span>
                            <p>{t("ethical_practice_contents_1")}</p>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_2")}</span>
                            <p>{t("ethical_practice_contents_2")}</p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("ethical_practice_subTitle_2")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_3")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_3_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_3_2")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("ethical_practice_contents_3_3")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_4")}</span>
                            <p>{t("ethical_practice_contents_4")}</p>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_5")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_5_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_5_2")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_6")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_6_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_6_2")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_7")}</span>
                            <p>{t("ethical_practice_contents_7")}</p>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_8")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_8_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_8_2")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("ethical_practice_contents_8_3")}
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("ethical_practice_contents_8_4")}
                                </li>
                                <li>
                                    <span>⑤</span>
                                    {t("ethical_practice_contents_8_5")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_9")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_9_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_9_2")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("ethical_practice_contents_9_3")}
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("ethical_practice_contents_9_4")}
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h4>{t("ethical_practice_subTitle_3")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_10")}</span>
                            <p>{t("ethical_practice_contents_10")}</p>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_11")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_11_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_11_2")}
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h4>{t("ethical_practice_subTitle_4")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_12")}</span>
                            <p>
                                {t("ethical_practice_contents_12")}
                            </p>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_13")}</span>
                            <p>
                                {t("ethical_practice_contents_13")}
                            </p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("ethical_practice_subTitle_5")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_14")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_14_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_14_2")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_15")}</span>
                            <p>{t("ethical_practice_contents_15")}</p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("ethical_practice_subTitle_6")}</h4>
                        <div>
                            <span>{t("ethical_practice_contents_title_16")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_16_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_16_2")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_17")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("ethical_practice_contents_17_1")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("ethical_practice_contents_17_2")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("ethical_practice_contents_17_3")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("ethical_practice_contents_title_18")}</span>
                            <p>{t("ethical_practice_contents_18")}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}