import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {count : number}
export const ListHeader:React.FC<Props> = ({count}) => {
    const searchRef = useRef<any>(null)
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const keyword = searchParam.get('keyword') || '';
    const workplace = searchParam.get('workplace')||'';
    const blowType = searchParam.get('whistleblowType')||'';
    const blowStatus = searchParam.get('blowStatus') || '';
    const startDate = searchParam.get('whistleBlowFromMdate')||'2023-01-01';
    const endDate = searchParam.get('whistleBlowToMdate')
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const [state, setState] = useState<{title:string,url:string}>({title : '', url : ''})
    useEffect(()=>{
        switch(location.pathname){
            case '/dotsAdmin/commoncode' : setState((prev) => ({...prev, title : '공용코드'})); break;
            case '/dotsAdmin/products/product' : setState((prev) => ({...prev, title : '제품 등록 및 수정'})); break;
            case '/dotsAdmin/products/P' : setState((prev) => ({...prev, title : '제품허가정보'})); break;
            case '/dotsAdmin/products/O' : setState((prev) => ({...prev, title : '비만치료약물'})); break;
            case '/dotsAdmin/reporting/report' : setState((prev) => ({...prev, title : '제보관리'})); break;
            case '/dotsAdmin/reporting/inquiry' : setState((prev) => ({...prev, title : '문의사항'})); break;
            case '/dotsAdmin/invest/notice' : setState((prev) => ({...prev, title : '전자공고'})); break;
            case '/dotsAdmin/promotion' : setState((prev)=>({...prev, title : '홍보센터'})); break;
        }
    }, [location])

    const handleSearch = () => {
        if(location.pathname === '/dotsAdmin/reporting/report'){
            navigate(`${location.pathname}?workplace=${workplace}&whistleblowType=${blowType}&blowStatus=${blowStatus}&whistleBlowFromMdate=${startDate}&whistleBlowToMdate=${endDate}&page=1&size=10&keyword=${searchRef.current.value}&sortOrder=desc&sortColumn=date`)
        }else{
            navigate(`${location.pathname}?page=1&size=10&keyword=${searchRef.current.value}&sortOrder=desc&sortColumn=date`)
        }
    }
    const handleEnter = (e:any) => {
        if(e.key==='Enter'){handleSearch()}
    }

    return(
        <>
        <div className="flexWrap">
            <div className="flexBox">
                <h4>
                    {state.title}
                    {path[2]==='reporting'? '' : <span className="content_count">{count}</span>}
                </h4>
                {path[2] === 'reporting' ? '' :
                <button className="addBtn" onClick={()=>navigate(`${location.pathname}/regist`)}>
                    <i className="fa-solid fa-plus"></i>
                    <span>Add New</span>
                </button>
                }
            </div>
                
            <div className="search_box">
                <input type="text"placeholder="Search ..." defaultValue={keyword} ref={searchRef} onKeyDown={handleEnter}/>
                <button type="button" className="ic-button" onClick={handleSearch}>
                    <img src="/assets/images/searchBox.png" alt="검색아이콘"/>
                </button>
            </div>

            
        </div>
        
        </>
    )
}