import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { getCookie } from "../../../../util/cookie/cookies";
import { CodeOfEthics } from "./codeOfEthicsAndImplement/codeOfEthics";
import { PracticeGuideline } from "./codeOfEthicsAndImplement/practiceGuidelines";
import { InformantProtection } from "./reportingAndprocedure/informantProtection";
import { ProcessingProcedure } from "./reportingAndprocedure/processingProcedure";
import { ReportingGuide } from "./reportingAndprocedure/reportingGuide";
import { ReportIt } from "./reportingAndprocedure/reportIt";

export const EthicalManagement = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const {thirdUrl} = useParams()
    const [state, setState] = useState<{classname_1:string;classname_2:string,title_kr:string;title_en:string}>({classname_1 : '',classname_2:'',title_kr : '',title_en:''})
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/ethical/codeOfehicsAndimplement/codeOfethics': setState((prev)=>({...prev, classname_1: 'codeOfEthicsAndPracticalGuidelines',classname_2:'code_of_ethics',title_kr:'윤리강령',title_en:'Code of Ethics'}));break;
            case '/ethical/codeOfehicsAndimplement/practiceGuideline': setState((prev)=>({...prev, classname_1: 'codeOfEthicsAndPracticalGuidelines', classname_2:'practice_guidelines',title_kr:'실천지침',title_en:'Pratice Guidelines'}));break;
            case '/ethical/reportingAndprocedure/reportGuide': setState((prev)=>({...prev, classname_1: 'reportingInformationAndProcedures', classname_2:'reporting_information',title_kr:'제보안내',title_en:'Introduction'}));break;
            case '/ethical/reportingAndprocedure/informantProtection': setState((prev)=>({...prev, classname_1: 'reportingInformationAndProcedures', classname_2:'whistleblower_protection',title_kr:'제보자 보호',title_en:'Whistleblower Protection'}));break;
            case '/ethical/reportingAndprocedure/report': setState((prev)=>({...prev, classname_1: 'reportingInformationAndProcedures', classname_2:'report',title_kr:'제보하기',title_en:'Reporting'}));break;
            case '/ethical/reportingAndprocedure/processingProcedure': setState((prev)=>({...prev, classname_1: 'reportingInformationAndProcedures', classname_2:'processing_procedure',title_kr:'제보절차',title_en:'Process'}));break;
        }
    },[location])
    return(
        <>
        <div className={`subPage ${state.classname_1} ${state.classname_2}`}>
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?state.title_kr:state.title_en}</h2>
                </div>
            </div>

            <NavigationBox/>

            {location.pathname === '/ethical/codeOfehicsAndimplement/codeOfethics' ? <CodeOfEthics/> :''}
            {location.pathname === '/ethical/codeOfehicsAndimplement/practiceGuideline' ? <PracticeGuideline/> :''}

            {location.pathname === '/ethical/reportingAndprocedure/reportGuide' ? <ReportingGuide/> :''}
            {location.pathname === '/ethical/reportingAndprocedure/informantProtection' ? <InformantProtection/> :''}
            {location.pathname === '/ethical/reportingAndprocedure/report' ? <ReportIt/> :''}
            {location.pathname === '/ethical/reportingAndprocedure/processingProcedure' ? <ProcessingProcedure/> :''}
        </div>
        </>
    )
}