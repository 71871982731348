import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import './locale/i18n'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Test } from './pages/user/test/test';
import LayOut from './pages/user/layout';
import { AdminLogin } from './pages/admin/manager';
import { DashBoard } from './pages/admin/dashboard';
import { getCookie, setCookie } from './util/cookie/cookies';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { Admin } from './pages/admin';

function App() {
  
  const [cookies, setCookies] = useCookies(['BINEXLANG']);
  const navigate = useNavigate()
  const {i18n} = useTranslation();
  const lang = getCookie('BINEXLANG')
  const location = useLocation()
  const path = location.pathname.split('/')[2]
  const path1 = location.pathname.split('/')
  useEffect(()=>{
    if(lang === undefined){setCookies('BINEXLANG', 'Kr', {path : '/'}); i18n.changeLanguage('Kr')}
    if(lang === 'Kr'){i18n.changeLanguage('Kr')}
    else {i18n.changeLanguage('En')}
  }, [lang, setCookies])
  useEffect(()=>{
    if(lang === 'En'){
      if(path === 'productAnnouncement' || path === 'probiitics' || path === 'busanFactory' || path1[3]==='productefficacy'){
        navigate('/syntheticMedicine/search/productname') 
      }
    }
  }, [lang])
  useEffect(()=>{window.scrollTo({top :0 , behavior : 'auto'})}, [path1[2],path1[3],path1[4]])
  return (
    <>
    <Routes>
      <Route path='/' element={<LayOut/>}/>
      <Route path='/siteMap' element={<LayOut/>}/>
      <Route path='/contactUs' element={<LayOut/>}/>
      <Route path='/binex/:secondUrl' element={<LayOut/>}/>
      <Route path='/binex/:secondUrl/:thirdUrl' element={<LayOut/>}/>
      <Route path='/bioMedicine/:secondUrl' element={<LayOut/>}/>
      <Route path='/bioMedicine/:secondUrl/:thirdUrl' element={<LayOut/>}/>
      <Route path='/syntheticMedicine/:secondUrl' element={<LayOut/>}/>
      <Route path='/syntheticMedicine/:secondUrl/:thirdUrl' element={<LayOut/>}/>
      <Route path='/syntheticMedicine/:secondUrl/:thirdUrl/:id' element={<LayOut/>}/>
      <Route path='/ethical/:secondUrl' element={<LayOut/>}/>
      <Route path='/ethical/:secondUrl/:thirdUrl' element={<LayOut/>}/>
      <Route path='/recruitmentInfomation/:secondUrl' element={<LayOut/>}/>
      <Route path='/invest/:secondUrl' element={<LayOut/>}/>
      <Route path='/invest/:secondUrl/:thirdUrl' element={<LayOut/>}/>
      <Route path='/privacy' element={<LayOut/>} />

      <Route path='/dotsAdmin' element={<Admin/>}/>
      <Route path='/dotsAdmin/:type' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/:type/regist' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/:type/:id' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/products/:type' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/products/:type/regist' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/products/:type/:id' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/reporting/:type' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/reporting/:type/:id' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/setting/adminstrator' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/setting/adminstrator/regist' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/setting/adminstrator/:adminId' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/setting/popup-management' element={<DashBoard/>}/>

      <Route path='/dotsAdmin/invest/:type' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/invest/:type/regist' element={<DashBoard/>}/>
      <Route path='/dotsAdmin/invest/:type/:id' element={<DashBoard/>}/>
      <Route path='/test' element={<Test/>}/>
    </Routes>
    </>
  );
}

export default App;
