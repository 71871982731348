import { t } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";

export const CodeOfEthics = () => {
    const lang = getCookie('BINEXLANG')
    const { t } = useTranslation();
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("ethical_codeOfehics_title_1")} <span>{t("ethical_codeOfehics_title_2")}</span></h3>
                
                {lang === 'Kr' ? 
                    <p>
                        바이넥스는 국내·외 모든 법규와 시장질서를 존중하고 공정거래질서 확립에 솔선수범하며, 사회적 가치와 관습을 존중하고 다양한 사회공헌 활동을 통하여 <br></br>
                        국가와 사회발전에 이바지한다. 이에 아래와 같이 윤리강령을 제정하여 모든 임직원의 행동과 가치판단의 최우선 원칙으로 삼는다.
                    </p>
                    : 
                    <p>Binex will respect all domestic and international regulations and market norms, actively establish fair trade practices, uphold social values and customs, and contribute to national and social development through various social contributions. To this end, we will establish the following code of ethics as the paramount principle for the behavior and value judgment of all employees.</p>
                        
                    }
                
                <ul>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_1")}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_2")}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_3")}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_4")}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_5")}
                        </p>
                    </li>
                    <li>
                        <p>
                            <span>{t("ethical_codeOfethics_contents_0")}</span>
                            {t("ethical_codeOfethics_contents_6")}
                        </p>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}