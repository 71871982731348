import React, { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../../../util/cookie/cookies";

export const SearchNameBox = () => {
    const lang = getCookie('BINEXLANG')
    const searchRef = useRef<any>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const searchParam = new URLSearchParams(location.search)
    const keyword = searchParam.get('keyword') || ''
    const char = searchParam.get('char') || ''
    const data = lang==='Kr' ? consonant_kr : consonant_en;
    const handleSelectChar = (selChar : string) => {
        if(searchRef.current.value){searchRef.current.value = ''}
        if(char === selChar){
            navigate(`/syntheticMedicine/search/productname?page=1&size=10&keyword=`)
        } else {
            navigate(`/syntheticMedicine/search/productname?page=1&size=10&char=${selChar}`)
        }
        console.log(selChar)
    }
    console.log(char)
    const handleSearch = () => {navigate(`/syntheticMedicine/search/productname?page=1&size=10&keyword=${searchRef.current.value}`)}
    const handleEnter = (e:any) => {if(e.key==='Enter'){handleSearch()}}
    return(
        <>
        <div className="grayBox product_name">
            <div>
                <div className="searchBox">
                    <input type="text" ref={searchRef} defaultValue={keyword} placeholder={lang==='Kr'?"찾으시는 제품명을 입력해 주세요.":"Please enter the product name."} onKeyDown={handleEnter} required/>
                    <div className="search_btnBox">
                        <button type="button" className="search_btn" onClick={handleSearch}></button>
                    </div>
                </div>

                <ul>
                    {data.map((list:string, index:number) => (
                        <li key={index} className={char===list?'active':''} onClick={()=>handleSelectChar(list)}>
                           {list}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
        </>
    )
}

const consonant_kr = [
    'ㄱ','ㄴ','ㄷ','ㄹ','ㅁ','ㅂ','ㅅ','ㅇ','ㅈ','ㅊ','ㅋ','ㅌ','ㅍ','ㅎ'
]

const consonant_en = [
    'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
]