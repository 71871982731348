import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { Paginate } from "../../../../../../components/paginate/paginate_admin";
import { useDelWhistleMutation, useGetWhistleListMutation } from "../../../../../../store/admin/adminReportingApiSlice";
import { ListFilter } from "../list-element/list-filter";
import { ListHeader } from "../list-element/list-header";
export interface Props {data:any, getList : Function}
export const ReportList:React.FC<Props> = ({data, getList}) => {
    const navigate = useNavigate()
    const [delWhistleApi] = useDelWhistleMutation()
    const handleDelReport = async(id : any) => {
        const confirm = window.confirm('해당 제보글을 삭제하시겠습니까?')
        if(confirm){
           const formData = new FormData()
           formData.append('ID', id)
           const res : any = await delWhistleApi(formData)
           if(res.data.result === true) {
            getList()
           }
        }else{
            return;
        }
    }
    return(
        <>
        
        <tbody>
            {data?.map((list:any) => (
            <tr className="active" key={list?.idx}>
                
                <td onClick={()=>navigate(`/dotsAdmin/reporting/report/${list?.idx}`)}>{list?.wbType === 'R'?'실명':'익명'}</td>
                
                <td onClick={()=>navigate(`/dotsAdmin/reporting/report/${list?.idx}`)}>{list?.wbSubject}</td>

                {list?.wbWorkplace === 'O'? <td>오송공장</td> : ''}
                {list?.wbWorkplace === 'B'? <td>부산공장</td> : ''}
                {list?.wbWorkplace === 'S'? <td>송도공장</td> : ''}

                <td onClick={()=>navigate(`/dotsAdmin/reporting/report/${list?.idx}`)}>{list?.wbType === 'R' ? list?.wbName : '-'}</td>

                <td onClick={()=>navigate(`/dotsAdmin/reporting/report/${list?.idx}`)}>{list?.wbMdate}</td>

                {list?.wbReplyStatus === 'W' ? <td>접수대기</td> : ''}
                {list?.wbReplyStatus === 'R' ? <td>접수</td> : ''}
                {list?.wbReplyStatus === 'I' ? <td>조사중</td> : ''}
                {list?.wbReplyStatus === 'C' ? <td>완료</td> : ''}
                {list?.wbReplyStatus === 'B' ? <td>반려</td> : ''}

                <td>
                    <button className="deleteBtn" onClick={()=>handleDelReport(list?.idx)}>삭제</button>
                </td>
            </tr>
            ))}
    
        </tbody>
            
        </>
    )
}