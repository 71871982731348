import React, { useEffect, useRef, useState } from "react";
import { Paginate } from "../../../../components/paginate/paginate_admin";
import { getCookie } from "../../../../util/cookie/cookies";
import { useGetUserPromotionListMutation } from "../../../../store/user/contentsApiSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const PromotionCenter = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const navigate = useNavigate()
    const page : any = new URLSearchParams(location.search).get('page') || 1;
    const keyword : any = new URLSearchParams(location.search).get('keyword') || '';
    const keywordClass : any = new URLSearchParams(location.search).get('keywordClass') || 'S';
    const searchRef = useRef<any>(null)
    const [getUserPromotionList] = useGetUserPromotionListMutation()
    const [type , setType] = useState<string>('S')
    const [param, setParam] = useState<any>({
        lang : lang === 'Kr' ? 'KR' : "EN", page : page, size : 10, keyword : keyword, keywordClass : keywordClass, column : 'promMdate', order : 'desc'
    })
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const getList = async() => {
        const result : any = await getUserPromotionList(param)
        if(result.data.result) setData(result.data.list); setTotalCount(result.data.total_cnt)
    }
    const handlePage = (pageNumber:number) => {
        if(pageNumber !== parseInt(page)) navigate(`/binex/promotion-center?page=${pageNumber}&keyword=${keyword}&keywordClass=${keywordClass}`)
    }
    const handleSelectType = (e:any) => {
        navigate(`/binex/promotion-center?page=${page}&keyword=${keyword}&keywordClass=${e.target.value}`)
    }
    const handleSearch = () => {
        navigate(`/binex/promotion-center?page=${page}&keyword=${searchRef.current.value}&keywordClass=${keywordClass}`)
    }
    const handleEnter = (e:React.KeyboardEvent<HTMLInputElement>) => {
        if(e.key === 'Enter') {handleSearch()}
    }
    const calculateIndex = (currentPage:any, pageSize:number, totalCount:number, itemIndex:number) => {
        const lastIndex = totalCount - (currentPage -1) * pageSize;
        return lastIndex - itemIndex;
    }
    useEffect(()=>{
        if(lang === 'En') navigate(`/`)
    }, [lang])
    useEffect(()=>{setParam((prev:any)=>({...prev, lang : lang==='Kr'?'KR':'EN', page : page, keyword : keyword, keywordClass : keywordClass}))}, [lang, page, keyword, keywordClass])
    useEffect(()=>{
        getList()
    },[param.lang, param.page, param.keyword, param.keywordClass])
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    <span>{lang === 'Kr' ? '홍보센터' : 'Promotion Center'}</span>
                </h3>

                <div className="searchWrap">
                    <select value={keywordClass} name="" id="" onChange={handleSelectType}>
                        <option value="S">{lang === 'Kr' ? '제목' : 'Subject'}</option>
                        <option value="C">{lang === 'Kr' ? '내용' : 'Contents'}</option>
                    </select>
                    
                    <div className="searchBox">
                        <input type="text" ref={searchRef} defaultValue={keyword} placeholder={`찾으시는 ${keywordClass==='S'?'제목':'내용'}을 입력해 주세요`} onKeyDown={handleEnter} required/>
                        <div className="search_btnBox">
                            <button type="button" className="search_btn" onClick={handleSearch}></button>
                        </div>
                    </div>
                </div>
                
                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>{lang === 'Kr' ? '제목' : 'Subject'}</th>
                                <th>{lang === 'Kr' ? '작성일' : 'Date'}</th>
                                <th>{lang === 'Kr' ? '조회수' : 'View Count'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((list:any, index:number) => (
                            <tr key={list?.promId} onClick={()=>navigate(`/binex/promotion-center/${list?.promId}`)}>
                                <td>{calculateIndex(page, param.size, totalCount, index)}</td>
                                <td>
                                    <p>{lang === 'Kr' ? list?.promSubjectKr : list?.promSubjectEn}</p>
                                </td>
                                <td>{list?.promMdate}</td>
                                <td>{lang === 'Kr' ? list?.promViewCntKr : list?.promViewCntEn}</td>
                            </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                <div className="pagerBox">
                    <Paginate page={page} setPage={handlePage} totalCount={totalCount} size={10}/>
                </div>
            </div>

        </div>
        </>
    )
}