import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../util/cookie/cookies";
export interface Props {type : string; setState : any}
export const Lang:React.FC<Props> = ({type, setState}) => {
    const clRef = useRef<any>(null)
    const [cookie, setCookie] = useCookies(['BINEXLANG'])
    const [langActive, setLangActive] = useState<boolean>(false)
    const lang = getCookie('BINEXLANG')
    const { t, i18n } = useTranslation();
    const handleLangChangeKr =()=>{setCookie('BINEXLANG', 'Kr' , {path : '/'}); i18n.changeLanguage('Kr'); if(setState!==''){setState(false)}}
    const handleLangChangeEn =()=>{setCookie('BINEXLANG', 'En', {path : '/'}); i18n.changeLanguage('En'); if(setState!==''){setState(false)}}
    useEffect(()=>{
        const handleOutSide = (e:any) =>{
            if(clRef.current&&!clRef.current.contains(e.target)){
                setLangActive(false);
            }
        }
        document.addEventListener('mousedown', handleOutSide);
        return()=> {
            document.removeEventListener('mousedown', handleOutSide)
        }
    })
    return(
        <>
        <div className={type==='pc'?'':'langBox'}>
            {type=== 'pc' ? 
            <>
            <span className="" onClick={handleLangChangeKr}>KOR</span>
            <span className="" onClick={handleLangChangeEn}>ENG</span>
            </> :
            <>
            <button className={lang==='Kr'?'langBtn_ko active':''} onClick={handleLangChangeKr}>KOR</button>
            <button className={lang==='En'?'langBtn_en active':''} onClick={handleLangChangeEn}>ENG</button>
            </>
            }
        </div>
        </>
    )
}