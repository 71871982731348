import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export const OriginalDrug = () => {
    const {t} = useTranslation()
    const [state, setState] = useState<number>(1)
    return(
        <>
        <div className="content_1 active">
            <h4>{t("cmo_tab_1")}</h4>
            
            <div className="flexBox">
                <div className="left">
                    <ol>
                        <li>
                            {t("cmo_origin_content_1")}
                            <ul>
                                <li>{t("cmo_origin_content_2")}</li>
                                <li>{t("cmo_origin_content_3")}</li>
                            </ul>
                        </li>
                        <li>{t("cmo_origin_content_5")}</li>
                        <li>{t("cmo_origin_content_6")}</li>
                        <li>{t("cmo_origin_content_8")}</li>
                    </ol>

                    <span>* STS: Stainless Steel Bioreactor / SUB: Single Use Bioreactor</span>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/original_drug.png" alt="원제의약품"/>
                </div>
            </div>

            <div className="tableWrap">
                <table>
                    <thead>
                        <tr>
                            <th>Classification</th>
                            <th>Suite</th>
                            <th>Scale (type)</th>
                            <th>Train (W.V.) *</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th rowSpan={8}>Mammalian</th>
                            <td rowSpan={2}>Suite 1</td>
                            <td>200 L (SUB)</td>
                            <td>200 L</td>
                        </tr>
                        <tr>
                            <td>1,000 L (SUB)</td>
                            <td>200 L - 1,000 L</td>
                        </tr>
                        <tr>
                            <td>Suite 2</td>
                            <td>500 L (STS)</td>
                            <td>500 L</td>
                        </tr>
                        <tr>
                            <td>Suite 3</td>
                            <td>1,000 L (STS)</td>
                            <td>200 L - 1,000 L</td>
                        </tr>
                        <tr>
                            <td>Suite 4</td>
                            <td>1,000 L (STS)</td>
                            <td>200 L - 1,000 L</td>
                        </tr>
                        <tr>
                            <td rowSpan={2}>Suite 5</td>
                            <td>1,000 L (STS)</td>
                            <td>1,000 L</td>
                        </tr>
                        <tr>
                            <td>1,000 L (STS)</td>
                            <td>200 L - 1,000 L</td>
                        </tr>
                        <tr>
                            <td>Suite 6</td>
                            <td>5,000 L (STS)</td>
                            <td>1,000 L - 5,000 L</td>
                        </tr>
                        <tr>
                            <th rowSpan={2}>Microbial</th>
                            <td>Suite 7</td>
                            <td>180 L (STS)</td>
                            <td>180 L</td>
                        </tr>
                        <tr>
                            <td>Suite 8</td>
                            <td>500 L (STS)</td>
                            <td>500 L</td>
                        </tr>
                    </tbody>
                </table>
                <div>
                    <span>* W.V.: Working Volume</span>
                </div>
            </div>
            
            <div>
                <ul className="available_modality_list">
                    <li>
                        <span>
                            Monoclonal<br/>
                            Antibody
                        </span>
                    </li>
                    <li>
                        <span>
                            Bispecific<br/>
                            Antibody
                        </span>
                    </li>
                    <li>
                        <span>
                            Fc-fusion<br/>
                            Protein
                        </span>
                    </li>
                    <li>
                        <span>
                            Recombinant<br/>
                            Protein
                        </span>
                    </li>
                    <li>
                        <span>
                            pDNA<br/>
                            (gene therapy)
                        </span>
                    </li>
                    <li>
                        <span>
                            Recombinant<br/>
                            Vaccine<br/>
                            (DNA vaccine)
                        </span>
                    </li>
                    <li>
                        <span>
                            (Exosome,<br></br>
                            Viral Vector, <br></br>
                            etc.)
                        </span>
                    </li>
                </ul>
            </div>

            <div>
                <h5>Process Flow {t("processFlow")}</h5>

                {/* <!-- 아래 opacityBox의 span hover시 left, right박스에 active 클래스 부여
                Upstream Process을 hover시 right에 active, Downstream Process을 hover시 left에 active --> */}
                <div className="listWrap">
                    <div className={state === 2 ? 'left active' : 'left'}>
                        <img src="/assets/images/process_flow_left.png" alt="Process flow"/>
                    </div>
                    <div className={state === 1 ? 'right active' : 'right'}>
                        <span>Buffer exchange, Filtration, Virus filtration, etc.</span>
                        <img src="/assets/images/process_flow_right.png" alt="Process flow"/>
                    </div>
                </div>

                <div className="opacityBox">
                    <span onMouseOver={()=>setState(1)}>Upstream Process</span>
                    <span onMouseOver={()=>setState(2)}>Downstream Process</span>
                </div>

                <div className="mobileBox">
                    <img src="/assets/images/process_flow_mo.png" alt="Process flow (단클론항체 기준)"/>
                </div>
            </div>

        </div>
        </>
    )
}