import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { getCookie } from "../../../../util/cookie/cookies";
import { Benefits } from "./sub/benefits";
import { Employment } from "./sub/employment";
import { IdealTalent } from "./sub/idealTalent";

export const RecruitmentInformation = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const {thirdUrl} = useParams()
    const [state, setState] = useState<{classname:string;title_kr:string;title_en:string}>({classname:'',title_kr : '',title_en: ''})
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/recruitmentInfomation/idealTalent': setState((prev)=>({...prev, classname: 'ideal_talent',title_kr:'인재상',title_en : 'Ideal Candidate'}));break;
            case '/recruitmentInfomation/benefits': setState((prev)=>({...prev, classname: 'welfare_benefits',title_kr:'복리후생',title_en : 'Benefits'}));break;
            case '/recruitmentInfomation/employment': setState((prev)=>({...prev, classname: 'job_posting',title_kr:'채용안내',title_en : 'Careers'}));break;
        }
    },[location])

    return(
        <>
        <div className={`subPage recruitment_information ${state.classname}`}>
            <div className="banner_area">
                <div>
                    <h2>{lang === 'Kr'? state.title_kr: state.title_en}</h2>
                </div>
            </div>
            <NavigationBox/>
            {location.pathname === '/recruitmentInfomation/idealTalent' ? <IdealTalent/> : ''}
            {location.pathname === '/recruitmentInfomation/benefits' ? <Benefits/> : ''}
            {location.pathname === '/recruitmentInfomation/employment' ? <Employment/> : ''}
        </div>
        </>
    )
}