import React, { useState } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
export const backDropStyles = {color: '#fff', width : '100%'}
export interface Props {open : boolean}
export const BackDrop:React.FC<Props> = ({open}) => {
    
    return(
        <>
        <div>
            <Backdrop
            style={backDropStyles}
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            >
            <CircularProgress color="inherit" />
            </Backdrop>
        </div>
        </>
    )
}