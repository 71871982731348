import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { SelectLangBox } from "../../../../../../components/contents/regist/selectLangBox";
import { SingleAttachedBox } from "../../../../../../components/contents/regist/singleAttachedPDFBox";
import { SingleContentsBox } from "../../../../../../components/contents/regist/singleContentsBox";
import { SingleDatePickerBox } from "../../../../../../components/contents/regist/singleDatePickerBox";
import { useGetProductPermissionHistoryDetailMutation, useSetProductPermissionHistoryMutation, useUpdProductPermissionHistoryMutation } from "../../../../../../store/admin/adminContentsApiSlice";
import { RegistHeader } from "../regist-header/regist-header";

export const RegistPermitChange = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [getProductPermissionHistoryDetailApi] = useGetProductPermissionHistoryDetailMutation()
    const [editPermissionHistoryApi] = useUpdProductPermissionHistoryMutation()
    const [setPermitChangeApi] = useSetProductPermissionHistoryMutation()
    const initData = {permissionClass : 'P', subjectKr:'',subjectEn:'',contentKr:'',contentEn:'', attachedFileKr:'',attachedFileEn:'', date : ''}
    const [data, setData] = useState<any>(initData)
    const [fileData, setFileData] = useState<any>({
        attachedFile1Url : '', attachedFileName1 : '',attachedFile2Url : '', attachedFileName2 : '',
    })
    const getProductPermissionHistoryDetail = async() => {
        const result : any = await getProductPermissionHistoryDetailApi({idx : id})
        if(result.data.Result==='true'){
            setData((prev:any)=>({...prev, subjectKr : result?.data?.List[0]?.permissionSubjectKr, contentKr : result?.data?.List[0]?.permissionContentsKr, contentEn : result?.data?.List[0]?.permissionContentsEn,
            subjectEn : result.data.List[0].permissionSubjectEn, date : result?.data?.List[0].permissionMdate}));
            setFileData((prev:any)=>({...prev, attachedFile1Url : result?.data?.List[0].PermissionAttachedKrFile, attachedFileName1 : result.data.List[0].PermissionAttachedKrFilename,
            attachedFile2Url : result?.data?.List[0].PermissionAttachedEnFile, attachedFileName2 : result.data.List[0].PermissionAttachedEnFilename,}))
        }
    }
    const handleSave = async() => {
        const formData :any= new FormData();
        if(id){formData.append("idx", id)}
        formData.append('permissionRegister' , 1);
        formData.append('permissionClass', data?.permissionClass)
        formData.append('permissionSubjectKr', data?.subjectKr)
        formData.append('permissionSubjectEn', data?.subjectEn)
        formData.append('permissionContentKr', data?.contentKr)
        formData.append('permissionContentEn', data?.contentEn)
        formData.append('permissionMdate', data?.date)
        if(data?.attachedFileKr){formData.append('permissionAttachedFileKr', data?.attachedFileKr)}
        if(data?.attachedFileEn){formData.append('permissionAttachedFileEn', data?.attachedFileEn)}
        try{
            if(id){
                const result : any = await editPermissionHistoryApi(formData)
                if(result.data.result === true) {alert("수정이 완료되었습니다."); navigate(-1)}
                else{alert("오류가 발생하였습니다.")}
            } else {
                const result : any = await setPermitChangeApi(formData)
                if(result.data.result === true) {navigate('/dotsAdmin/products/P')}
                else{alert("오류가 발생하였습니다.")}
            }
        }catch{alert("서버 통신 오류가 발생했습니다.")}
    }

    useEffect(()=> {
        if(id){getProductPermissionHistoryDetail()}
        else {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            const todayDate = `${year}-${month}-${day}`;
            setData((prev: any) => ({ ...prev, date: todayDate }));
          }
    }, [id])
    
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'제품허가정보 등록'} backUrl={'/dotsAdmin/products/permitchange'} onSave={handleSave}/>

            <table>
                <tbody>
                    <MultiInputBox title={'제목'} name={'subject'} krValue={data?.subjectKr} enValue={data?.subjectEn} setData={setData}/>
                    <SingleContentsBox title={'국문 본문'} value={data?.contentKr} name={'contentKr'} setData={setData}/>
                    <SingleContentsBox title={'영문 본문'} value={data?.contentEn} name={'contentEn'} setData={setData}/>
                    <SingleAttachedBox title={'국문첨부파일'} name={'attachedFileKr'} setData={setData} detail={fileData.attachedFileName1} url={fileData.attachedFile1Url}/>
                    <SingleAttachedBox title={'영문첨부파일'} name={'attachedFileEn'} setData={setData} detail={fileData.attachedFileName2} url={fileData.attachedFile2Url}/>
                    <SingleDatePickerBox title={'등록일자'} name={'date'} value={data?.date} setData={setData}/>
                </tbody>
            </table>
        </div>
        </>
    )
}