import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { firstNavItem, second_binexItem, second_bioMedicineItem, second_ethical, second_invest, second_recruitmentInfomation, second_syntheticMedicineItem, third_aboutItem, third_ethical_1, third_ethical_2, third_invest, third_syntheticMedicineItem_1, third_syntheticMedicineItem_2 } from "../../data/navBoxData";
import { getCookie } from "../../util/cookie/cookies";

export const NavigationBox = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const navigate = useNavigate()
    const naviRef = {
        firstDiv : useRef<any>(null), secondDiv : useRef<any>(null), thirdDiv : useRef<any>(null)
    }
    const {secondUrl, thirdUrl} = useParams()
    const [data, setData] = useState<{secondData:any[],thirdData:any[]}>({secondData : [], thirdData: []})
    const path = location.pathname.split('/')
    const [isOpen, setIsOpen] = useState<any>([false, false,false])
    // 첫번째 네비게이션 버튼 표시
    const firstTitle = firstNavItem.find((x)=>x.location === path[1])
    const secondTitle = data?.secondData?.find((y)=>y.location === path[2])
    const thirdTitle = data?.thirdData?.find((z)=>z.location === path[3])
    const handleAcitveUp = (index: number) => {
        const newActiveStates = [...isOpen];
        newActiveStates[index] = !newActiveStates[index];
        setIsOpen(newActiveStates);
    }
    const handleNavTrans = (url : string) => {navigate(url)}
    
    useLayoutEffect(()=>{
        switch(path[1]){
            case 'binex' : setData((prev)=>({...prev, secondData : second_binexItem})); break;
            case 'bioMedicine' : setData((prev)=>({...prev, secondData : second_bioMedicineItem})); break;
            case 'syntheticMedicine' : setData((prev)=>({...prev, secondData : second_syntheticMedicineItem})); break;
            case 'invest' : setData((prev)=>({...prev, secondData : second_invest})); break;
            case 'ethical' : setData((prev)=>({...prev, secondData : second_ethical})); break;
            case 'recruitmentInfomation' : setData((prev)=>({...prev, secondData : second_recruitmentInfomation})); break;
            case 'contactUs' : setData((prev)=>({...prev, secondData : []})); break;
        }
        switch(secondUrl){
            case 'about' : setData((prev)=>({...prev, thirdData : third_aboutItem})); break;
            case 'productAnnouncement' : setData((prev)=>({...prev, thirdData : third_syntheticMedicineItem_1})); break;
            case 'search' : setData((prev) => ({...prev, thirdData : []}));break;
            case 'probiitics' : setData((prev)=>({...prev, thirdData : third_syntheticMedicineItem_2})); break;
            case 'stockInformation' : setData((prev)=>({...prev, thirdData : third_invest})); break;
            case 'codeOfehicsAndimplement' : setData((prev)=>({...prev, thirdData : third_ethical_1})); break;
            case 'reportingAndprocedure' : setData((prev)=>({...prev, thirdData : third_ethical_2})); break;
        }
    }, [location])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (naviRef.firstDiv.current && !naviRef.firstDiv.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [false, prev[1], prev[2]]);
          }
          if (naviRef.secondDiv.current && !naviRef.secondDiv.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [prev[0], false, prev[2]]);
          }
          if (naviRef.thirdDiv.current && !naviRef.thirdDiv.current.contains(event.target as Node)) {
            setIsOpen((prev:any) => [prev[0], prev[1], false]);
          }
        };
        document.addEventListener("click", handleClickOutside);
        return () => {
          document.removeEventListener("click", handleClickOutside);
        };
      }, [naviRef]);
    return(
        <>
        <div className="stickyNavBox">
            <div>
                <span></span>
                <div onClick={()=>handleAcitveUp(0)} ref={naviRef.firstDiv}>
                    <span className={isOpen[0]?'active':''}>{lang === 'Kr'?firstTitle?.title_kr:firstTitle?.title_en}</span>
                    <ul>
                        {firstNavItem.map((x:{title_kr:string,title_en:string,location:string,initUrl:string}, index:number) => (
                        <li key={index} onClick={()=>handleNavTrans(x.initUrl)}>
                            {/* 문의하기 페이지를 제외하고 출력 */}
                            {x.initUrl !== '/contactUs' ? 
                            <span>{lang==='Kr'?x.title_kr:x.title_en}</span> :
                            ''
                            }
                        </li>
                        ))}
                    </ul>
                </div>
                {data?.secondData?.length !== 0 ? 
                <div onClick={()=>handleAcitveUp(1)} ref={naviRef.secondDiv}>
                    <span className={isOpen[1]?'active':''}>{lang==='Kr'?secondTitle?.title_kr:secondTitle?.title_en}</span>
                    <ul>
                        {data?.secondData?.map((y:any,index:number) => (
                        <li key={index} onClick={()=>handleNavTrans(y.initUrl)}>
                            {lang === 'En'&& y.title_en === '' ? '' : 
                            <span>{lang==='Kr'?y.title_kr:y.title_en}</span>
                            }
                        </li>
                        ))}
                    </ul>
                </div> : ''
                
                }
                
                {data?.thirdData?.length !== 0? 
                <div onClick={()=>handleAcitveUp(2)} ref={naviRef.thirdDiv}>
                    <span className={isOpen[2]?'active':''}>{lang==='Kr'?thirdTitle?.title_kr:thirdTitle?.title_en}</span>
                    <ul>
                        {data?.thirdData?.map((z:any,index:number) => (
                        <li key={index} onClick={()=>handleNavTrans(z.initUrl)}>
                            <span>{lang==='Kr'?z.title_kr:z.title_en}</span>
                        </li>
                        ))}
                    </ul>
                </div>
                : ''
                }
            </div>
        </div>
        </>
    )
}