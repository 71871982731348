import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { List } from "./contents/list";
import { RegistCommonCode } from "./contents/regist/regist-type/regist-commonCode";
import { RegistObesityMedicine } from "./contents/regist/regist-type/regist-obesityMedicine";
import { RegistPermitChange } from "./contents/regist/regist-type/regist-permitChange";
import { RegistProduct } from "./contents/regist/regist-type/regist-product";
import { DashboardHeader } from "./header/dashboard-header";
import { ReportList } from "./contents/list/list-type/list-report";
import { ReportView } from "./contents/regist/regist-type/view-report";
import { DashboardSidebar } from "./sideBar/dashboard-sidebar";
import { AdminStratorsList } from "./setting/list/list-adminStrators";
import { AdminStratorsRegist } from "./setting/regist/regist-adminStrators";
import { InquiryView } from "./contents/regist/regist-type/view-inquiry";
import { ElectronicNoticeView } from "../../user/subPage/invest/electronicNoticeView";
import { InvestNoticeRegist } from "./contents/regist/regist-type/regist-investNotice";
import { useGetAdminInfoMutation } from "../../../store/admin/adminstrators";
import { useAppDispatch } from "../../../store/storeHooks";
import { setAdminInfo } from "../../../store/admin/adminInfoSlice";
import { RegistPromotion } from "./contents/regist/regist-type/regist-promotion";

export const DashBoard = () => {
    const {type, id, adminId} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const [getAdminInfoApi] = useGetAdminInfoMutation()
    const getAdminInfo = async(adminId : string) => {
        const result : any = await getAdminInfoApi({id : adminId})
        if(result.data.result === true){
            dispatch(setAdminInfo({adminId : result.data.ID, account : result.data.manager_id, name : result.data.manager_name, groupId : result.data.manager_group}))
        } else {
            navigate('/dotsAdmin')
        }
    }
    useEffect(()=>{
        const storeId = sessionStorage.getItem('BinexKey')
        if(storeId){
            const adminId = storeId
            getAdminInfo(adminId)
        } else {
            navigate('/dotsAdmin')
        }
    }, [dispatch])
    return(
        <>
        <div className="wrap_admin">
            <DashboardSidebar/>
            <div className="dotsContentWrap">
                <DashboardHeader/>
                {location.pathname === '/dotsAdmin/commoncode' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/products/product' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/products/P' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/products/O' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/reporting/report' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/reporting/inquiry' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/invest/notice' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/promotion' ? <List/> : ''}
                {location.pathname === '/dotsAdmin/commoncode/regist' ? <RegistCommonCode/> : ''}
                {location.pathname === `/dotsAdmin/commoncode/${id}` ? <RegistCommonCode/> : ''}
                {location.pathname === `/dotsAdmin/products/product/${id}` ? <RegistProduct/> : ''}
                {location.pathname === '/dotsAdmin/products/product/regist' ? <RegistProduct/> : ''}
                {location.pathname === `/dotsAdmin/products/P/${id}` ? <RegistPermitChange/> : ''}
                {location.pathname === '/dotsAdmin/products/P/regist' ? <RegistPermitChange/> : ''}
                {location.pathname === `/dotsAdmin/products/O/${id}` ? <RegistObesityMedicine/> : ''}
                {location.pathname === '/dotsAdmin/products/O/regist' ? <RegistObesityMedicine/> : ''}
                {location.pathname === `/dotsAdmin/reporting/report/${id}` ? <ReportView/> : ''}
                {location.pathname === `/dotsAdmin/reporting/inquiry/${id}` ? <InquiryView/> : ''}
                {location.pathname === '/dotsAdmin/invest/notice/regist' ? <InvestNoticeRegist/> : ''}
                {location.pathname === `/dotsAdmin/invest/notice/${id}` ? <InvestNoticeRegist/> : ''}
                {location.pathname === `/dotsAdmin/promotion/regist` ? <RegistPromotion/> : ''}
                {location.pathname === `/dotsAdmin/promotion/${id}` ? <RegistPromotion/> : ''}

                {location.pathname === `/dotsAdmin/setting/adminstrator` ? <AdminStratorsList/> : ''}
                {location.pathname === `/dotsAdmin/setting/adminstrator/regist` ? <AdminStratorsRegist/> : ''}
                {location.pathname === `/dotsAdmin/setting/adminstrator/${adminId}` ? <AdminStratorsRegist/> : ''}
                {location.pathname === `/dotsAdmin/setting/popup-management` ? <AdminStratorsList/> : ''}

            </div>
        </div>
        </>
    )
}