import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin/finance";

export const adminInvestApiSlice = createApi({
  reducerPath: "adminInvestApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 전자공고 리스트 가져오기 
    getFinanceSettlementList : builder.mutation({
      query : (param : {workplace : string,page:any,size:number,keyword:string,sortColumn:string,sortOrder:string}) => {
        return{
          url : `/getFinanceSettlementList.php?page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    // 전자공고 등록하기
    setFinanceSettlement : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setFinanceSettlement.php', method : 'post', body
        }
      }
    }),
    // 전자공고 수정하기
    updFinanceSettlement : builder.mutation({
      query : (body : any) => {
        return{
          url : '/updFinanceSettlement.php', method: 'post', body
        }
      }
    }),
    // 전자공고 상세보기
    getFinanceSettlementDetail : builder.mutation({
      query : (param : {id : string}) => {
        return{
          url : `/getFinanceSettlementDetail.php?idx=${param.id}`
        }
      }
    }),
    // 전자공고 공개여부 수정하기
    updFinanceSettlementActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/updFinanceSettlementActiveStatus.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetFinanceSettlementListMutation, useSetFinanceSettlementMutation, useUpdFinanceSettlementMutation, useGetFinanceSettlementDetailMutation, useUpdFinanceSettlementActiveStatusMutation
} = adminInvestApiSlice;