import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export interface Props {onSort:any}
export const ListFilter:React.FC<Props> = ({onSort}) => {
    const location = useLocation()
    const [data,setData] = useState<any>([])
    const [sortOtion, setSortOption] = useState<{column : string, order: string}>({column : 'date', order : 'desc'})
    const handleSortOrder = (column:string) => {
        if(column !== ''){
            if(sortOtion.column === column){setSortOption((prev:any) => ({...prev, order : sortOtion.order === 'desc' ? 'asc' : 'desc'}))}
            else {
                setSortOption((prev:any) => ({...prev, column : column, order : 'desc'}))
            }
        }
    }
    useEffect(()=>{onSort(sortOtion.order, sortOtion.column)}, [sortOtion])
    useEffect(()=>{
        switch(location.pathname){
            case '/dotsAdmin/commoncode' : setData(commonCodeFilter); break;
            case '/dotsAdmin/products/product' : setData(productFilter); break;
            case '/dotsAdmin/products/P' : case '/dotsAdmin/products/O' : setData(permitFilter); break;
            case '/dotsAdmin/reporting/report' : setData(reportFilter); break;
            case '/dotsAdmin/reporting/inquiry' : setData(inquiryFilter); break;
            case '/dotsAdmin/invest/notice' : setData(noticeFilter); break;
            case '/dotsAdmin/promotion' : setData(promotionFilter); break;
        }
    }, [location])
    return(
        <>
        <thead>
            <tr>
                {location.pathname === '/dotsAdmin/products/product'? <th><span>제품이미지</span></th> : ''}
                
                {data?.map((data:any, index:number)=>{
                    const isSortDisplay = data.column === '' ? '' : 'sort'
                    return(
                    <th key={index}>
                        <span className="sortWrap" onClick={()=>handleSortOrder(data?.column)}>
                            {data?.title}
                            {sortOtion.column === data.column ?
                                <span className={`sort ${sortOtion.order === 'desc' ? 'desc' : 'asc'}`}></span>
                                :<span className={isSortDisplay}></span>
                            }
                        </span>
                    </th>
                    )
                })}
            </tr>
        </thead>
        </>
    )
}

export const commonCodeFilter = [{idx: 1, title : '공용코드명', column : 'codeNameKr'},{idx:3, title:'등록일자', column : 'date'}, {idx:4, title:'', column : ''}]
export const productFilter = [{idx: 2, title : '제품명', column : 'subject'}, {idx:4, title:'등록일자', column : 'date'},{idx:5, title:'공개여부', column : 'openYn'}]
export const permitFilter = [{idx: 1, title : '등록일자', column : 'date'}, {idx:2, title:'제목', column : 'subject'}, {idx:3, title:'공개여부', column : 'openYn'}]
export const reportFilter = [{idx:1, title : '제보유형', column : ''}, {idx:2, title:'제보명',column:'wbSubject'},{idx:3, title:'제보사업장',column:''},{idx:4, title:'제보자명',column:'wbName'},{idx:5, title:'등록일자', column:'date'}, {idx:6, title:'처리상태', column : ''}, {idx:7, title:'삭제', column : ''}]
export const inquiryFilter = [{idx: 1, title : '회사명', column : 'inquiry_company_name'},{idx: 2, title : '고객명', column : 'inquiry_name'}, {idx: 2, title : '문의유형', column : ''},{idx: 3, title : '이메일', column : 'inquiry_email'},{idx: 4, title : '연락처', column : 'inquiry_phone'},
{idx: 5, title : '문의날짜', column : 'date'}, {idx : 6, title : '답변상태', column : 'inquiry_reply_status'}]
export const noticeFilter = [{idx: 1, title : '등록일자', column : 'date'}, {idx : 2, title : '제목', column : 'subject'}, {idx : 3, title : '공개여부', column : ''}]
export const promotionFilter = [{idx: 1, title : '등록일자', column :'date'}, {idx: 2, title : '제목', column :'promSubject'}, {idx: 3, title : '공개여부', column :''}]