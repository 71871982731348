import React from "react";

export const MicrobialAnaylsisService = () => {

    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    Probiotics <span>미생물 동정 분석 서비스</span>
                </h3>
                <dl>
                    <dt>균제제 허가 및 제조 시 미생물 동정 시험(16S rDNA sequencing)은 필수 항목입니다.</dt>
                    <dd>
                        『의약품의 품목허가∙신고∙심사 규정』 제7조에 따라 균종의 기원을 확인할 수 있는 유전자분석결과 등의 자료가 허가 시 제출되어야 하며 이는<br></br> 『유산균제제
                        품질확보를 위한 규격설정 가이드라인』 에 제시된 16S rDNA 확인 시험으로 입증이 가능합니다.
                    </dd>
                    <dd>
                        『의약품 등의 제조업 및 수입자의 시설기준령 시행규칙』 제11조 제1항 제2호에 따라 16S rDNA 확인시험을 GMP 인증기관에서 진행해야 하며<br></br> 특히
                        품질시험 항목은 GMP기관 또는 식약처 지정 위탁 시험기관에서 진행되어야 합니다.
                    </dd>
                </dl>

                <dl>
                    <dt>
                        바이넥스는 BGMP 인증 및 균제제 전용 생산 시설을 보유한 GMP 인증 업체로서 연구소가 아닌 품질부서에서 엄격한 의약품 품질
                        관리 기준에 따라 16S rDNA sequencing 분석을 실시하고 있습니다.
                    </dt>
                    <dd>500bp 16S rDNA Identification – 약 500bp의 유전자 염기서열을 분석</dd>
                    <dd>Full Gene 16S rDNA Identification – 약 1,500bp의 전체 유전자 염기서열을 분석</dd>
                    <dd>D2 rDNA Fungal Identification – 26S rDNA 중 D2 region을 분석</dd>
                </dl>

                <div className="imgBox">
                    <img src="/assets/images/microbial_identification_analysis_service.png" alt="미생물 동정 분석 서비스"/>
                </div>

                <h4>미생물 동정 위탁 시험 문의 또는 신청</h4>
                <div className="listBox">
                    <div>
                        <span>FAX</span>
                        <span>051-790-4500</span>
                    </div>
                    {/* <!-- fax Fin --> */}
                    <div>
                        <span>E-MAIL</span>
                        <span>gene@bi-nex.com</span>
                    </div>
                    {/* <!-- e-mail Fin --> */}
                    <div>
                        <span>TEL</span>
                        <span>051-790-4412</span>
                    </div>
                    {/* <!-- tel Fin --> */}
                </div>
            </div>

        </div>
        </>
    )
}