import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";

export const Greetings = () => {
    const lang = getCookie('BINEXLANG')
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3><span>바이넥스는 합성의약품 및 바이오의약품 사업</span>을 영위하고 있으며,</h3>
                : 
                <h3>Binex is engaged in <span>Chemical Drug Business and Biologics CDMO Business</span></h3>
                    
                }
                
                <p>
                    {t("greetings_sub_title_1")}<br/>
                    {t("greetings_sub_title_2")}
                </p>
                <div>
                    <p>
                    {t("greetings_content_1")}
                    </p>
                    {lang === 'Kr' ? 
                    <p>
                        우리의 실천 덕목은 "주주만족"과 "고객만족"입니다. 주식회사의 주인은 주주이며, 당사는 주주님들의 믿음에 보답하고자 최선의 노력을 경주하고 있습니다.<br></br>
                        또한 당사를 믿고 거래하는 모든 고객이 만족할 수 있도록 오늘도 임직원 모두가 하나 되어 열의를 다하고 있습니다. 이는 당사를 믿고 응원해주시는 주주님들과 고객님들에 대한 당연한 보답이며 더 나아가 바이넥스가 한 단계 더 성장할 수 있는 발판이 될 것이라 믿습니다.
                    </p>
                    : 
                    <p>Our guiding principles are "Shareholder Satisfaction" and "Customer Satisfaction." All our employees are united and dedicated today as well to ensure that every customer who believes in and trades with us can be satisfied.</p>
                        
                    }
                    <p>
                    {t("greetings_content_3")}
                    </p>
                    <p>{t("greetings_content_4")}</p>
                    <span>
                    {t("greetings_content_5")} <span>{t("greetings_content_6")}</span>
                    </span>
                </div>
            </div>
        </div>
        </>
    )
}