import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAdminDetailMutation, useSetManagerMutation, useUptManagerMutation } from "../../../../../store/admin/adminstrators";
import { AdminStratorInputBox } from "./admin-inputBox";
import { AdminSelectBox } from "./admin-selectBox";

export const AdminStratorsRegist = () => {
    const {adminId}:any = useParams()
    const navigate = useNavigate()
    const initData = {admin_id: '' , admin_pw : '', admin_email : '', admin_name : '', admin_tel : '', admin_phone : '', admin_groupId : ''}
    const [data , setData] = useState<any>(initData)
    const [getAdminDetailApi] = useGetAdminDetailMutation()
    const [setManagerApi] = useSetManagerMutation()
    const [uptManagerApi] = useUptManagerMutation()
    const getAdminDetail = async() => {
        const result : any = await getAdminDetailApi({id : adminId})
        if(result.data.result===true){setData((prev:any)=>({...prev, admin_id : result.data.manager_login, admin_email:result.data.manager_email, admin_name:result.data.manager_name,
        admin_tel : result.data.manager_mobile, admin_phone:result.data.manager_phone, admin_groupId : result.data.manager_group}))}
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(adminId){formData.append('ID', adminId)}
        formData.append('manager_login', data?.admin_id)
        formData.append('manager_pass', data?.admin_pw)
        formData.append('manager_name', data?.admin_name)
        formData.append('manager_phone', data?.admin_phone)
        formData.append('manager_mobile', data?.admin_tel)
        formData.append('manager_email', data?.admin_email)
        formData.append('manager_group', data?.admin_groupId)
        if(adminId){
            const result : any = await uptManagerApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다."); navigate(-1)}
        }else {
            const result : any = await setManagerApi(formData)
            if(result.data.result === true){alert('등록이 완료되었습니다.'); navigate('/dotsAdmin/setting/adminstrator')}
        }
    }
    useEffect(()=>{if(adminId){getAdminDetail()}}, [adminId])
    return(
        <>
        <div className="contentBox administratorAdd">
            <h3>Setting</h3>
            <div className="flexWrap">
                <h4>Administrator</h4>
                <div>
                    <button className="blackBtn" onClick={()=>navigate('/dotsAdmin/setting/adminstrator')}>목록으로</button>
                    <button className="blueBtn" onClick={handleSave}>저장하기</button>
                </div>
            </div>
            
            <ul className="upload">
                <AdminSelectBox value={data?.admin_groupId} setData={setData}/>
                <AdminStratorInputBox type={'아이디'} name={'admin_id'} value={data.admin_id} setData={setData}/>
                <AdminStratorInputBox type={adminId ? '비밀번호 변경' : '비밀번호'} name={'admin_pw'} value={data.admin_pw} setData={setData}/>
                <AdminStratorInputBox type={'이메일'} name={'admin_email'} value={data.admin_email} setData={setData}/>
                <AdminStratorInputBox type={'관리자 이름'} name={'admin_name'} value={data.admin_name} setData={setData}/>
                <AdminStratorInputBox type={'연락처'} name={'admin_tel'} value={data.admin_tel} setData={setData}/>
                <AdminStratorInputBox type={'휴대전화'} name={'admin_phone'} value={data.admin_phone} setData={setData}/>
                <li>
                    
                </li>
            </ul>
        </div>
        </>
    )
}