import React from "react";
import { useTranslation } from "react-i18next";

export const FinishedDrug = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_2 active">
            <h4>{t("cmo_tab_2")}</h4>
            
            <div className="flexBox">
                <div className="left">
                    <ol>
                        <li>
                        {t("cmo_finishd_content_1")}
                            <ul>
                                <li>{t("cmo_finishd_content_2")}</li>
                            </ul>
                        </li>
                        <li>
                            {t("cmo_finishd_content_3")}
                            <ul>
                                <li>{t("cmo_finishd_content_3_1")}</li>
                                <li>{t("cmo_finishd_content_3_2")}</li>
                            </ul>
                        </li>
                        <li>{t("cmo_finishd_content_4")}</li>
                    </ol>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/finished_drug.png" alt="완제의약품"/>
                </div>
            </div>

            <div className="tableWrap">
                <table>
                    <thead>
                        <tr>
                            <th>System</th>
                            <th>Liquid Vial</th>
                            <th>Lyophilized Vial</th>
                            <th>Pre-Filled Syringe</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Available size</th>
                            <td>2 ~ 50 mL</td>
                            <td>2 ~ 50 mL</td>
                            <td>1 ~ 2.25 mL</td>
                        </tr>
                        <tr>
                            <th>Capacity</th>
                            <td>10,000 vials/batch</td>
                            <td>5,000 vials/batch</td>
                            <td>6,000 syringes/batch</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <h5>Process Flow</h5>
                <div className="listWrap">
                    <span>
                        <span>01</span>
                        Liquid Vial / Pre-filled Syringe
                    </span>

                    <ul className="listBox">
                        <li>
                            <div>
                                <img src="/assets/images/formulation.png" alt="Formulation"/>
                            </div>
                            <div>
                                <span>Formulation</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/filling.png" alt="Filling"/>
                            </div>
                            <div>
                                <span>Filling</span>
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/capping.png" alt="Capping"/>
                            </div>
                            <div>
                                <span>Capping</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/visual_inspection.png" alt="Visual Inspection"/>
                            </div>
                            <div>
                                <span>Visual Inspection</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/labeling.png" alt="Labeling"/>
                            </div>
                            <div>
                                <span>Labeling</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/packaging.png" alt="Packaging"/>
                            </div>
                            <div>
                                <span>Packaging</span>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="listWrap">
                    <span>
                        <span>02</span>
                        Lyophilized Vial
                    </span>

                    <ul className="listBox">
                        <li>
                            <div>
                                <img src="/assets/images/formulation.png" alt="Formulation"/>
                            </div>
                            <div>
                                <span>Formulation</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/filling.png" alt="Filling"/>
                            </div>
                            <div>
                                <span>Filling</span>
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/lyophilization.png" alt="Lyophilization"/>
                            </div>
                            <div>
                                <span>Lyophilization</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/capping.png" alt="Capping"/>
                            </div>
                            <div>
                                <span>Capping</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/visual_inspection.png" alt="Visual Inspection"/>
                            </div>
                            <div>
                                <span>Visual Inspection</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/labeling.png" alt="Labeling"/>
                            </div>
                            <div>
                                <span>Labeling</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/packaging.png" alt="Packaging"/>
                            </div>
                            <div>
                                <span>Packaging</span>
                            </div>
                        </li>
                    </ul>
                </div>
                {/* <div className="listWrap">
                    <span>
                        <span>03</span>
                        Pre-filled Syringe
                    </span>

                    <ul className="listBox">
                        <li>
                            <div>
                                <img src="/assets/images/formulation.png" alt="Formulation"/>
                            </div>
                            <div>
                                <span>Formulation</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/filling.png" alt="Filling"/>
                            </div>
                            <div>
                                <span>Filling</span>
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/visual_inspection_2.png" alt="Visual Inspection"/>
                            </div>
                            <div>
                                <span>Visual Inspection</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/labeling_2.png" alt="Labeling"/>
                            </div>
                            <div>
                                <span>Labeling</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/blistering.png" alt="Blistering"/>
                            </div>
                            <div>
                                <span>Blistering</span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/packaging.png" alt="Packaging"/>
                            </div>
                            <div>
                                <span>Packaging</span>
                            </div>
                        </li>
                    </ul>
                </div> */}
            </div>
            
        </div>
        </>
    )
}