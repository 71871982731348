import React from "react";
import { useLocation } from "react-router-dom";
export interface Props {title : string, value : string}
export const SingleTextAreaBox:React.FC<Props> = ({title, value}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    return(
        <>
        <tr className="textareaBox">
            <th>{title}</th>
            <td>
                <textarea value={value} disabled={path[2]==='reporting'?true:false}/>
            </td>
        </tr>
        </>
    )
}