import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin";

export const adminPromotionApiSlice = createApi({
  reducerPath: "adminPromotionApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 홍보센터 리스트 가져오기
    getPromotionList : builder.mutation({
      query : (param : {page:any,size:number,keyword:string,sortColumn:string,sortOrder:string}) => {
        return{
          url : `/promotion/getPromotionList.php?page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    // 홍보센터 저장
    setPromotion : builder.mutation({
        query : (body : any) => {
            return {
                url : '/promotion/setPromotion.php' , method : 'post', body
            }
        }
    }),
    // 홍보센터 글 수정
    updPromotion : builder.mutation({
        query : (body : any) => {
            return {
                url : '/promotion/updPromotion.php', method : 'post', body
            }
        }
    }),
    // 홍보센터 공개여부 수정
    updPromotionActiveStatus : builder.mutation({
        query : (body : any) => {
            return{
                url : '/promotion/updPromotionActiveStatus.php', method : 'post', body
            }
        }
    }),
    // 홍보센터 상세보기
    getPromotionDetail : builder.mutation({
        query : (param : {id : any}) => {
            return {
                url : `/promotion/getPromotionDetail.php?promId=${param.id}`
            }
        }
    })
  }),
});

export const {
  useSetPromotionMutation, useGetPromotionListMutation, useUpdPromotionActiveStatusMutation, useGetPromotionDetailMutation,
  useUpdPromotionMutation                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              
} = adminPromotionApiSlice;