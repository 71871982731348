import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../util/cookie/cookies";
import { useLocation, useNavigate } from "react-router-dom";

export const CDMOQuailty = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()
    const location = useLocation()
    const tab : any = new URLSearchParams(location.search).get('tab') || 1;
    const handleTransPage = (url : string) => {
        if(url !== location.pathname) {
            navigate(url)
        }
    }

    const [state , setState]= useState<boolean>(false)
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>Quality</h3>
                <p>
                    {lang === 'Kr' ? 
                    <strong>
                        바이넥스는 글로벌 규제기관의 규정과 지침에 부합하는 품질시스템을 구축하고 있으며, 
                        고품질의 바이오의약품을 제공하기 위한 다양한 품질 시험을 수행합니다.
                    </strong>
                    :
                    <strong>
                        BINEX's quality system complies with the regulations and guidelines of global regulatory agencies, and we conduct various quality tests to ensure the provision of high-quality biopharmaceuticals.
                    </strong>
                    }
                </p>

                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>handleTransPage(`/bioMedicine/quailty?tab=1`)}>
                            <button className={tab === '1' ? 'active' : ''}>{t("cmo_quality_content_6")}</button>
                        </div>
                        <div onClick={()=>handleTransPage(`/bioMedicine/quailty?tab=2`)}>
                            <button className={tab === '2' ? 'active' : ''}>{t("cmo_quality_content_1")}</button>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    <div className={tab === '1' ? 'content_3 active' : ''}>
                        <h4>{t("cmo_quality_content_6")}</h4>
                        <div className="flexBox">
                            <div className="left">
                                <ul className="checkList">
                                    <li>
                                        {t("cmo_quality_content_7")}
                                    </li>
                                    <li>
                                        {t("cmo_quality_content_8")}
                                    </li>
                                    <li>
                                        {t("cmo_quality_content_9")} <br></br>
                                        {t("cmo_quality_content_9_1")}
                                    </li>
                                </ul>
                            </div>
                            <div className="imgBox right">
                                <img src="/assets/images/quality_1.png" alt="Quality"/>
                            </div>
                        </div>


                        <div className="programBox">
                            <h5>Quality Program</h5>
                            <ul>
                                <li>
                                    <span>
                                        <span>Quality System</span>
                                    </span>
                                    <div className="colspan">
                                        <div>
                                            <span>
                                                Quality<br/>
                                                Management
                                            </span>
                                            <span>
                                                Document &<br/>
                                                Records<br/>
                                                Management
                                            </span>
                                            <span>
                                                Change<br/>
                                                Management
                                            </span>
                                            <span>
                                                Deviation<br/>
                                                Management<br/>
                                                /CAPA
                                            </span>
                                            <span>
                                                Complaint<br/>
                                                Management
                                            </span>
                                            <span>
                                                Lot<br/>
                                                Disposition
                                            </span>
                                        </div>
                                        <div>
                                            <span>
                                                Audit<br/>
                                                Management
                                            </span>
                                            <span>
                                                Personnel<br/>
                                                Management
                                            </span>
                                            <span>
                                                Regulatory<br/>
                                                Interaction<br/>
                                                Management
                                            </span>
                                            <span>
                                                Annual<br/>
                                                Product Review
                                            </span>
                                            <span>Validation</span>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            Facilities&<br/>
                                            Equipment
                                        </span>
                                    </span>
                                    <div>
                                        <span>
                                            Facility&Unit<br/>
                                            Management
                                        </span>
                                        <span>
                                            Equipment<br/>
                                            Management
                                        </span>
                                        <span>
                                            Environmental<br/>
                                            Control
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span>
                                        <span>Materials</span>
                                    </span>
                                    <div>
                                        <span>
                                            Supplier<br/>
                                            Management
                                        </span>
                                        <span>
                                            Materials<br/>
                                            Management
                                        </span>
                                        <span>
                                            Contract<br/>
                                            Management
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span>
                                        <span>Production</span>
                                    </span>
                                    <div>
                                        <span>Processing</span>
                                        <span>
                                            Technology<br/>
                                            Transfer
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span>
                                        <span>
                                            Packaging &<br/>
                                            Labeling
                                        </span>
                                    </span>
                                    <div>
                                        <span>
                                            Packaging&<br/>
                                            Labeling
                                        </span>
                                    </div>
                                </li>
                                <li>
                                    <span>
                                        <span>Laboratory Control</span>
                                    </span>
                                    <div className="colspan">
                                        <div>
                                            <span>
                                                Laboratory<br/>
                                                Management
                                            </span>
                                            <span>
                                                Test<br/>
                                                Performance<br/>
                                                and Review
                                            </span>
                                            <span>
                                                Laboratory<br/>
                                                Insvestigations<br/>
                                                (OOS/OOT/<br/>
                                                Lab Deviations)
                                            </span>
                                            <span>Stability</span>
                                        </div>
                                        <div>
                                            <span>
                                                Reagent and<br/>
                                                Standard<br/>
                                                Management
                                            </span>
                                            <span>
                                                Method<br/>
                                                Transfer
                                            </span>
                                            <span>Monitoring</span>
                                            <span>
                                                Sampling and<br/>
                                                Inspection
                                            </span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h5>Document Control System</h5>
                            <div className="imgBox">
                                <img src="/assets/images/document_control_system.png" alt="Document Control System"/>
                            </div>
                        </div>

                    </div>
                    {/* <!-- Quality Fin --> */}



                    <div className={tab === '2' ? 'content_3 active' : ''}>
                        <h4>{t("cmo_quality_content_1")}</h4>
                        <div className="flexBox">
                            <div className="left">
                                <ul className="checkList">
                                    <li>{t("cmo_quality_content_2")}</li>
                                    <li>{t("cmo_quality_content_3")}</li>
                                    <li>{t("cmo_quality_content_4")}</li>
                                    <li>{t("cmo_quality_content_5")}</li>
                                </ul>
                            </div>
                            <div className="imgBox right">
                                <img src="/assets/images/quality_2.png" alt="Quality"/>
                            </div>
                        </div>

                        
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Lot Release Test</th>
                                        <th>Stability Test</th>
                                        <th>Method Validation</th>
                                        <th>Others</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li>Physico-chemical test</li>
                                                <li>Biochemical test</li>
                                                <li>Microbial test</li>
                                                <li>Bioassay</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Long term stability test</li>
                                                <li>Accelerated stability test</li>
                                                <li>Stressed stability test</li>
                                                <li>Freeze-thaw test</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>
                                                    Accuracy, Repeatability,
                                                    Intermediate precision, Specificity,
                                                    Detection limit, Quantitation limit,
                                                    Linearity, Range, Robustness
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Incoming test</li>
                                                <li>etc.</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        
                        <h4>{t("quailty_1")}</h4>
                        <p>{t("quailty_2")}</p>
                        {/* <div className="flexBox">
                            <div className="left">
                                <ul className="checkList">
                                    <li>프로젝트 특성에 따른 다양한 분석법 시험 및 이전이 가능한 QC 역량 보유</li>
                                    <li>USP, EP, JP, KP 등 다양한 글로벌 기준을 충족하는 품질시험 수행</li>
                                    <li>cGMP를 준수하는 환경 및 Utility 모니터링 수행</li>
                                    <li>수탁시험 서비스(contract QC testing) 제공</li>
                                </ul>
                            </div>
                            <div className="imgBox right">
                                <img src="/assets/images/quality_2.png" alt="Quality"/>
                            </div>
                        </div> */}

                        
                        <div className="tableWrap">
                            <table>
                                <thead>
                                    <tr>
                                        <th colSpan={2}>{t("quailty_3")}</th>
                                        <th colSpan={2}>{t("quailty_4")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <ul>
                                                <li>Western Blot</li>
                                                <li>Isoelectric Focusing</li>
                                                <li>SDS-PAGE</li>
                                                <li>{t("quailty_5")}</li>
                                                <li>Amino Acid</li>
                                                <li>Capillary Electrophoresis</li>
                                                <li>Peptide Mapping</li>
                                                <li>N-terminal Sequencing</li>
                                                <li>HPLC</li>
                                                <li>GC</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Host Cell Protein</li>
                                                <li>{t("quailty_6")}</li>
                                                <li>Endotoxin Test</li>
                                                <li>{t("quailty_7")}</li>
                                                <li>Sterility Test</li>
                                                <li>{t("quailty_8")}</li>
                                                <li>GC Assay</li>
                                                <li>SDS-PAGE</li>
                                                <li>Enzyme Immunoassay</li>
                                                <li>Cell-based Bioassay</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>Appearance Test</li>
                                                <li>{t("quailty_9")}</li>
                                                <li>Identification Test</li>
                                                <li>{t("quailty_10")}</li>
                                                <li>{t("quailty_11")}</li>
                                                <li>{t("quailty_12")}</li>
                                                <li>pH Test</li>
                                                <li>UV/VIS</li>
                                                <li>Optical Rotation</li>
                                                <li>Water Content</li>
                                            </ul>
                                        </td>
                                        <td>
                                            <ul>
                                                <li>{t("quailty_13")}</li>
                                                <li>{t("quailty_14")}</li>
                                                <li>{t("quailty_15")}</li>
                                                <li>FT-IR</li>
                                                <li>{t("quailty_16")}</li>
                                                <li>{t("quailty_17")}</li>
                                                <li>{t("quailty_18")}</li>
                                                <li>{t("quailty_19")}</li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
      
                        <div className="contactBox">
                            <div>
                                <div>
                                    <img src="/assets/images/quality_email.png" alt="email"/>
                                </div>
                                <div>
                                    <span>E-MAIL</span>
                                    <span>binexcmo@bi-nex.com</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <img src="/assets/images/quality_call.png" alt="call"/>
                                </div>
                                <div>
                                    <span>TEL</span>
                                    <span>032-850-3249</span>
                                </div>
                            </div>
                            <div>
                                <div>
                                    <img src="/assets/images/quality_download.png" alt="download"/>
                                </div>
                                <div className="btnBox">
                                    {lang === 'Kr' ? 
                                    <a href='/Quality_test_request_form(Rev.20)_KOR.xlsx' download>{t("quailty_btn")}</a>
                                    :
                                    <a href='/Quality_test_request_form(Rev.18)_ENG.xlsx' download>{t("quailty_btn")}</a>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}