import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../../../util/cookie/cookies";

export const ProcessingProcedure = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const [state,setState] = useState<number>(0)
    const handleMouseUp = (idx:number) => {
        setState(idx)
    }
    const handleMouseOut = () =>{
        setState(0)
    }
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    제보 내용을 제보자 보호의 원칙하에 확인하여
                    <span>사실여부 확인 시 적절한 조치를 취할 것을 약속드립니다.</span>
                </h3>
                : 
                <h3>
                    <span>We assure you that the reported information will be verified</span> in accordance with the principles of whistleblower protection, and appropriate actions will be taken upon confirmation of its accuracy.
                </h3>
                }

                <div className="tabBtnBox">
                    <div>
                        <div className="step_1" onMouseOver={()=>handleMouseUp(1)} onMouseLeave={handleMouseOut}>
                            <div>
                                <div>
                                    <span>STEP 01</span>
                                    <span>{t("report_process_1")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- step_1 Fin --> */}
                        <div className="step_2" onMouseOver={()=>handleMouseUp(2)} onMouseLeave={handleMouseOut}>
                            <div>
                                <div>
                                    <span>STEP 02</span>
                                    <span>{t("report_process_2")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- step_2 Fin --> */}
                        <div className="step_3" onMouseOver={()=>handleMouseUp(3)} onMouseLeave={handleMouseOut}>
                            <div>
                                <div>
                                    <span>STEP 03</span>
                                    <span>{t("report_process_3")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- step_3 Fin --> */}
                        <div className="step_4" onMouseOver={()=>handleMouseUp(4)} onMouseLeave={handleMouseOut}>
                            <div>
                                <div>
                                    <span>STEP 04</span>
                                    <span>{t("report_process_4")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- step_4 Fin --> */}
                        <div className="step_5" onMouseOver={()=>handleMouseUp(5)} onMouseLeave={handleMouseOut}>
                            <div>
                                <div>
                                    <span>STEP 05</span>
                                    <span>{t("report_process_5")}</span>
                                </div>
                            </div>
                        </div>
                        {/* <!-- step_5 Fin --> */}
                    </div>
                </div>
                {/* <!-- tabBtnBox내에 각 step 영역을 hover 하면 아래 hoverTxtBox에서 내용에 맞게 p에 active클래스 부여, */}
                {/* 다른 step을 hover하기 전까지 아래 hoverTxtBox p에 active는 유지되어야 함. --> */}


                <div className="hoverTxtBox">
                    <p className={state===0?'none active' : 'none'}>{t("report_process_6")}</p>
                    <p className={state===1?'active' : ''}>
                        <span>{t("report_process_7")}</span>
                        {t("report_process_8")}
                    </p>
                    <p className={state===2?'active' : ''}>
                        <span>{t("report_process_9")}</span>
                        {t("report_process_10")}
                    </p>
                    <p className={state===3?'active' : ''}>
                        <span>{t("report_process_11")}</span>
                        {t("report_process_12")}
                    </p>
                    <p className={state===4?'active' : ''}>
                        <span>{t("report_process_13")}</span>
                        {t("report_process_14")}
                    </p>
                    <p className={state===5?'active' : ''}>
                        <span>{t("report_process_15")}</span>
                        {t("report_process_16")}
                    </p>
                </div>

                <div className="btnBox">
                    <button className="reportBtn" onClick={()=>navigate('/ethical/reportingAndprocedure/report')}>{t("report_process_17")}</button>
                </div>

            </div>
        </div>
        </>
    )
}