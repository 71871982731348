import React from "react";
export interface Props {title : string, name : string, value : string, setData : Function}
export const SingleDatePickerBox:React.FC<Props> = ({title, name, value, setData}) => {
    const handleChange = (e:any) => {
        const {name, value} = e.target
        setData((prev:any)=>({...prev, [name] : value}))
    }
    return(
        <>
        <tr>
            <th>{title}</th>
            <td>
                <input 
                    name={name}
                    style={{width : '240px'}}
                    value={value}
                    type="date"
                    onChange={handleChange}
                    placeholder={`등록일자을(를) 입력해 주세요.`}
                />
            </td>
        </tr>
        </>
    )
}