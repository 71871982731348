import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { SelectLangBox } from "../../../../../../components/contents/regist/selectLangBox";
import { SingleInputBox } from "../../../../../../components/contents/regist/singleInputBox";
import { useGetCommonCodeDetailMutation, useSetCommonCodeMutation, useUpdCommonCodeMutation } from "../../../../../../store/admin/adminCommonCodeApiSlice";
import { RegistHeader } from "../regist-header/regist-header";

export const RegistCommonCode = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [getCommonCodeDetailApi] = useGetCommonCodeDetailMutation()
    const [editCommonCodeApi] = useUpdCommonCodeMutation()
    const [setCommonCodeApi] = useSetCommonCodeMutation()
    const [data, setData] = useState<any>({codeId : 'EC', codeNameKr : '', codeNameEn : ''})
    const getCommonCodeDetail = async() => {
        const result : any = await getCommonCodeDetailApi({idx : id})
        if(result.data.Result === 'true'){
            setData((prev : any) => ({...prev, codeNameKr : result.data.List[0].codeNameKr, codeNameEn : result.data.List[0].codeNameEn}))
        }
    }
    const handleSave = async() => {
        const formData = new FormData()
        if(id){formData.append('idx', id)}
        formData.append('codeId', data?.codeId)
        formData.append('codeNameKr' , data?.codeNameKr)
        formData.append('codeNameEn', data?.codeNameEn)
        if(id){
            const result : any = await editCommonCodeApi(formData)
            if(result.data.result === true){alert("수정완료되었습니다."); navigate(-1)}
            else{alert("오류가 발생하였습니다.")}
        }else {
            const result:any = await setCommonCodeApi(formData);
            if(result.data.result===true){alert("등록완료되었습니다."); navigate('/dotsAdmin/commoncode')}
            else{alert("오류가 발생하였습니다.")}
        }
    }

    useEffect(()=>{
        if(id){getCommonCodeDetail()}
    }, [id])

    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'공용코드 등록 및 수정'} backUrl={'/dotsAdmin/commoncode'} onSave={handleSave}/>

            <table>
                <tbody>
                    <SingleInputBox title={'공용코드명(국문)'} name={'codeNameKr'} value={data?.codeNameKr} setData={setData}/>
                    <SingleInputBox title={'공용코드명(영문)'} name={'codeNameEn'} value={data?.codeNameEn} setData={setData}/>
                </tbody>
            </table>
        </div>
        </>
    )
}