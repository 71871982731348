import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetWhistleblowMutation } from "../../../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../../../util/cookie/cookies";
export const AnonymousReport = ({}) => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const [setWhistleblowApi] = useSetWhistleblowMutation()
    const initData = {wbName : '' , wbEmail_1 : '', wbEmail_2 : '', wbPhone_1 : '010', wbPhone_2 : '',wbPhone_3 : '' , 
    targetWorkplace : '' , targetName : '', targetDept : '', wbSubject : '' , wbContents : '', wbFile : '', wbPrivacyAgree : false, wbPrivacyProvideAgree : false}
    const [data, setData] = useState<any>(initData)
    const [fileName, setFileName] = useState<any>(null)
    const handleRadio = (place : string) => {setData((prev:any) => ({...prev, targetWorkplace : place}))}
    const handleChange = (e:any) => {
        const {type, name, value, checked} = e.target;
        if(type==='file'){
            setData((prev:any) => ({...prev, [name] : e.target.files[0]}))
            setFileName(e.target.files[0].name)
        } 
        else if(name === 'wbPrivacyAgree' || name === 'wbPrivacyProvideAgree'){setData((prev:any) => ({...prev, [name] : !prev[name]}))}
        else {setData((prev:any) => ({...prev, [name] : value}))}
    }
    const handleDelFile = () => {
        setData((prev:any) => ({...prev, wbFile : ''}))
        setFileName(null)
    }
    const handleSubmit = async() => {
        if(!data?.targetWorkplace || !data?.targetName || !data?.targetDept){
            alert("제보대상 정보를 모두 기입해주세요."); return;
        }else if(!data?.wbSubject || !data?.wbContents){
            alert("제보내용을 모두 기입해주세요."); return;
        }else{
            const formData : any = new FormData()
            formData.append('wbType', 'A')
            formData.append('wbWorkplace', data?.targetWorkplace)
            formData.append('wbTargetName', data?.targetName)
            formData.append('wbTargetDept', data?.targetDept)
            formData.append('wbSubject', data?.wbSubject)
            formData.append('wbContents', data?.wbContents)
            formData.append('wbFile', data?.wbFile)
            const result : any = await setWhistleblowApi(formData)
            if(result.data.result === true){alert("제보가 완료되었습니다."); window.location.reload()}
        }
    }
    return(
        <>
        <div className="tabContent_1 active">
            <div className="anonymous_1">
                <h4>
                    <span>01</span>
                    {t("report_reporting_1_1")}
                </h4>
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="busan_factory">{t("report_reporting_1_2")}</label>
                                    </div>
                                </th>
                                <td colSpan={3}>
                                    <div className="flexWrap radio">
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="busan_factory" onClick={(e)=>handleRadio('B')}/>
                                            <label htmlFor="busan_factory">{t("report_reporting_1_3")}</label>
                                        </div>
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="songdo_factory" onClick={(e)=>handleRadio('S')}/>
                                            <label htmlFor="songdo_factory">{t("report_reporting_1_4")}</label>
                                        </div>
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="osong_factory" onClick={(e)=>handleRadio('O')}/>
                                            <label htmlFor="osong_factory">{t("report_reporting_1_5")}</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="half">
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_6")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="targetName" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_7")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="targetDept" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 제보대상 --> */}

            <div className="anonymous_2">
                <h4>
                    <span>02</span>
                    {t("report_reporting_1_8")}
                </h4>
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="busan_factory">{t("report_reporting_1_9")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="wbSubject" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_10")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="textareaBox">
                                        <textarea name="wbContents" id="" onChange={handleChange}></textarea>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_11")}</label>
                                    </div>
                                </th>
                                <td className="paddingBox">
                                    <div className="fileWrap">
                                        <div className="fileBox">
                                            <input type="file" id="select_file" name="wbFile" onChange={handleChange}/>
                                            <label htmlFor="select_file">Select a file</label>
                                        </div>
                                        
                                        {fileName &&
                                        <div className="fileNameBox">
                                            <span>{fileName}</span>
                                            <button onClick={()=>handleDelFile()}></button>
                                        </div>
                                        }
                                        {/* <!-- 선택된 파일이 있을 때만 활성화 --> */}
                                    </div>
                                    {/* <div className="fileBox">
                                        <input type="file" name="wbFile" id="" onChange={handleChange}/>
                                    </div> */}
                                    <p>※ {t("report_reporting_1_12")}</p>
                                    <p>※ {t("report_reporting_1_13")}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 제보내용 --> */}

            <div className="btnBox">
                <button className="reportBtn" onClick={handleSubmit}>{lang==='Kr'?'제보하기':'Submit'}</button>
            </div>
        </div>
        </>
    )
}