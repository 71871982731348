import React from "react";
import { useTranslation } from "react-i18next";

export const ResearchSampleProduction = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_5 active">
            <h4>{t("cdo_tab_5")}</h4>
            <p>
                {t("cdo_sample_title_1")}
            </p>


            <div className="grayWrap">
                <div className="grayBG">
                    <div>
                        <img src="/assets/images/mammalian.png" alt="Mammalian, 50L SUS(Capacity 25~50 L) (Thermo Fisher Scientific)" />
                    </div>
                    <span>
                        Mammalian, 50L STS(Capacity 25~50 L)<br />
                        (Thermo Fisher Scientific)
                    </span>
                </div>
                <div className="grayBG">
                    <div>
                        <img src="/assets/images/microbial.png" alt="Microbial, 50L STS(Capacity 30~50 L) (Bioengineering)" />
                    </div>
                    <span>
                        Microbial, 50L STS(Capacity 30~50 L)<br />
                        (BioENGINEERING)
                    </span>
                </div>
                <div className="grayBG">
                    <div>
                        <img src="/assets/images/AKTA_Pilot.png" alt="AKTA Pilot (Index 70 ~ BPG 140 scale) (Cytiva)" />
                    </div>
                    <span>
                        AKTA Pilot (Index 70 ~ BPG 140 scale)<br />
                        (Cytiva)
                    </span>
                </div>
            </div>

            <ul className="checkList">
                <li>{t("cdo_sample_list_1")}</li>
                <li>{t("cdo_sample_list_2")}</li>
                <li>{t("cdo_sample_list_3")}</li>
                <li>{t("cdo_sample_list_4")}</li>
            </ul>

            <div className="listWrap">
                <h5>Service Offerings</h5>
                <ul className="listBox">
                    <li>
                        <div>
                            <img src="/assets/images/developer_development1.png" alt="Services offerings" />
                        </div>
                        <div>
                            <span>
                                Up to 50 L<br />
                                cultivation/fermentation
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/developer_development2.png" alt="Services offerings" />
                        </div>
                        <div>
                            <span>
                                Lab to pilot scale<br />
                                purification
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/developer_development3.png" alt="Services offerings" />
                        </div>
                        <div>
                            <span>
                                Quality test for<br />
                                non-GMP material
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/developer_development4.png" alt="Services offerings" />
                        </div>
                        <div>
                            <span>
                                Tech transfer to<br />
                                GMP site
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
            {/* <div>
                <div className="left">
                    <table>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Capacity</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th rowSpan={6}>Mammalian</th>
                                <td>15mL</td>
                            </tr>
                            <tr>
                                <td>1L</td>
                            </tr>
                            <tr>
                                <td>2L</td>
                            </tr>
                            <tr>
                                <td>3.7L</td>
                            </tr>
                            <tr>
                                <td>5L</td>
                            </tr>
                            <tr>
                                <td>50L</td>
                            </tr>
                            <tr>
                                <th rowSpan={3}>Microbial</th>
                                <td>250mL</td>
                            </tr>
                            <tr>
                                <td>5L</td>
                            </tr>
                            <tr>
                                <td>50L</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="right">
                    <div>
                        <img src="/assets/images/mammalian.png" alt="Mammalian"/>
                        <span>Mammalian, 50L SUS(Capacity 25~50L) (Thermo Fisher Scientific)</span>
                    </div>

                    <div>
                        <img src="/assets/images/microbial.png" alt="Microbial"/>
                        <span>Microbial, 50L STS(Capacity 30~50L) (Bioengineering)</span>
                    </div>
                </div>
            </div> */}
        </div>
        </>
    )
}