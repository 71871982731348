import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Paginate } from "../../../../../components/paginate/paginate_admin";
import { useGetCommonCodeListMutation } from "../../../../../store/admin/adminCommonCodeApiSlice";
import { useGetProductMutation, useGetProductPermissionHistoryMutation } from "../../../../../store/admin/adminContentsApiSlice";
import { useGetInquiryMutation, useGetWhistleListMutation } from "../../../../../store/admin/adminReportingApiSlice";
import { ReportIt } from "../../../../user/subPage/ethicalManagement/reportingAndprocedure/reportIt";
import { ReportList } from "./list-type/list-report";
import { ListFilter } from "./list-element/list-filter";
import { ListHeader } from "./list-element/list-header";
import { CommonCodeList } from "./list-type/list-commonCode";
import { ObesityMedicine } from "./list-type/list-obesityMedicine";
import { PermitChange } from "./list-type/list-permitChange";
import { Product } from "./list-type/list-product";
import { InquiryList } from "./list-type/list-inquiry";
import { useGetFinanceSettlementListMutation } from "../../../../../store/admin/adminInvestApiSlice";
import { InvestNoticeList } from "./list-type/list-investNotice";
import { PaginateUser } from "../../../../../components/paginate/paginate_user";
import { PromotionList } from "./list-type/list-promotion";
import { useGetPromotionListMutation } from "../../../../../store/admin/adminPromotionApiSlice";
import { ListFilterReport } from "./list-element/list-filter-report";

export const List = () => {
    const {type} = useParams()
    console.log(type)
    const groupCode = sessionStorage.getItem('GroupCode') || ''
    const navigate = useNavigate()
    const location = useLocation()
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;
    const searchParam = new URLSearchParams(location.search)
    const page = searchParam.get('page') || 1;
    const keyword = searchParam.get('keyword') || '';
    const sort = searchParam.get('sortOrder')||'desc';
    const column = searchParam.get('sortColumn')||'date';
    const workplace = searchParam.get('workplace')||'';
    const blowType = searchParam.get('whistleblowType')||'';
    const blowStatus = searchParam.get('blowStatus') || ''
    const startDate = searchParam.get('whistleBlowFromMdate')||'2023-01-01';
    const endDate = searchParam.get('whistleBlowToMdate')||currentDate;
    const [getCommonCodeListApi] = useGetCommonCodeListMutation()
    const [getInquiryApi] = useGetInquiryMutation()
    const [getWhistleApi] = useGetWhistleListMutation()
    const [getProductApi] = useGetProductMutation()
    const [getProductPermissionHistoryApi] = useGetProductPermissionHistoryMutation()
    const [getFinanceSettlementListApi] = useGetFinanceSettlementListMutation()
    const [getPromotionListApi] = useGetPromotionListMutation()
    const [classname, setClassname] = useState<string>('')
    const [data, setData] = useState<any>([])
    const [param, setParam] = useState<any>({
        user : 'A', userlang:'KR', permissionClass: type, codeId:'EC', page : page, size : 10, sortColumn: column, sortOrder : sort, keyword : keyword,
        workplace:workplace, whistleblowType : blowType, wbStatus : blowStatus, whistleBlowFromMdate : startDate, whistleBlowToMdate : endDate,
        groupCode : groupCode
    })
    const [totalCount, setTotalCount] = useState<number>(0)
    const getList = async() => {
        if(type==='product'){const result : any = await getProductApi(param);  if(result){setData(result.data.List); setTotalCount(result.data.totalCnt)}
        }else if(type === 'report'){ const result : any = await getWhistleApi(param); if(result){setData(result.data.List); setTotalCount(result.data.totalCnt)}
        }else if(type === 'inquiry'){ const result : any = await getInquiryApi(param); if(result){setData(result.data.list); setTotalCount(result.data.total_cnt)}
        }else if(type === 'commoncode'){ const result : any = await getCommonCodeListApi(param); if(result){setData(result.data.List); setTotalCount(result.data.totalCnt)}
        }else if(type === 'notice'){ const result : any = await getFinanceSettlementListApi(param); if(result){setData(result.data.List); setTotalCount(result.data.totalCnt)}
        }else if(type === 'promotion'){ const result : any = await getPromotionListApi(param); if(result){setData(result.data.list); setTotalCount(result.data.total_cnt)}}
        else {const result:any = await getProductPermissionHistoryApi(param); if(result){setData(result.data.List); setTotalCount(result.data.totalCnt)}}
    }
    const handlePage = (pageNumber : number) => {
        if(type==='report'){navigate(`${location.pathname}?workplace=${workplace}&whistleblowType=${blowType}&blowStatus=${blowStatus}&whistleBlowFromMdate=${startDate}&whistleBlowToMdate=${endDate}&page=${pageNumber}&size=10&sortColumn=${column}&sortOrder=${sort}&keyword=${keyword}`)}
        else{navigate(`${location.pathname}?page=${pageNumber}&size=10&keyword=${keyword}&sortOrder=${sort}&sortColumn=${column}`)}
    }
    const handleSort = (order:string,column:string) => {
        if(type==='report'){navigate(`${location.pathname}?workplace=${workplace}&whistleblowType=${blowType}&blowStatus=${blowStatus}&whistleBlowFromMdate=${startDate}&whistleBlowToMdate=${endDate}&page=${page}&size=10&sortColumn=${column}&sortOrder=${order}&keyword=${keyword}`)}
        else{navigate(`${location.pathname}?page=${page}&size=10&keyword=${keyword}&sortOrder=${order}&sortColumn=${column}`)}
    }
    useEffect(()=>{setParam((prev:any)=>({...prev, page : page, keyword : keyword, sortOrder: sort, sortColumn: column, workplace:workplace, whistleblowType:blowType, wbStatus : blowStatus, whistleBlowFromMdate:startDate, whistleBlowToMdate:endDate}))},
    [page, keyword, sort, column, workplace, blowType, blowStatus, startDate, endDate])
    useEffect(()=>{getList()}, 
    [param.page, param.keyword, param.sortOrder, param.sortColumn, param.workplace, param.whistleblowType, param.wbStatus, param.whistleBlowFromMdate, param.whistleBlowToMdate])
    useLayoutEffect(()=>{
        switch(type){
            case 'commoncode' : case 'P': case 'O': case 'notice': case 'promotion' : setClassname('productAndComponent'); break;
            case 'product' : setClassname('catalogue'); break;
            case 'report' : case 'inquiry' : setClassname('marineNews'); break;
        }
    }, [location])
    return(
        <>
        <div className={`contentBox ${classname}`}>
            <h3>ADMIN</h3>
            
            <ListHeader count={totalCount}/>

            {type === 'report' ? 
            <ListFilterReport/>
            : ''
            }
            
            <table className={`table_hover thumb_table thumbType_a`}>
                <ListFilter onSort={handleSort}/>
                {type === 'commoncode' ? <CommonCodeList data={data} getList={getList}/> : ''}
                {type === 'product' ? <Product data={data} getList={getList}/> : ''}
                {type === 'P' ? <PermitChange data={data} getList={getList}/> : ''}
                {type === 'O' ? <ObesityMedicine data={data} getList={getList}/> : ''}
                {type === 'report' ? <ReportList data={data} getList={getList}/> : ''}
                {type === 'inquiry' ? <InquiryList data={data}/> : ''}
                {type === 'notice' ? <InvestNoticeList data={data} getList={getList}/> : ''}
                {type === 'promotion' ? <PromotionList data={data} getList={getList}/> : ''}
            </table>
            
            <Paginate page={page} totalCount={totalCount} size={10} setPage={handlePage}/>
            
        </div>
        </>
    )
}