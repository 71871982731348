import React, { useEffect, useState } from "react";
import { RegistHeader } from "../regist-header/regist-header";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { SingleContentsBox } from "../../../../../../components/contents/regist/singleContentsBox";
import { SingleAttachedBox } from "../../../../../../components/contents/regist/singleAttachedPDFBox";
import { SingleDatePickerBox } from "../../../../../../components/contents/regist/singleDatePickerBox";
import { useGetPromotionDetailMutation, useSetPromotionMutation, useUpdPromotionMutation } from "../../../../../../store/admin/adminPromotionApiSlice";
import { useNavigate, useParams } from "react-router-dom";

export const RegistPromotion = () => {
    const navigate = useNavigate()
    const {id} = useParams()
    const [setPromotion] = useSetPromotionMutation()
    const [getPromotionDetail] = useGetPromotionDetailMutation()
    const [updPromotion] = useUpdPromotionMutation()
    const [data, setData] = useState<any>({
        subjectKr : '', subjectEn : '', contentKr : '', contentEn : '', date : '',
    })
    const getDetail = async() => {
        const result : any = await getPromotionDetail({id : id})
        if(result.data.result){
            const res = result.data.list[0]
            setData((prev:any)=>({...prev, subjectKr : res.promSubjectKr, subjectEn : res.promSubjectEn, contentKr : res.promContentsKr,
            contentEn : res.promContentsEn, date : res.promMdate}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(id){formData.append('promId', id)}
        formData.append('promSubjectKr', data?.subjectKr)
        formData.append('promSubjectEn', data?.subjectEn)
        formData.append('promContentsKr', data?.contentKr)
        formData.append('promContentsEn', data?.contentEn)
        formData.append('promMdate', data?.date)
        if(id){
            const result : any = await updPromotion(formData)
            if(result.data.result === true){
                alert('수정이 완료되었습니다.'); navigate(-1)
            }
        }else{
            const result : any = await setPromotion(formData)
            if(result.data.result === true){
                alert("등록이 완료되었습니다."); navigate('/dotsAdmin/promotion?page=1&size=10&keyword=&sortOrder=desc&sortColumn=date')
            }
        }
    }
    useEffect(()=>{
        if(id) getDetail()
        else return;
    }, [])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'홍보자료 등록'} backUrl={'/dotsAdmin/promotion'} onSave={handleSave}/>

            <table>
                <tbody>
                    <MultiInputBox title={'제목'} name={'subject'} krValue={data?.subjectKr} enValue={data?.subjectEn} setData={setData}/>
                    <SingleContentsBox title={'국문 본문'} value={data?.contentKr} name={'contentKr'} setData={setData}/>
                    <SingleContentsBox title={'영문 본문'} value={data?.contentEn} name={'contentEn'} setData={setData}/>
                    {/* <SingleAttachedBox title={'국문첨부파일'} name={'attachedFileKr'} setData={setData} detail={fileData.attachedFileName1} url={fileData.attachedFile1Url}/>
                    <SingleAttachedBox title={'영문첨부파일'} name={'attachedFileEn'} setData={setData} detail={fileData.attachedFileName2} url={fileData.attachedFile2Url}/> */}
                    <SingleDatePickerBox title={'등록일자'} name={'date'} value={data?.date} setData={setData}/>
                </tbody>
            </table>
        </div>
        </>
    )
}