import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { Paginate } from "../../../../../../components/paginate/paginate_admin";
import { useDelCommonCodeMutation, useGetCommonCodeListMutation } from "../../../../../../store/admin/adminCommonCodeApiSlice";
import { ListFilter } from "../list-element/list-filter";
import { ListHeader } from "../list-element/list-header";
export interface Props {data: any;getList:Function}
export const CommonCodeList:React.FC<Props> =({data,getList})=> {
    const navigate = useNavigate()
    const [delCommonCodeApi] = useDelCommonCodeMutation()
    const handleDelCommonCode = async(id:string) => {
        const confirm = window.confirm("해당 공용코드를 삭제하시겠습니까?")
        if(confirm){
            const result : any = await delCommonCodeApi({id : id})
            if(result){getList()}
        }
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
                <tr className="active" key={list?.idx}>
                    
                    <td onClick={()=>navigate(`/dotsAdmin/commoncode/${list?.idx}`)}>{list?.codeNameKr}</td>
                    
                    <td onClick={()=>navigate(`/dotsAdmin/commoncode/${list?.idx}`)}>{list?.codeMdate}</td>

                    <td><button onClick={()=>handleDelCommonCode(list?.idx)}>삭제</button></td>

                </tr>
            ))}
        </tbody>
        </>
    )
} 