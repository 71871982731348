import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetProductPermissionHistoryDetailMutation } from "../../../../../store/admin/adminContentsApiSlice";
import { useUpdProductPermissionViewCntMutation } from "../../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../../util/cookie/cookies";

export const PermitChangeView = () => {
    const {id} = useParams()
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const [getProductPermissionHistoryDetailApi, {data, isSuccess, isError}] = useGetProductPermissionHistoryDetailMutation()
    const handleTrans = () => {
        if(path[3] === 'permitChange'){
            navigate('/syntheticMedicine/productAnnouncement/permitChange')
        } else {
            navigate('/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine')
        }
    }
    const handleDownLoad = (url: string) => {
        const fileUrl: any = url;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    useEffect(()=>{
        getProductPermissionHistoryDetailApi({idx: id})
    }, [id])
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    {path[3] === 'permitChange' ? <span>허가 변경사항</span> : ''}
                    {path[3] === 'obesityTreatmentMedicine' ? <span>비만치료 약물</span> : ''}
                </h3>
                
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>제목</th>
                                <td colSpan={3}>
                                    <p><strong>{lang==='Kr'?data?.List[0]?.permissionSubjectKr:data?.List[0]?.permissionSubjectEn}</strong></p>
                                </td>
                            </tr>
                            <tr className="half">
                                <th>작성일</th>
                                <td>{data?.List[0]?.permissionMdate}</td>
                                <td className="th">조회수</td>
                                <td>{parseInt(data?.List[0]?.permissionViewCnt)}</td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colSpan={3}>
                                    <div>
                                        <div dangerouslySetInnerHTML={{__html: lang === 'Kr' ? data?.List[0].permissionContentsKr : data?.List[0].permissionContentsEn}}></div>
                                    </div>
                                </td>
                            </tr>
                            {lang === 'Kr'&&data?.List[0].PermissionAttachedKrFilename===''? 
                            '':
                            <tr>
                                <th>첨부파일</th>
                                <td colSpan={3}>
                                    <a className="download" onClick={()=>handleDownLoad(lang === 'Kr' ? data?.List[0].PermissionAttachedKrFile : data?.List[0].PermissionAttachedEnFile)}>
                                        {lang === 'Kr' ? data?.List[0].PermissionAttachedKrFilename : data?.List[0].PermissionAttachedEnFilename}
                                    </a>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>

                <div className="btnBox">
                    <button onClick={handleTrans}>목록</button>
                </div>
            </div>

        </div>
        </>
    )
}