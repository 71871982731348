import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const SearchBox = () => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const navigate = useNavigate()
    const searchParam = new URLSearchParams(location.search)
    const keyword = searchParam.get('keyword') || ''
    const searchType : any = searchParam.get('type') || 'T'
    const searchRef = useRef<any>(null)
    const [type, setType] = useState<string>(searchType)
    const handleSelectType = (e:any) => {
        setType(e.target.value)
    }
    const handleSearch = () => {navigate(`/syntheticMedicine/productAnnouncement/${path[3]}?page=1&keyword=${searchRef.current.value}&type=${type}`)}
    const handleEnter = (e:any) => {if(e.key==='Enter'){handleSearch()}}
    return(
        <>
        <div className="searchWrap">
            <select value={type} name="" id="" onChange={handleSelectType}>
                <option value="T">제목</option>
                <option value="C">내용</option>
            </select>
            
            <div className="searchBox">
                <input type="text" ref={searchRef} defaultValue={keyword} placeholder={`찾으시는 ${type==='T'?'제목':'내용'}을 입력해 주세요`} onKeyDown={handleEnter} required/>
                <div className="search_btnBox">
                    <button type="button" className="search_btn" onClick={handleSearch}></button>
                </div>
            </div>
        </div>
        </>
    )
}