import { combineReducers, configureStore  } from '@reduxjs/toolkit'
import { setupListeners } from "@reduxjs/toolkit/dist/query/react";
import { adminCommonCodeApiSlice } from './admin/adminCommonCodeApiSlice';
import { adminContentsApiSlice } from './admin/adminContentsApiSlice';
import { adminInvestApiSlice } from './admin/adminInvestApiSlice';
import { adminReportingApiSlice } from './admin/adminReportingApiSlice';
import { adminStratorsApiSlice } from './admin/adminstrators';
import { contentsApiSlice } from './user/contentsApiSlice';
import loginAdminReducer  from './admin/adminInfoSlice';
import { adminPromotionApiSlice } from './admin/adminPromotionApiSlice';

const reducer = combineReducers({})

export const store = configureStore({
  reducer:  {
    loginAdmin : loginAdminReducer,
    [adminInvestApiSlice.reducerPath] : adminInvestApiSlice.reducer,
    [adminCommonCodeApiSlice.reducerPath] : adminCommonCodeApiSlice.reducer,
    [adminReportingApiSlice.reducerPath] : adminReportingApiSlice.reducer,
    [adminContentsApiSlice.reducerPath] : adminContentsApiSlice.reducer,
    [adminStratorsApiSlice.reducerPath] : adminStratorsApiSlice.reducer,
    [adminPromotionApiSlice.reducerPath] : adminPromotionApiSlice.reducer,
    [contentsApiSlice.reducerPath] : contentsApiSlice.reducer
  },
  
  middleware: getMiddleware => getMiddleware().concat(adminContentsApiSlice.middleware, adminStratorsApiSlice.middleware, contentsApiSlice.middleware, adminCommonCodeApiSlice.middleware,
    adminReportingApiSlice.middleware, adminInvestApiSlice.middleware, adminPromotionApiSlice.middleware),
  devTools: process.env.NODE_ENV === 'development'
});

setupListeners(store.dispatch);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
