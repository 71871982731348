import { createSlice, PayloadAction } from "@reduxjs/toolkit"
interface loginAdminState {
    adminInfo : loginAdminInfo
}
export interface loginAdminInfo {
    adminId : string, account : string, name : string, groupId : string
}
const initAdminInfo : loginAdminInfo = {
    adminId : '',
    account : '',
    name : '',
    groupId : ''
}

const initialState : loginAdminState = {
    adminInfo : initAdminInfo
}

export const loginAdminReducer = createSlice({
    name : 'loginAdmin',
    initialState,
    reducers : {
        setAdminInfo : (state, action: PayloadAction<loginAdminInfo>) => {
            state.adminInfo = action.payload;
        }
    },
});

export const {setAdminInfo} = loginAdminReducer.actions;
export default loginAdminReducer.reducer;