import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { ReportStatusSelectBox } from "../../../../../../components/contents/regist/report_status_selectBox";
import { SingleAttachedBox } from "../../../../../../components/contents/regist/singleAttachedPDFBox";
import { SingleInputBox } from "../../../../../../components/contents/regist/singleInputBox";
import { SingleTextAreaBox } from "../../../../../../components/contents/regist/singleTextAreaBox";
import { useDelWhistleMutation, useGetWhistleDetailMutation, useSetWhistleblowCommentMutation } from "../../../../../../store/admin/adminReportingApiSlice";
import { RegistHeader } from "../regist-header/regist-header";

export const ReportView = () => {
    const {id} : any = useParams()
    const [setWhistleblowComment] = useSetWhistleblowCommentMutation()
    const [delWhistleApi] = useDelWhistleMutation()
    const navigate = useNavigate()
    const [getWhistleDetailApi] = useGetWhistleDetailMutation()
    const [data, setData] = useState<any>([])
    const [commentList, setCommentList] = useState<any>([])
    const [isActive , setIsActive] = useState<boolean>(false)
    const [adminName, setAdminName] = useState<string>('')
    const [comment, setComment] = useState<string>('')
    const [isComment, setIsComment] = useState<boolean>(false)
    const getDetail = async() => {
        const result : any = await getWhistleDetailApi({id : id})
        if(result){
            setData(result.data.List[0]); setCommentList(result.data.processList)
        }
    }
    // 관리자 의견 남기기
    const handleComment = async(type :string) => {
        if(type === 'close') {setIsActive(false); return;}
        else{
            if(!adminName) {alert('관리자명을 기입해 주세요.'); return}
            if(!comment) {alert('처리 내용을 기입해 주세요.'); return}
            const formData : any = new FormData()
            formData.append('wbId', id)
            formData.append('wbStatus', data?.wbReplyStatus)
            formData.append('wbManagerName', adminName)
            formData.append('wbProcessContents', comment)
            const res : any = await setWhistleblowComment(formData)
            if(res.data.result === true) {
                setIsComment(true);
                setIsActive(false); getDetail()
            }
        }
    }
    // 제보글 삭제
    const handleDelReport = async() => {
        const confirm = window.confirm('해당 제보글을 삭제하시겠습니까?')
        if(confirm){
           const formData = new FormData()
           formData.append('ID', id)
           const res : any = await delWhistleApi(formData)
           if(res.data.result === true) {
                navigate(-1)
           }
        }else{
            return;
        }
    }
    useEffect(()=>{
        getDetail()
    }, [])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader 
                title={'제보 상세보기'} 
                backUrl={'/dotsAdmin/reporting/report'} 
                onSave={''}
            />

            <table>
                <tbody>
                    <ReportStatusSelectBox refetch={getDetail} status={data?.wbReplyStatus} setActive={setIsActive}/>
                    {data?.wbType === 'R' ? <SingleInputBox title={'제보유형'} name={'codeNameKr'} value={'실명'} setData={setData}/> : ''}
                    {data?.wbType === 'A' ? <SingleInputBox title={'제보유형'} name={'codeNameKr'} value={'익명'} setData={setData}/> : ''}
                    <SingleInputBox title={'등록일자'} name={'codeNameKr'} value={data?.wbMdate} setData={setData}/>
                    <SingleInputBox title={'신고자명'} name={'codeNameKr'} value={data?.wbName} setData={setData}/>
                    <MultiInputBox title={'이메일&전화'} name={''} krValue={data?.wbEmail} enValue={data?.wbPhone} setData={setData}/>
                    {data?.wbWorkplace === 'O' ? <SingleInputBox title={'제보사업장'} name={''} value={'오송공장'} setData={setData}/> : ''}
                    {data?.wbWorkplace === 'B' ? <SingleInputBox title={'제보사업장'} name={''} value={'부산공장'} setData={setData}/> : ''}
                    {data?.wbWorkplace === 'S' ? <SingleInputBox title={'제보사업장'} name={''} value={'송도공장'} setData={setData}/> : ''}
                    <MultiInputBox title={'신고대상&부서'} name={''} krValue={data?.wbTargetName} enValue={data?.wbTargetDept} setData={setData}/>
                    <SingleInputBox title={'신고제목'} name={''} value={data?.wbSubject} setData={setData}/>
                    <SingleTextAreaBox title={'신고내용'} value={data?.wbContents}/>
                    <SingleAttachedBox title={'제보파일'} name={''} setData={setData} detail={data?.wbPdsFilename} url={`https://bi-nex.com${data?.wbPdsFile}`}/>
                    <tr>
                        <th>처리내용</th>
                        <td>
                            <ul className="reportDetail">
                                {commentList?.map((comment : any) => (
                                    <li>
                                        <span>
                                            <span>
                                            {comment?.wbStatus === 'W' ? '접수대기' : ''}
                                            {comment?.wbStatus === 'R' ? '접수' : ''}
                                            {comment?.wbStatus === 'I' ? '조사중' : ''}
                                            {comment?.wbStatus === 'C' ? '완료' : ''}
                                            {comment?.wbStatus === 'B' ? '반려' : ''}
                                            </span> 
                                            ({comment?.wbManagerName}, {comment?.updateDate})
                                            </span>
                                        <span>: {comment?.wbProcessContents}</span>
                                    </li>
                                ))}
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div className="btnBox">
                <button className="deleteBtn" onClick={()=>handleDelReport()}>삭제</button>
                <button className="listBtn" onClick={()=>navigate(-1)}>목록으로</button>
            </div>
        </div>


        {/* -------------------------popUp------------------------ */}
        {isActive && 
        <div className="popUpBox active">
            <div className="admin_opinion">
                <div>
                    <h3>관리자 의견</h3>
                </div>
                <div className="managerBox">관리자 명 <input type="text" onChange={(e)=>setAdminName(e.target.value)} placeholder="관리자 이름을 입력해 주세요."></input></div>
                <textarea name="" onChange={(e)=>setComment(e.target.value)} placeholder="처리 내용에 대해 간단한 코멘트를 남겨주세요."></textarea>
                <div className="btnBox right">
                    <button className="grayBtn" onClick={()=>handleComment('close')}>닫기</button>
                    <button className="blueBtn" onClick={()=>handleComment('commit')}>저장</button>
                </div>
            </div>
        </div>
        }
        </>
    )
}