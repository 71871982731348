import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnalyticalDevelop } from "./sub/analyticalDevelop";
import { BioAnalysisSupport } from "./sub/bioAnalysisSupport";
import { CellLine } from "./sub/cellLine";
import { ProcessDevelop } from "./sub/processDevelop";
import { ResearchSampleProduction } from "./sub/reseachSampleProduct";


export const CDOS = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const tab = searchParam.get('tab') || 1;
    const {thirdUrl} : any = useParams()
    const handleTrans = (url : string) => {
        navigate(`/bioMedicine/cdmoservice?tab=${url}`)
    }
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("cdo_title")}</h3>
                <p><strong>{t("cdo_sub_title")}</strong></p>
                <p>
                    {t("cdo_sub_title_cotent_1")}<br></br>
                    <span>{t("cdo_sub_title_cotent_2")}</span>
                </p>

                <div className="tabBtnWrap">
                    <div className="tabBtnBox">
                        <div>
                            <div onClick={()=>handleTrans('1')}>
                                <button className={tab === '1'?'active':''}>{t("cdo_tab_1")}</button>
                            </div>
                            <div onClick={()=>handleTrans('2')}>
                                <button className={tab === '2'?'active':''}>{t("cdo_tab_2")}</button>
                            </div>
                            <div onClick={()=>handleTrans('3')}>
                                <button className={tab === '3'?'active':''}>{t("cdo_tab_3")}</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabBtnBox">
                        <div>
                            <div onClick={()=>handleTrans('5')}>
                                <button className={tab === '5'?'active':''}>{t("cdo_tab_5")}</button>
                            </div>
                            <div onClick={()=>handleTrans('4')}>
                                <button className={tab === '4'?'active':''}>
                                    {t("cdo_tab_4_1")}<br></br>
                                    {t("cdo_tab_4_2")}
                                </button>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    {/* 여기부터 시작 */}
                    {tab === '1' ? <CellLine/> : ''}
                    {tab === '2' ? <ProcessDevelop/> : ''}
                    {tab === '3' ? <AnalyticalDevelop/> : ''}
                    {tab === '4' ? <BioAnalysisSupport/> : ''}
                    {tab === '5' ? <ResearchSampleProduction/> : ''}
                </div>

            </div>



            <div className={location.pathname === '/bioMedicine/cdmoservice/bioAnalysisCenter' ? `analysis_center active` : 'analysis_center'}>
                <div>
                    <img src="/assets/images/ifez_logo.png" alt="분석센터 로고"/>
                    <button>{t("cdo_center_trans")}</button>
                </div>
            </div>
            {/* <!-- content_4에 active가 붙었을 때 여기도 active 클래스 추가 --> */}


        </div>
        </>
    )
}