import React from "react";

export const LocationData = [
    {main_kr:'서울지점', main_en : 'Seoul Branch', address_kr : '서울특별시 영등포구 양평로21길 26 선유도역1차 IS비즈타워 1405호', address_en : 'IS Biz Tower No. 1405, Seonyudo Station, 26, Yangpyeong-ro 21-gil, Yeongdeungpo-gu, Seoul, Republic of Korea', tel: '02-2631-8334', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.8047841937337!2d126.88928867645933!3d37.536098825871804!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9f5494f05def%3A0x6ad2acb5e3247b1b!2z7ISg7Jyg64-EIOyVhOydtOyXkOyKpOu5hOymiO2DgOybjA!5e0!3m2!1sko!2skr!4v1697675253270!5m2!1sko!2skr'},
    {main_kr:'충청지점', main_en : 'Chungcheong Branch', address_kr : '대전광역시 서구 괴정로 125 대림약품 2층 (주)바이넥스', address_en : 'Binex Co., Ltd. on the 2nd floor of Daelim Pharmaceutical Co., Ltd., 125, Goejeong-ro, Seo-gu, Daejeon', tel: '042-533-4965', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3213.9798416531457!2d127.38396407641999!3d36.33703839367003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35654956e97a9273%3A0x581543d64d1096e3!2z64yA7KCE6rSR7Jet7IucIOyEnOq1rCDqtLTsoJXroZwgMTI1IDLsuLU!5e0!3m2!1sko!2skr!4v1697676248060!5m2!1sko!2skr'},
    {main_kr:'부산지점', main_en : 'Busan Branch', address_kr : '부산광역시 사하구 다대로 368-3 (주)바이넥스', address_en : 'Binex Co., Ltd. 368-3 Dadae-ro, Saha-gu, Busan', tel: '051-622-1112', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3265.4738852736155!2d128.97333217637956!3d35.069890563313244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3568c2b21ff33e93%3A0x694e429695be7e6e!2z67CU7J2064Sl7Iqk!5e0!3m2!1sko!2skr!4v1697683626831!5m2!1sko!2skr'},
    {main_kr:'경남지점', main_en : 'Gyeongnam Branch', address_kr : '경상남도 창원시 의창구 남산로1번길 20 3층 (주)바이넥스', address_en : 'Binex Co., Ltd. on the 203rd floor, Namsan-ro 1-gil, Uichang-gu, Changwon-si, Gyeongsangnam-do, Republic of Korea', tel: '055-255-4563', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3257.8064138942677!2d128.6100430763856!3d35.261071552939164!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x356f33750bd640b9%3A0x8088dfa242358bc8!2z6rK97IOB64Ko64-EIOywveybkOyLnCDsnZjssL3qtawg64Ko7IKw66GcMeuyiOq4uCAyMCAz7Li1!5e0!3m2!1sko!2skr!4v1697683682429!5m2!1sko!2skr'},
    {main_kr:'전남지점', main_en : 'Jeonnam Branch', address_kr : '광주광역시 북구 중흥로 153-14 6층 (주)바이넥스', address_en : 'Binex Co., Ltd. on the 6th floor of 153-146 Jungheung-ro, Buk-gu, Gwangju', tel: '062-381-0591', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3261.7602056477217!2d126.90858907638241!3d35.16260065828857!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x35718c8aaac08293%3A0xacaf2b551b0503ba!2z6rSR7KO86rSR7Jet7IucIOu2geq1rCDspJHtnaXroZwgMTUzLTE0IDbsuLU!5e0!3m2!1sko!2skr!4v1697683727876!5m2!1sko!2skr'},
    {main_kr:'강원지점', main_en : 'Gangwon Branch', address_kr : '강원특별자치도 원주시 시청로 21-1 중부타워 3층 303호 (주)바이넥스', address_en : 'Binex Co., Ltd., Room 303, 3rd floor of Jungbu Tower, 21-1, City Hall-ro, Wonju-si, Gangwon-do, Republic of Korea', tel: '033-734-3044', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3172.0708617726045!2d127.92076067645272!3d37.34083103703735!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3563757b7e124529%3A0x9bf16955227dee29!2z7KSR67aA7YOA7JuM!5e0!3m2!1sko!2skr!4v1697683764962!5m2!1sko!2skr'},
    {main_kr:'경북지점', main_en : 'Gyeongbuk Branch', address_kr : '대구광역시 수성구 알파시티1로 135 광명테크 3층 ㈜바이넥스', address_en : 'Binex Co., Ltd. on the 3rd floor of Gwangmyeong Tech, 135, Alpha City 1-ro, Suseong-gu, Daegu', tel: '053-525-5887', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3234.579309050199!2d128.68264391670917!3d35.83479612115931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3566099a6e5bd02d%3A0x51e10d7214a42497!2z64yA6rWs6rSR7Jet7IucIOyImOyEseq1rCDslYztjIzsi5zti7Ax66GcIDEzNSAz7Li1!5e0!3m2!1sko!2skr!4v1697683810392!5m2!1sko!2skr'},
    {main_kr:'제주지점', main_en : 'Jeju Branch', address_kr : '제주특별자치도 제주시 화삼로 167 307동 1001호 (주)바이넥스', address_en : 'Binex Co., Ltd. No. 1001, 167307 Hwasam-ro, Jeju-si, Jeju-do, Republic of Korea', tel: '070-7715-7673', location: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3326.3427133095006!2d126.57968708867888!3d33.51847417705565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x350ce2f27addabf5%3A0xb1baffc265d27e1f!2z7KCc7KO87Yq567OE7J6Q7LmY64-EIOygnOyjvOyLnCDtmZTsgrzroZwgMTY3IDEwMDHtmLg!5e0!3m2!1sko!2skr!4v1697683849976!5m2!1sko!2skr'},
]