import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSetWhistleblowMutation } from "../../../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../../../util/cookie/cookies";
export const RealNameReport = ({}) => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const [setWhistleblowApi] = useSetWhistleblowMutation()
    const initData = {wbName : '' , wbEmail_1 : '', wbEmail_2 : '', wbPhone_1 : '', wbPhone_2 : '',wbPhone_3 : '' , 
    targetWorkplace : '' , targetName : '', targetDept : '', wbSubject : '' , wbContents : '', wbFile : '', wbPrivacyAgree : false, wbPrivacyProvideAgree : false}
    const [data, setData] = useState<any>(initData)
    const [fileName, setFileName] = useState<any>(null)
    const handleRadio = (place : string) => {setData((prev:any) => ({...prev, targetWorkplace : place}))}
    const handleChange = (e:any) => {
        const {type, name, value, checked} = e.target;
        if(type==='file'){
            setData((prev:any) => ({...prev, [name] : e.target.files[0]}))
            setFileName(e.target.files[0].name)
        } 
        else if(name === 'wbPrivacyAgree' || name === 'wbPrivacyProvideAgree'){setData((prev:any) => ({...prev, [name] : !prev[name]}))}
        else {
            if(name === 'wbEmail_1' || name==='wbEmail_2'){
                const emailRegex = /^[a-zA-Z0-9@._-]*$/;
                if (!emailRegex.test(value)) return;
            }
            if(name === 'wbPhone_1' ||  name === 'wbPhone_2' || name === 'wbPhone_3'){
                const phoneRegex = /^[0-9]*$/;
                if (!phoneRegex.test(value) || value.length > 4) return;
            }
            setData((prev:any) => ({...prev, [name] : value}))
        }
    }
    const handleChangeEmail = (e:any) => {
        setData((prev:any) => ({...prev, wbEmail_2 : e.target.value}))
    }
    const handleDelFile = () => {
        setData((prev:any) => ({...prev, wbFile : ''}))
        setFileName(null)
    }
    const handleSubmit = async() => {
        if(!data?.wbName || !data?.wbEmail_1 || !data?.wbEmail_2 || !data?.wbPhone_1 || !data?.wbPhone_2 || !data?.wbPhone_3){
            alert("제보자 정보를 모두 기입해주세요."); return;
        }else if(!data?.targetWorkplace || !data?.targetName || !data?.targetDept){
            alert("제보대상 정보를 모두 기입해주세요."); return;
        }else if(!data?.wbSubject || !data?.wbContents){
            alert("제보내용을 모두 기입해주세요."); return;
        }else if(!data?.wbPrivacyAgree || !data?.wbPrivacyProvideAgree){
            alert("개인정보 수집에 동의 필수 사항입니다."); return;
        }else{
            const formData : any = new FormData()
            formData.append('wbType', 'R')
            formData.append('wbName', data?.wbName)
            formData.append('wbEmail', data?.wbEmail_1 + '@' + data?.wbEmail_2)
            formData.append('wbPhone', data?.wbPhone_1 + "-" + data?.wbPhone_2 + "-" + data?.wbPhone_3)
            formData.append('wbPrivacyAgree', data?.wbPrivacyAgree ? 'Y' : 'N')
            formData.append('wbPrivacyProvideAgree', data?.wbPrivacyProvideAgree ? 'Y' : 'N')
            formData.append('wbWorkplace', data?.targetWorkplace)
            formData.append('wbTargetName', data?.targetName)
            formData.append('wbTargetDept', data?.targetDept)
            formData.append('wbSubject', data?.wbSubject)
            formData.append('wbContents', data?.wbContents)
            formData.append('wbFile', data?.wbFile)
            const result : any = await setWhistleblowApi(formData)
            if(result.data.result === true){alert("제보가 완료되었습니다."); window.location.reload()}
        }
    }
    return(
        <>
        <div className="tabContent_1 active">
            <div className="realName_1">
                <h4>
                    <span>01</span>
                    {t("report_reporting_2_1")}
                </h4>
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="user_name">{t("report_reporting_2_2")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="wbName" id="user_name" value={data?.wbName} onChange={handleChange}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="user_mail">{t("report_reporting_2_3")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="flexWrap">
                                        <div className="inputBox">
                                            <input type="text" name="wbEmail_1" id="user_mail" value={data?.wbEmail_1} onChange={handleChange}/>
                                        </div>
                                        <span>@</span>
                                        <div className="inputBox">
                                            <input type="text" name="wbEmail_2" id="user_mail" value={data?.wbEmail_2} onChange={handleChange}/>
                                        </div>
                                        <div className="inputBox">
                                            <select onChange={handleChangeEmail} name="" id="">
                                                <option value="">{t("report_reporting_2_4")}</option>
                                                <option value="bi-nex.com">bi-nex.com</option>
                                                <option value="naver.com">naver.com</option>
                                                <option value="daum.net">daum.net</option>
                                                <option value="hanmail.net">hanmail.net</option>
                                                <option value="nate.com">nate.com</option>
                                                <option value="gmail.com">gmail.com</option>
                                            </select>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="user_phone">{t("report_reporting_2_5")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="flexWrap">
                                        <div className="inputBox phone">
                                            <input type="text" name="wbPhone_1" value={data?.wbPhone_1} id="user_phone" onChange={handleChange}/>
                                        </div>
                                        <span>-</span>
                                        <div className="inputBox phone">
                                            <input type="text" name="wbPhone_2" value={data?.wbPhone_2} id="user_phone" onChange={handleChange}/>
                                        </div>
                                        <span>-</span>
                                        <div className="inputBox phone">
                                            <input type="text" name="wbPhone_3" value={data?.wbPhone_3} id="user_phone" onChange={handleChange}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            {/* <!-- 제보자 정보 --> */}
            
            <div className="realName_2">
                <h4>
                    <span>02</span>
                    {t("report_reporting_1_1")}
                </h4>
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="busan_factory">{t("report_reporting_1_2")}</label>
                                    </div>
                                </th>
                                <td colSpan={3}>
                                    <div className="flexWrap radio">
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="busan_factory" onClick={(e)=>handleRadio('B')}/>
                                            <label htmlFor="busan_factory">{t("report_reporting_1_3")}</label>
                                        </div>
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="songdo_factory" onClick={(e)=>handleRadio('S')}/>
                                            <label htmlFor="songdo_factory">{t("report_reporting_1_4")}</label>
                                        </div>
                                        <div className="radioBox">
                                            <input type="radio" name="targetWorkplace" id="osong_factory" onClick={(e)=>handleRadio('O')}/>
                                            <label htmlFor="osong_factory">{t("report_reporting_1_5")}</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr className="half">
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_6")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="targetName" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_7")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="targetDept" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 제보대상 --> */}

            <div className="realName_3">
                <h4>
                    <span>03</span>
                    {t("report_reporting_1_8")}
                </h4>
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="busan_factory">{t("report_reporting_1_9")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="inputBox">
                                        <input type="text" name="wbSubject" id="" onChange={handleChange}/>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_10")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="textareaBox">
                                        <textarea name="wbContents" id="" onChange={handleChange}></textarea>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <div>
                                        <label htmlFor="">{t("report_reporting_1_11")}</label>
                                    </div>
                                </th>
                                <td>
                                    <div className="fileWrap">
                                        <div className="fileBox">
                                            <input type="file" id="select_file" name="wbFile" onChange={handleChange}/>
                                            <label htmlFor="select_file">Select a file</label>
                                        </div>
                                        
                                        {fileName &&
                                        <div className="fileNameBox">
                                            <span>{fileName}</span>
                                            <button onClick={()=>handleDelFile()}></button>
                                        </div>
                                        }
                                        {/* <!-- 선택된 파일이 있을 때만 활성화 --> */}
                                    </div>

                                    {/* <div className="fileBox">
                                        <input type="file" name="wbFile" id="" onChange={handleChange}/>
                                    </div> */}
                                    <p>※ {t("report_reporting_1_12")}</p>
                                    <p>※ {t("report_reporting_1_13")}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 제보내용 --> */}
            <div className="realName_4">
                <div className="titleBox">
                    <h4>{t("report_reporting_2_6")}</h4>
                    <div className="radioBox">
                        <input type="radio" name="wbPrivacyAgree" id="agree" onClick={handleChange} checked={data?.wbPrivacyAgree}/>
                        <label htmlFor="agree">{t("report_reporting_2_7")}</label>
                    </div>
                </div>
                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("report_reporting_2_8")}</th>
                                <th>{t("report_reporting_2_9")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t("report_reporting_2_10")}</td>
                                <td>
                                    <p>
                                    {t("report_reporting_2_11")}
                                    </p>
                                    <p>{t("report_reporting_2_12")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_13")}</td>
                                <td>
                                    <p>{t("report_reporting_2_14")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_15")}</td>
                                <td>
                                    <p>
                                    {t("report_reporting_2_16")}
                                    </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 개인정보 수집 및 이용 동의 --> */}
            
            <div className="realName_5">
                <div className="titleBox">
                    <h4>{t("report_reporting_2_17")}</h4>
                    <div className="radioBox">
                        <input type="radio" name="wbPrivacyProvideAgree" id="wbPrivacyProvideAgree" onClick={handleChange} checked={data?.wbPrivacyProvideAgree}/>
                        <label htmlFor="wbPrivacyProvideAgree">{t("report_reporting_2_18")}</label>
                    </div>
                </div>
                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>{t("report_reporting_2_19")}</th>
                                <th>{t("report_reporting_2_20")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{t("report_reporting_2_21")}</td>
                                <td>
                                    <p>{t("report_reporting_2_22")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_23")}</td>
                                <td>
                                    <p>{t("report_reporting_2_24")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_25")}</td>
                                <td>
                                    <p>{t("report_reporting_2_26")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_27")}</td>
                                <td>
                                    <p>{t("report_reporting_2_28")}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>{t("report_reporting_2_29")}</td>
                                <td>
                                    <p>{t("report_reporting_2_30")}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <!-- 개인정보 제3자 제공 동의 --> */}

            <div className="btnBox">
                <button className="reportBtn" onClick={handleSubmit}>{lang==='Kr'?'제보하기':'Submit'}</button>
            </div>
        </div>
        </>
    )
}