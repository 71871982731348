import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetFinanceSettlementDetailMutation } from "../../../../store/admin/adminInvestApiSlice";
import { getCookie } from "../../../../util/cookie/cookies";

export const ElectronicNoticeView = () => {
    const {thirdUrl}:any = useParams()
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()
    const [getFinanceSettlementDetailApi] = useGetFinanceSettlementDetailMutation();
    const [data, setData] = useState<any>([])
    const getDetail = async() => {
        const result : any = await getFinanceSettlementDetailApi({id : thirdUrl})
        if(result.data.Result==='true'){setData(result.data.List[0])}
    }
    const handleDownLoad = (url: string) => {
        const fileUrl: any = url;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    useEffect(()=>{getDetail()},[])
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>바이넥스의 <span>사업성과를 공개합니다.</span></h3>
                : 
                <h3>Binex discloses its <span>business performance</span></h3>
                }
                
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>제목</th>
                                <td colSpan={3}>
                                    <p><strong>{lang==='Kr'?data?.fsSubjectKr:data?.fsSubjectEn}</strong></p>
                                </td>
                            </tr>
                            <tr className="half">
                                <th>작성일</th>
                                <td>{data?.fsMdate}</td>
                                <td className="th">조회수</td>
                                <td>{data?.fsViewCnt}</td>
                            </tr>
                            <tr>
                                <th>내용</th>
                                <td colSpan={3}>
                                    <div className="imgBox">
                                        <div dangerouslySetInnerHTML={{__html: lang === 'Kr' ? data?.fsContentsKr : data?.fsContentsEn}}></div>
                                    </div>
                                </td>
                            </tr>
                            {lang==='Kr'&&data?.fsAttached1PdsFilename!==''?
                            <tr>
                                <th>첨부파일</th>
                                <td colSpan={3}>
                                    <a onClick={()=>handleDownLoad(data?.fsAttached1PdsFile)}>
                                        {data?.fsAttached1PdsFilename}
                                    </a>
                                </td>
                            </tr> : ''
                            }
                            {lang === 'En'&&data?.fsAttached2PdsFilename!==''?
                            <tr>
                                <th>첨부파일</th>
                                <td colSpan={3}>
                                    <a onClick={()=>handleDownLoad(data?.fsAttached2PdsFile)}>
                                        {data?.fsAttached2PdsFilename}
                                    </a>
                                </td>
                            </tr> : ''
                            }
                        </tbody>
                    </table>
                </div>

                <div className="btnBox">
                    <button onClick={()=>navigate('/invest/electronicNotice')}>
                        {lang==='Kr'?'목록':'List'}
                    </button>
                </div>
            </div>

        </div>
        </>
    )
}