import React from "react";
import { useLocation } from "react-router-dom";
import QuillEditor from "./quillEditor/quillEditor";
export interface Props {title:string,value:string,name:string;setData:Function}
export const SingleContentsBox:React.FC<Props> = ({title,value,name,setData}) => {
    
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, [name] : e}))
    }
    return(
        <>
        <tr className="lang_separation active">
                <th>{title}</th>
                <td>
                    <QuillEditor value={value} onChange={handleChange}/>
                </td>
            </tr>
        </>
    )
}