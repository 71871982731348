import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../util/cookie/cookies";

export const SiteMap = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()
    return(
        <>
        <div className="siteMap">
            <div className="grayBox">
                <h2>{t("footer_9")}</h2>
            </div>


            <div className="section_1">
				<div className="type_a">
                    <span>{lang==='Kr'?'바이넥스':'Binex'}</span>
                    <div>
                        <div>
                            <span onClick={()=>navigate('/binex/about/overView')}>{lang==='Kr'?'회사소개':'Company'}</span>
                            <ul>
                                <li onClick={()=>navigate('/binex/about/overView')}>{lang==='Kr'?'회사개요':'Overview'}</li>
                                <li onClick={()=>navigate('/binex/about/vision')}>{lang==='Kr'?'비전':'Vision'}</li>
                                <li onClick={()=>navigate('/binex/about/greetings')}>{lang==='Kr'?'인사말':'Greeting'}</li>
                                <li onClick={()=>navigate('/binex/about/businessArea')}>{lang==='Kr'?'사업분야':'Business'}</li>
                            </ul>
                        </div>
                        <div onClick={()=>navigate('/binex/ci')}>
                            <span>{lang==='Kr'?'CI':'CI'}</span>
                        </div>
                        <div onClick={()=>navigate('/binex/history')}>
                            <span>{lang==='Kr'?'연혁':'History'}</span>
                        </div>
                        <div onClick={()=>navigate('/binex/location')}>
                            <span>{lang==='Kr'?'오시는길':'Location'}</span>
                        </div>
                        {lang === 'Kr' ? 
                        <div onClick={()=>navigate('/binex/promotion-center')}>
                            <span>{lang==='Kr'?'홍보센터':''}</span>
                        </div>
                        : ''
                        }
                    </div>
                </div>
                <div className="type_a">
                    <span>{lang==='Kr'?'바이오의약품 CDMO':'Biologics CDMO'}</span>
                    <div>
                        <div onClick={()=>navigate('/bioMedicine/bussinessOverview')}>
                            <span>{lang==='Kr'?'CDMO 사업개요':'CDMO Business'}</span>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/bioMedicine/cdmoservice?tab=1')}>CDO</span>
                            <ul>
                                <li onClick={()=>navigate('/bioMedicine/cdmoservice?tab=1')}>{lang==='Kr'?'세포주 개발':'Cell Line Development'}</li>
                                <li onClick={()=>navigate('/bioMedicine/cdmoservice?tab=2')}>{lang==='Kr'?'공정 개발':'Process Development'}</li>
                                <li onClick={()=>navigate('/bioMedicine/cdmoservice?tab=3')}>{lang==='Kr'?'분석법 개발':'Analytical Method Development'}</li>
                                <li onClick={()=>navigate('/bioMedicine/cdmoservice?tab=5')}>{lang==='Kr'?'연구용 시료 생산':'Clinical Manufacturing'}</li>
                                <li onClick={()=>navigate('/bioMedicine/cdmoservice?tab=4')}>{lang==='Kr'?'단백질 구조 및 특성분석(바이오분석지원센터)':'Bio Analysis Center'}</li>
                            </ul>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/bioMedicine/cdmoproduction?tab=1')}>CMO</span>
                            <ul>
                                <li onClick={()=>navigate('/bioMedicine/cdmoproduction?tab=1')}>{lang==='Kr'?'원제의약품':'Drug Substance'}</li>
                                <li onClick={()=>navigate('/bioMedicine/cdmoproduction?tab=2')}>{lang==='Kr'?'완제의약품':'Drug Product'}</li>
                            </ul>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/bioMedicine/quailty')}>Quailty</span>
                            <ul>
                                <li onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>
                                    {lang==='Kr'? '품질시스템':'Quality System'}
                                </li>
                                <li onClick={()=>navigate(`/bioMedicine/quailty?tab=2`)}>
                                    {lang==='Kr'? '품질관리':'Quality Control'}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                
                <div className="type_a">
                    <span>{lang==='Kr'?'합성의약품':'Chemical'}</span>
                    <div>
                        <div>
                            <span onClick={()=>navigate('/syntheticMedicine/search/productname')}>{lang==='Kr'?'제품검색':'Products'}</span>
                        </div>
                        {lang === 'Kr' ? 
                        <div>
                            <span onClick={()=>navigate('/syntheticMedicine/productAnnouncement/permitChange')}>제품 공지사항</span>
                            <ul>
                                <li onClick={()=>navigate('/syntheticMedicine/productAnnouncement/permitChange')}>허가 변경사항</li>
                                <li onClick={()=>navigate('/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine')}>비만치료 약물</li>
                            </ul>
                        </div> : ''
                        }
                        {lang === 'Kr' ? 
                        <div>
                            <span onClick={()=>navigate('/syntheticMedicine/probiitics/manufacturingFacility')}>Probiotics</span>
                            <ul>
                                <li onClick={()=>navigate('/syntheticMedicine/probiitics/manufacturingFacility')}>제조시설</li>
                                <li onClick={()=>navigate('/syntheticMedicine/probiitics/microbialIdentificationAnalysisService')}>미생물 동정 분석 서비스</li>
                            </ul>
                        </div> : ''
                        }
                        {lang === 'Kr' ? 
                        <div onClick={()=>navigate('/syntheticMedicine/busanFactory')}>
                            <span>제조시설(부산공장)</span>
                        </div> : ''
                        }
                    </div>
                </div>
                <div className="type_a">
                    <span>{lang==='Kr'?'투자정보':'Investment'}</span>
                    <div>
                        {lang === 'Kr' ? 
                        <div onClick={()=>navigate('/invest/electronicDisclosure')}>
                            <span>{lang==='Kr'?'전자공시':'Public Announcement'}</span>
                        </div> : ''
                        }
                        {lang === 'Kr' ? 
                        <div onClick={()=>navigate('/invest/electronicNotice')}>
                            <span>{lang==='Kr'?'전자공고':'Electric Notice'}</span>
                        </div>: ''
                        }
                        <div onClick={()=>navigate('/invest/financialInformation')}>
                            <span>{lang==='Kr'?'재무정보':'Financial Information'}</span>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/invest/stockInformation/stockPrice')}>{lang==='Kr'?'주식정보':'Stock Information'}</span>
                            <ul>
                                <li onClick={()=>navigate('/invest/stockInformation/stockPrice')}>{lang==='Kr'?'주가정보':'Stock Price Information'}</li>
                                <li onClick={()=>navigate('/invest/stockInformation/stockStatus')}>{lang==='Kr'?'주식현황':'Stock Status'}</li>
                                <li onClick={()=>navigate('/invest/stockInformation/general')}>{lang==='Kr'?'일반현황':'General Information'}</li>
                            </ul>
                        </div>
                        <div onClick={()=>navigate('/invest/internalInformationManagement')}>
                            <span>{lang==='Kr'?'내부정보관리규정':'Internal Information Control Regulation'}</span>
                        </div>
                    </div>
                </div>
                <div className="type_a">
                    <span>{lang==='Kr'?'윤리경영':'Ethical Management'}</span>
                    <div>
                        <div>
                            <span onClick={()=>navigate('/ethical/codeOfehicsAndimplement/codeOfethics')}>{lang==='Kr'?'윤리강령 및 실천지침':'Code of Ethics and Pratice Guidelines'}</span>
                            <ul>
                                <li onClick={()=>navigate('/ethical/codeOfehicsAndimplement/codeOfethics')}>{lang==='Kr'?'윤리강령':'Code of Ethics'}</li>
                                <li onClick={()=>navigate('/ethical/codeOfehicsAndimplement/practiceGuideline')}>{lang==='Kr'?'실천지침':'Pratice Guidelines'}</li>
                            </ul>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/ethical/reportingAndprocedure/reportGuide')}>{lang==='Kr'?'제보안내 및 절차':'Recruitment'}</span>
                            <ul>
                                <li onClick={()=>navigate('/ethical/reportingAndprocedure/reportGuide')}>{lang==='Kr'?'제보안내':'Introduction'}</li>
                                <li onClick={()=>navigate('/ethical/reportingAndprocedure/informantProtection')}>{lang==='Kr'?'제보자 보호':'Whistleblower Protection'}</li>
                                <li onClick={()=>navigate('/ethical/reportingAndprocedure/report')}>{lang==='Kr'?'제보하기':'Reporting'}</li>
                                <li onClick={()=>navigate('/ethical/reportingAndprocedure/processingProcedure')}>{lang==='Kr'?'처리절차':'Process'}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="type_a">
                    <span>{lang==='Kr'?'채용정보':'Recruitment'}</span>
                    <div>
                        <div>
                            <span onClick={()=>navigate('/recruitmentInfomation/idealTalent')}>{lang==='Kr'?'인재상':'Ideal Candidate'}</span>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/recruitmentInfomation/benefits')}>{lang==='Kr'?'복리후생':'Benefits'}</span>
                        </div>
                        <div>
                            <span onClick={()=>navigate('/recruitmentInfomation/employment')}>{lang==='Kr'?'채용안내':'Careers'}</span>
                        </div>
                    </div>
                </div>

            </div>


        </div>
        </>
    )
}

export const siteMapData = [
    {}
]