import React from "react";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { useUpdPromotionActiveStatusMutation } from "../../../../../../store/admin/adminPromotionApiSlice";
import { useNavigate } from "react-router-dom";
interface Props {data : any, getList : Function}
export const PromotionList:React.FC<Props> = ({data, getList}) => {
    const navigate = useNavigate()
    const [updPromotionActiveStatus] = useUpdPromotionActiveStatusMutation()
    const handleStatusChange = async(id : any, status : string) => {
        const formData : any = new FormData()
        formData.append('promId', id)
        formData.append('promActiveStatus', status === 'Y' ? 'N' : 'Y')
        const result : any = await updPromotionActiveStatus(formData)
        if(result.data.result===true) getList()
    }
    return(
        <>
        <tbody>
            {data?.map((list:any) => (
                <>
                <tr className="active" key={list?.promId}>
                    
                    <td onClick={()=>navigate(`/dotsAdmin/promotion/${list?.promId}`)}>{list?.promMdate}</td>

                    <td onClick={()=>navigate(`/dotsAdmin/promotion/${list?.promId}`)}>{list?.promSubjectKr}</td>

                    <td>
                        <span className="toggleSwitch">
                            <input type="checkbox" id={list?.promId} className="toggleIpt" onChange={()=>handleStatusChange(list?.promId, list?.promActiveStatus)} checked={list?.promActiveStatus==='Y'?true : false} hidden/> 
                            <label htmlFor={list?.promId} className="toggleSwitch">
                                <span className="toggleButton"></span>
                            </label>
                        </span>
                    </td>

                </tr>
                </>
            ))}
        </tbody> 
        </>
    )
}