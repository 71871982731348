import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {title:string,backUrl:string,onSave : any}
export const RegistHeader:React.FC<Props> = ({title,onSave}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')

    return(
        <>
        <div className="flexWrap">
            <div className="flexBox">
                <h4>
                    {title}
                </h4>
            </div>
            <div>
                <button className="blackBtn" onClick={()=>navigate(-1)}>목록으로</button>
                {path[2] === 'reporting' ? '' : 
                <button className="blueBtn" onClick={()=>onSave()}>저장하기</button>
                }
            </div>
        </div>
        </>
    )
}