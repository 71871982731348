import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUpdProductActiveStatusMutation, useUpdProductPermissionActiveStatusMutation } from "../../store/admin/adminContentsApiSlice";
export interface Props {idx : string; openYn : string; getList : any}
export const CheckBox_Admin:React.FC<Props> = ({openYn, idx, getList}) => {
    const location = useLocation()
    // 제품
    const [updProductActiveStatusApi] = useUpdProductActiveStatusMutation()
    const [updProductPermissionActiveStatusApi] = useUpdProductPermissionActiveStatusMutation()
    const handleChangeActive = async() => {
        const formData : any = new FormData()
        formData.append('idx' , idx)
        if(location.pathname === '/dotsAdmin/products/product'){formData.append('productActiveStatus', openYn === 'Y'?'N':'Y')}
        else {formData.append('permissionActiveStatus', openYn === 'Y'?'N':'Y')}
        if(location.pathname === '/dotsAdmin/products/product'){
            const result : any = await updProductActiveStatusApi(formData)
            if(result.data.result === true){getList()}
        } else {
            const result : any = await updProductPermissionActiveStatusApi(formData)
            if(result.data.result === true){getList()}
        }
    }
    return(
        <>
        <td>
            <span className="toggleSwitch">
                {/* <!-- id값과 for 값은 고유값으로 한쌍씩 같아야 토글애니메이션 작동합니다. --> */}
                <input type="checkbox" id={idx} className="toggleIpt" onChange={handleChangeActive} checked={openYn==='Y'?true : false} hidden/> 
                <label htmlFor={idx} className="toggleSwitch">
                    <span className="toggleButton"></span>
                </label>
            </span>
        </td>
        </>
    )
}