import React, { useEffect, useLayoutEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
export interface Props {address:string; google : any}
export const GoogleMaps:React.FC<Props> = ({address, google}) => {
  const [center, setCenter] = useState({ lat: 0, lng: 0 }); // 초기 중심 위치
  // 주소를 좌표로 변환하는 함수
  const geocodeAddress = () => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results : any, status : any) => {
      if (status === 'OK' && results[0].geometry) {
        const location = results[0].geometry.location;
        setCenter({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error('Geocode was not successful for the following reason: ' + status);
      }
    });
  };

  const myStyles = [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
  ];

  useEffect(()=>{
    if(address){
      geocodeAddress();
    }
  }, [address])
  
  return (
    <GoogleMap
      options={{disableDefaultUI : true, styles : myStyles}}
      mapContainerStyle={{ width: '100%', height: '400px' }}
      center={center}
      zoom={15}
    >
      <Marker
        position={center}
        title={address}
        icon={{
          url: '/assets/images/binex-marker.png',
          scaledSize: new window.google.maps.Size(110, 44),
        }}
      />
    </GoogleMap>
  );
};