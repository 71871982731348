import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { OverVeiw } from "./about/about-overView";
import { Vision } from "./about/about-vision";
import { BusinessArea } from "./about/about-businessArea";
import { Greetings } from "./about/about-greeting";
import { CI } from "./binex-CI";
import { History } from "./binex-history";
import { Location } from "./binex-location";
import { getCookie } from "../../../../util/cookie/cookies";
import { PromotionCenter } from "./binex-promotion";
import { PromotionView } from "./binex-promotion-view";

export const Binex = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const {thirdUrl} = useParams()
    const [state, setState] = useState<{classname:string;title_kr:string;title_en:string}>({classname : '', title_kr : '', title_en : ''})
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/binex/ci' : setState((prev)=>({...prev, classname:'ci',title_kr:'CI', title_en : 'CI'}));break;
            case '/binex/history' : setState((prev)=>({...prev, classname:'history',title_kr:'연혁', title_en : 'History'}));break;
            case '/binex/location' : setState((prev)=>({...prev, classname:'location',title_kr:'오시는 길', title_en : 'Location'}));break;
            case '/binex/about/overView': setState((prev)=>({...prev, classname:'company_overview',title_kr:'회사개요', title_en : 'Overview'})); break;
            case '/binex/about/vision': setState((prev)=>({...prev, classname:'vision',title_kr:'비전', title_en : 'Vision'})); break;
            case '/binex/about/greetings': setState((prev)=>({...prev, classname:'greetings',title_kr:'인사말', title_en : 'Greeting'})); break;
            case '/binex/about/businessArea': setState((prev)=>({...prev, classname:'business_areas',title_kr:'사업분야', title_en : 'Business'})); break;
            case '/binex/promotion-center': setState((prev)=>({...prev, classname:'product_license_changes borad',title_kr:'홍보센터', title_en : 'Promotion Center'})); break;
            case `/binex/promotion-center/${thirdUrl}`: setState((prev)=>({...prev, classname:'product_license_changes_view boardView',title_kr:'홍보센터', title_en : 'Promotion Center'})); break;
        }
    },[location])

    return(
        <>
        {/* 헤더 바로 밑 시작 */}
        <div className={`subPage binex ${state.classname}`}>
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?state.title_kr:state.title_en}</h2>
                </div>
            </div>
            <NavigationBox/>
            {location.pathname === '/binex/ci' ? <CI/> : ''}
            {location.pathname === '/binex/history' ? <History/> : ''}
            {location.pathname === '/binex/location' ? <Location/> : ''}
            {location.pathname === '/binex/about/overView'? <OverVeiw/> :''}
            {location.pathname === '/binex/about/vision'? <Vision/> :''}
            {location.pathname === '/binex/about/greetings'? <Greetings/> :''}
            {location.pathname === '/binex/about/businessArea'? <BusinessArea/> :''}
            {location.pathname === '/binex/promotion-center' ? <PromotionCenter/> : ''}
            {location.pathname === `/binex/promotion-center/${thirdUrl}` ? <PromotionView/> : '' }
        </div>
        </>
    )
}