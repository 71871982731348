import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SingleImageUploadBox } from "../../../../../../components/contents/regist/singleImageUploadBox";
import { SingleInputBox } from "../../../../../../components/contents/regist/singleInputBox";
import { SingleSelectBox } from "../../../../../../components/contents/regist/singleSelectBox";
import { useGetProductDetailMutation, useSetProductMutation, useUpdProductMutation } from "../../../../../../store/admin/adminContentsApiSlice";
import { RegistHeader } from "../regist-header/regist-header";

export const RegistProduct = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const [getProductDetailApi] = useGetProductDetailMutation()
    const [editProductApi] = useUpdProductMutation();
    const [setProductApi] = useSetProductMutation()
    const initData = {productNameKr : '', productNameEn : '', productImageFile : null, productIngredientKr : '', productIngredientEn : '', productEfficacy : 0, productUrl : '', imageUrl : ''}
    const [data, setData] = useState<any>(initData)
    const getProductDetail = async() => {
        const result : any = await getProductDetailApi({idx: id})
        if(result){setData((prev:any) => ({...prev, productNameKr : result.data.List[0].productNameKr, productNameEn : result.data.List[0].productNameEn,
        productIngredientKr : result.data.List[0].productIngrdiendKr, productIngredientEn : result.data.List[0].productIngrdiendEn, imageUrl : result.data.List[0].productImageFile,
        productEfficacy : result.data.List[0].productEfficacy, productUrl : result.data.List[0].productUrl}))}
    }
    console.log(data)
    const handleSave = async() => {
        const formData:any = new FormData()
        if(id){formData.append('idx' , id)}
        formData.append('productRegister', 1)
        formData.append('productNameKr', data?.productNameKr)
        formData.append('productNameEn', data?.productNameEn)
        if(data?.productImageFile){formData.append('productImageFile', data?.productImageFile)}
        formData.append('productIngredientKr', data?.productIngredientKr)
        formData.append('productIngredientEn', data?.productIngredientEn)
        formData.append('productEfficacy', data?.productEfficacy)
        formData.append('productUrl', data?.productUrl)
        if(id){
            const result : any = await editProductApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다."); navigate(-1)}
            else{alert("오류가 발생하였습니다.")}
        }else {
            const result : any = await setProductApi(formData)
            if(result.data.result === true){alert("등록이 완료되었습니다."); navigate('/dotsAdmin/products/product')}
            else {alert("오류가 발생하였습니다.")}
        }
    }
    useEffect(()=>{
        if(id){getProductDetail()}
    }, [id])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'제품 등록'} backUrl={'/dotsAdmin/products/product'} onSave={handleSave}/>

            <table>
                <tbody>
                    <SingleInputBox title={'제품명 (국문)'} name={'productNameKr'} value={data?.productNameKr} setData={setData}/>
                    <SingleInputBox title={'제품명 (영문)'} name={'productNameEn'} value={data?.productNameEn} setData={setData}/>
                    {/* 이미지 */}
                    <SingleImageUploadBox title={'제품 이미지'} name={'productImageFile'} setData={setData} url={data?.imageUrl}/>

                    <SingleInputBox title={'성분·함량 (국문)'} name={'productIngredientKr'} value={data?.productIngredientKr} setData={setData}/>
                    <SingleInputBox title={'성분·함량 (영문)'} name={'productIngredientEn'} value={data?.productIngredientEn} setData={setData}/>
                    <SingleSelectBox name={'productEfficacy'} selValue={parseInt(data?.productEfficacy)} setData={setData}/>
                    <SingleInputBox title={'제품 정보 URL'} name={'productUrl'} value={data?.productUrl} setData={setData}/>

                </tbody>
            </table>
        </div>
        </>
    )
}