import React from 'react';
import { useParams } from 'react-router-dom';
export interface Props {type:string;name:string;value:string;setData:Function}
export const AdminStratorInputBox:React.FC<Props> = ({type,name,value,setData}) => {
    const {adminId} = useParams()
    const handleChange = (e:any) => {
        const {name, value} = e.target;
        setData((prev:any)=>({...prev, [name]:value}))
    }
    return(
        <>
        <li>
            <label htmlFor="">{type}</label>
            <input type={name === 'admin_pw'?'password':'text'} name={name} value={value} onChange={handleChange} placeholder={`사용하실 ${type}를 입력해 주세요.`} disabled={adminId&&type==='아이디' ? true : false}/>
        </li>
        </>
    )
}