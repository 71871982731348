import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
export interface Props {title:string;name:string;krValue:string;enValue:string;setData:Function}
export const MultiInputBox:React.FC<Props> = ({title,name,krValue,enValue,setData}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const handleChange = (e:any) => {
        const {name,value} = e.target;
        setData((prev:any) => ({...prev, [name] : value}))
    }
    return(
        <>
        <tr className='lang_separation active'>
            <th>{title}</th>
            <td>
                <div className="table_flexWrap">
                    <input type="text" name={`${name}Kr`} id="" value={krValue} placeholder={`국문 ${title}을(를) 입력해 주세요.`} onChange={handleChange} disabled={path[2]==='reporting'?true:false}/>
                    <input type="text" name={`${name}En`} id="" value={enValue} placeholder={`영문 ${title}을(를) 입력해 주세요.`} onChange={handleChange} disabled={path[2]==='reporting'?true:false}/>
                </div>
            </td>
        </tr>
        </>
    )
}