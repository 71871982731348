import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../../util/cookie/cookies";

const Footer = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()

    return(
        <>
        <footer>
            <div>
                <ul>
                    <li onClick={()=>navigate('/binex/about/overView')}>{t("footer_1")}</li>
                    <li onClick={()=>navigate('/syntheticMedicine/search/productname')}>{t("footer_2")}</li>
                    <li onClick={()=>navigate('/contactUs')}>{t("footer_3")}</li>
                    <li onClick={()=>navigate('/siteMap')}>{t("footer_9")}</li>
                    {lang === 'En' ? 
                    '':
                    <>
                        <li className="red" onClick={()=>navigate('/invest/internalInformationManagement')}>{t("footer_4")}</li>
                        <li className="red" onClick={()=>navigate('/privacy')}>개인정보취급방침</li>
                    </>
                    }
                </ul>
            </div>

            <div>
                <span></span>
                <div>
                    <span>
                        <span>{t("footer_5")}</span>
                    </span>
                    <span>
                        <span>{t("footer_6")}</span>
                        {t("footer_7")}
                    </span>
                    <span>
                        <span>Tel</span>
                        032.850.3000
                    </span>
                    <span>
                        <span>Fax</span>
                        032.850.3299
                    </span>
                </div>
                <div>
                    <div>
                        <span>{t("footer_10")}</span>
                        <address>
                            <span>{t("footer_11")}</span>{t("footer_12")}
                        </address>
                    </div>
                    <div>
                        <span>{t("footer_13")}</span>
                        <address>
                            <span>{t("footer_14")}</span>{t("footer_15")}
                        </address>
                        <address>
                            <span>{t("footer_16")}</span>
                            {t("footer_17")}
                        </address>
                    </div>
                </div>
                <p>Copyright © Binex2024. All rights reserved.</p>
            </div>
        </footer> 
        </>
    )
}
export default Footer;