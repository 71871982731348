import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";

export const Benefits = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    {t("benefits_title_1")}
                    <span>{t("benefits_title_2")}</span>
                </h3>

                <div className="tabBtnBox">
                    <div>
                        <div>
                            <div>
                            {lang === 'Kr' ? 
                            <span>
                                경조사 지원
                            </span>
                            : 
                            <span>
                            Support for <br></br>
                            Congrats and <br></br>
                            Condolences
                            </span>
                                
                            }
                                
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>{t("benefits_item_2")}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>{t("benefits_item_3")}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>{t("benefits_item_4")}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>{t("benefits_item_5")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="tabBtnBox">
                    <div>
                        <div>
                            <div>
                                <span>{t("benefits_item_6")}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                {/* <span>{t("benefits_item_7")}</span> */}

                                {lang === 'Kr' ? 
                                <span>
                                    휴양시설(콘도) 지원
                                </span>
                                : 
                                <span>
                                    Support for <br></br>
                                    Recreational <br></br>
                                    Facilities
                                </span>
                                    
                                }
                            </div>
                        </div>
                        <div>
                            <div>
                                {/* <span>{t("benefits_item_8")}</span> */}

                                {lang === 'Kr' ? 
                                <span>
                                    기숙사 지원
                                </span>
                                : 
                                <span>
                                    Support for <br></br>
                                    Dormitories
                                </span>
                                    
                                }
                            </div>
                        </div>
                        <div>
                            <div>
                                <span>{t("benefits_item_9")}</span>
                            </div>
                        </div>
                        <div>
                            <div>
                                {/* <span>{t("benefits_item_10")}</span> */}

                                {lang === 'Kr' ? 
                                <span>
                                    사내동호회 활동지원
                                </span>
                                : 
                                <span>
                                    Support for <br></br>
                                    Company Clubs
                                </span>
                                    
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}