import i18n from 'i18next';
import languageEn from './en/language.json';
import languageKr from './kr/language.json';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    lng: "En",
    resources: {
      Kr: {
        translation: languageKr
      },
      En: {
        translation: languageEn
      },
    },
    fallbackLng: "Kr",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
