import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../../util/cookie/cookies";
import { useGetUserPromotionDetailMutation } from "../../../../store/user/contentsApiSlice";

export const PromotionView = () => {
    const {thirdUrl} = useParams()
    const navigate = useNavigate()
    const lang = getCookie('BINEXLANG')
    const [data, setData] = useState<any>([])
    const [getUserPromotionDetail] = useGetUserPromotionDetailMutation()
    const getDetail = async() => {
        const result : any = await getUserPromotionDetail({id : thirdUrl, lang : lang === 'Kr' ? 'KR' : 'EN'})
        if(result.data.result===true){
            const res = result.data.list[0]
            setData(res)
        }
    }
    useEffect(()=>{
        getDetail()
    }, [lang])
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    <span>{lang === 'Kr' ? '홍보센터' : 'Promotion Center'}</span>
                </h3>
                
                <div className="tableWrap">
                    <table>
                        <tbody>
                            <tr>
                                <th>{lang === 'Kr' ? '제목' : 'Subject'}</th>
                                <td colSpan={3}>
                                    <p><strong>{lang === 'Kr' ? data?.promSubjectKr : data?.promSubjectEn}</strong></p>
                                </td>
                            </tr>
                            <tr className="half">
                                <th>{lang === 'Kr' ? '작성일' : 'Date'}</th>
                                <td>{data?.promMdate}</td>
                                <td className="th">{lang === 'Kr' ? '조회수' : 'View Count'}</td>
                                <td>{lang === 'Kr' ? data?.promViewCntKr : data?.promViewCntEn}</td>
                            </tr>
                            <tr>
                                <th>{lang === 'Kr' ? '내용' : 'Contents'}</th>
                                <td colSpan={3}>
                                    <div>
                                        <div dangerouslySetInnerHTML={{__html: lang === 'Kr' ? data?.promContentsKr : data?.promContentsEn}}></div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="btnBox">
                    <button onClick={()=>navigate(-1)}>{lang === 'Kr' ? '목록' : 'Back'}</button>
                </div>
            </div>

        </div>
        </>
    )
}