import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
export interface Props {title:string; name : string;setData:any;detail:any;url:any}
export const SingleAttachedBox:React.FC<Props> = ({title,name,setData,detail,url}) => {
    const location = useLocation()
    const path = location.pathname.split('/')
    const {id} = useParams();
    const [fileName, setFileName] = useState<any>(null)
    const handleChange = (e:any) => {
        const {name} = e.target;
        const reader = new FileReader();
        if(e.target.files[0]){reader.readAsDataURL(e.target.files[0])}
        reader.onload=()=>{
            setData((prev:any)=>({...prev, [name] : e.target.files[0]}));
            setFileName(e.target.files[0].name)
        }
    }
    const handleDownLoad = () => {
        const fileUrl: any = url;
        const filePath = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
        const downLoad = document.createElement('a');
        downLoad.download = filePath;
        downLoad.href = fileUrl;
        downLoad.target = "_blank";
        document.body.appendChild(downLoad);
        downLoad.click();
        document.body.removeChild(downLoad);
    }
    const handleDelFile= () => {setData((prev:any) => ({...prev, [name] : ''}));setFileName('')}
    useEffect(()=>{
        if(detail){
            setFileName(detail)
        }
    },[detail])
    return(
        <>
        <tr>
            <th className="thumbnail">{title}</th>
            <td>
                <div className={fileName===''||fileName===null?'catalogueUploadBox catalogueUploadBox_before':'catalogueUploadBox catalogueFileUploadBox_after'}>
                    {fileName === '' || fileName === null ?
                    <>
                    <input type="file" id={name} name={name} onChange={handleChange} disabled={path[2]==='reporting'?true:false}/>
                    <label htmlFor={name} className="catalogueUploadBtn catalogueUploadBtn_before">
                        <i className="fa-light fa-upload"></i>
                        {title}
                    </label>
                    </> : <>
                    <p onClick={handleDownLoad}>{fileName}</p>
                    {path[2]==='reporting' ? '' : 
                    <button onClick={handleDelFile}>
                        <i className="fa-light fa-xmark"></i>
                    </button> 
                    }
                    </>
                    }
                </div>
            </td>
        </tr>
        </>
    )
}