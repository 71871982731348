import React, { useEffect } from "react";
import { getCookie } from "../../../../util/cookie/cookies";
import { useNavigate } from "react-router-dom";

export const ElectronicDisclosure = () => {
    const lang = getCookie('BINEXLANG')
    const navigate = useNavigate()
    useEffect(()=>{
        if(lang === 'En'){
            navigate('/invest/electronicNotice')
        }
    }, [lang])
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    바이넥스의 주주와 고객 여러분께
                    <span>당사의 모든 공시정보를 제공해 드립니다.</span>
                </h3>
                : 
                <h3>To our valued shareholders and customers of Binex
                    <span>We provide all of our disclosure information</span>
                </h3>
                    
                }
                <div className="dart_wrapper">
                    <iframe loading="lazy" src="https://dart.fss.or.kr/html/search/SearchCompanyIR3_M.html?textCrpNM=053030" name="IR" scrolling="yes"></iframe>
                </div>
            </div>
        </div>
        </>
    )
}