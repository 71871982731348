import React from "react";
import { useTranslation } from "react-i18next";

export const BioAnalysisSupport = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_4 active">
            <h4>{t("cdo_tab_4")}</h4>
            <p>
                {t("cdo_bioanalysis_title_1")}
            </p>

            <div className="img_flexBox">
                <div className="imgBox">
                    <img src="/assets/images/bioanalysis_support_center.png" alt="바이오분석지원센터"/>
                </div>

                <div>
                    <div>
                        <img src="/assets/images/ultimateLC.png" alt="Ultimate-300 Nano LC System"/>
                        <span>Ultimate-3000 Nano LC System</span>
                    </div>

                    <div>
                        <img src="/assets/images/UltimateRSLC.png" alt="Ultimate-300 X2 Dual RSLC System"/>
                        <span>Ultimate-3000 X2 Dual RSLC System</span>
                    </div>
                </div>
            </div>

            <div className="txtBox">
                <dl>
                    <dt>Identification</dt>
                    <dd>Intact Mass, Peptide mapping fingerprinting, Full length sequencing, N/C terminal determination, Amino acid composition, Extinction coefficient</dd>
                </dl>
                <dl>
                    <dt>Modification analysis</dt>
                    <dd>Modification (Oxidation, Deamination), IsoAsp, Glycation, PEGylation, Succinimide, Disulfide bond, Free thiol </dd>
                </dl>
                <dl>
                    <dt>Glycan analysis</dt>
                    <dd>Monosaccharide compostion, Sialic acid compositon, N-linked glycan profile, O-linked glycan profile, N-glycosylation site, O-glycosylation site</dd>
                </dl>
                <dl>
                    <dt>Higher-order Structure</dt>
                    <dd>UV, Fluorescence, CD, DSC, FT-IR</dd>
                </dl>
                <dl>
                    <dt>Particles & Aggregates</dt>
                    <dd>DLS, SEC-UPLC</dd>
                </dl>
                <dl>
                    <dt>Biological Activity</dt>
                    <dd>HDX</dd>
                </dl>
            </div>
        </div>
        </>
    )
}