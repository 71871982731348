import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Paginate } from "../../../../components/paginate/paginate_admin";
import { PaginateUser } from "../../../../components/paginate/paginate_user";
import { useGetProductMutation } from "../../../../store/admin/adminContentsApiSlice";
import { useGetProductByUserMutation } from "../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../util/cookie/cookies";
import { SearchEfficacyBox } from "./searchType/searchEfficacyBox";
import { SearchNameBox } from "./searchType/searchNameBox";

export const ProductSearch = () => {
    const {t} = useTranslation()
    const {thirdUrl} = useParams()
    const scrollRef = useRef<any>(null)
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search);
    const page : any = searchParam.get('page') || 1;
    const efficacy : any = searchParam.get('efficacy') || '';
    const char : any = searchParam.get('char')||''
    const keyword : any = searchParam.get('keyword')||''
    const navigate = useNavigate()
    const [data, setData] = useState<any>([])
    const [efficacyData, setEfficacyData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    const [getProductApi] = useGetProductByUserMutation()
    const [param, setParam] = useState<any>({user:'U', searchLang : lang==='Kr'?'KR':'EN', searchChar : char , efficacy : efficacy , page:page,size:10,keyword:'',sortColumn:'date',sortOrder:'desc'})
    const getList = async () => {
        const result : any = await getProductApi(param)
        if(result.data.Result==='true'){setData(result.data.List); setEfficacyData(result.data.efficacyList); setTotalCount(result.data.totalCnt)}
    }
    const handlePage = (pageNumber : any) => {
        if(location.pathname === '/syntheticMedicine/search/productname'){
            navigate(`/syntheticMedicine/search/productname?page=${pageNumber}&size=10&keyword=${keyword}&char=${char}`)
        } else {
            navigate(`/syntheticMedicine/search/productefficacy?page=${pageNumber}&size=10&efficacy=${efficacy}`)
        }
    }
    console.log(location.pathname)
    const handleTrans = (url : string) => {navigate(`/syntheticMedicine/search/${url}`)}
    const handleNewPageTrans = (url : string) => {
        window.open(url, '_blank')
    }
    useEffect(()=>{setParam((prev:any) => ({...prev, page : page, efficacy : efficacy, searchChar : char, keyword:keyword}))}, [page, efficacy, char, keyword])
    useEffect(()=>{
        if(lang === 'Kr'){setParam((prev:any) => ({...prev, searchLang : 'KR'}))} 
        else {setParam((prev:any) => ({...prev, searchLang : 'EN'}))}
    }, [lang])
    useEffect(()=>{getList()}, [param.page, param.efficacy, param.searchChar, param.keyword, param.searchLang])
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    <span>{t("syntheticMedicine_title")}</span>
                </h3>
                <p>{t("syntheticMedicine_sub_title")}</p>
                
                <div className="tabBtnBox tabBtnBox_1">
                    {lang === 'En' ? '' : 
                    <div>
                        <div onClick={()=>handleTrans('productname')}>
                            <button className={thirdUrl==='productname'?'active':''}>{t("syntheticMedicine_search")}</button>
                        </div>
                        <div onClick={()=>handleTrans('productefficacy')}>
                            <button className={thirdUrl==='productefficacy'?'active':''}>효능별 검색</button>
                        </div>
                    </div>
                    }
                </div>
                <div className="tabBtnBox tabBtnBox_2">
                    <div>
                        <div>
                            {thirdUrl === 'productname' ? 
                            <SearchNameBox/> : 
                            <SearchEfficacyBox efficacyList={efficacyData}/>}
                        </div>
                    </div>
                </div>
                <div className="tabBtnBox tabBtnBox_3">
                    <div>
                        <div>
                            <div className="searchResultBox" ref={scrollRef}>
                                {keyword !== '' || char !== '' ? 
                                <p>
                                    {lang === 'Kr' ? 
                                    <>'<span>{keyword===''?char:keyword}</span>'(으)로 검색한 결과</>:
                                    <>Search results for '<span>{keyword===''?char:keyword}</span>'</>
                                    }
                                </p>
                                :''
                                }
                                <span>{t("syntheticMedicine_search_3")} <span>{totalCount}</span>{t("syntheticMedicine_search_4")}</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- 제품별 검색일때만 활성화 --> */}

                <ul className="searchResultList">
                    {data?.map((list:any) => (
                    <li>
                        <div>
                            <div className="thumbBox">
                                <img src={list?.productImageFile} alt="글리렙 서방정"/>
                            </div>
                            <div className="txtBox">
                                <dl>
                                    <dt>
                                    {lang==='Kr'?
                                    list?.productNameKr
                                    :
                                    list?.productNameEn
                                    }
                                    </dt>
                                    <dd>
                                        <span>{t("syntheticMedicine_search_5")}</span>
                                        {lang==='Kr'?
                                        list?.productIngrdiendKr
                                        : 
                                        list?.productIngrdiendEn
                                        }
                                    </dd>
                                    <dd>
                                        <span>{t("syntheticMedicine_search_6")}</span>
                                        {lang==='Kr'?list?.efficacyNameKr:list?.efficacyNameEn}
                                    </dd>
                                </dl>
                                <button onClick={()=>handleNewPageTrans(list?.productUrl)}>{t("syntheticMedicine_search_7")}</button>
                            </div>
                        </div>
                    </li>
                    ))}
                </ul>

                <div className="pagerBox">
                    <Paginate setPage={handlePage} totalCount={totalCount} page={page} size={10}/>
                </div>
            </div>

        </div>
        </>
    )
}