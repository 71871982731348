import React from "react";
import { useUpdInquiryReplyStatusMutation } from "../../../store/admin/adminReportingApiSlice";
import { useParams } from "react-router-dom";
interface Props {status : string}
export const InquiryStatusBox:React.FC<Props> = ({status}) => {
    const {id} : any = useParams()
    const [updInquiryReplyStatus] = useUpdInquiryReplyStatusMutation()
    const handleChangeStatus = async(status : string) => {
        const confirm = window.confirm("상태를 변경하시겠습니까?")
        if(confirm){
            const formData : any = new FormData()
            formData.append('inquiryId', id)
            formData.append('inquiryReplyStatus', status)
            const result : any = await updInquiryReplyStatus(formData)
        }else return;
    }
    return(
        <>
        <tr>
            <th>상태</th>
            <td>
                <select onChange={(e)=>handleChangeStatus(e.target.value)}>
                    <option value={'U'} selected={status==='U'}>답변대기</option>
                    <option value={'R'} selected={status==='R'}>답변완료</option>
                </select>
            </td>
        </tr>
        </>
    )
}