import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FinishedDrug } from "./sub/finishedDrug";
import { OriginalDrug } from "./sub/originalDrug";
import { Quality } from "./sub/quality";

export const CMO = () => {
    const {t} = useTranslation()
    const {thirdUrl} = useParams()
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const tab = searchParam.get('tab') || 1;
    const navigate = useNavigate()
    const handleTrans = (url : string) => {
        navigate(`/bioMedicine/cdmoproduction?tab=${url}`)
    }
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("cmo_title")}</h3>
                <p>
                    <strong>
                        {t("cmo_sub_title_1")}<br></br>
                        {t("cmo_sub_title_2")}
                    </strong>
                </p>
                <p>
                    {t("cmo_sub_title_content")}
                </p>

                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>handleTrans('1')}>
                            <button className={tab === '1' ? 'active' :''}>{t("cmo_tab_1")}</button>
                        </div>
                        <div onClick={()=>handleTrans('2')}>
                            <button className={tab === '2' ? 'active' :''}>{t("cmo_tab_2")}</button>
                        </div>
                        
                    </div>
                </div>

                <div className="tabContentBox">
                    {tab === '1' ? <OriginalDrug/> :''}
                    {tab === '2' ? <FinishedDrug/> :''}
                </div>

            </div>


        </div>
        </>
    )
}