import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BackDrop } from "../../../components/backDrop/backDrop";
import { setAdminInfo } from "../../../store/admin/adminInfoSlice";
import { useGetManagerLoginMutation } from "../../../store/admin/adminstrators";
import { useAppDispatch } from "../../../store/storeHooks";
import AdminPageStyles from '../../modules/css_module/admin/admin_page.module.css'
export const AdminLogin = () => {
    const groupId = sessionStorage.getItem("GroupCode")
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [getManagerLoginApi] = useGetManagerLoginMutation()
    const [data, setData] = useState<{id:string;pw:string;idSave:boolean;isOpen:boolean}>({
        id: '', pw : '', idSave : false, isOpen : false
    })
    const handleChange = (e:any) => {
        const {name, type, value, checked} = e.target;
        if(type==='checkbox'){setData((prev:any)=>({...prev, idSave : checked}))}
        else {setData((prev:any)=>({...prev, [name]:value}))}
    }
    const handleLogin = async() => {
        setData((prev:any)=>({...prev, isOpen : true}))
        const formData : any = new FormData();
        formData.append('manager_login', data.id)
        formData.append('manager_pass', data.pw)
        const result : any = await getManagerLoginApi(formData)
        if(result.data.result === true){
            setTimeout(()=>{
                if(result.data.manager_group === '16'){navigate('/dotsAdmin/products/product')}
                else if(result.data.manager_group === '17'){navigate('/dotsAdmin/invest/notice')}
                else if(result.data.manager_group === '18'){navigate('/dotsAdmin/reporting/report')}
                else if(result.data.manager_group === '19'){navigate('/dotsAdmin/reporting/inquiry')}
                else if(result.data.manager_group === '20'){navigate('/dotsAdmin/reporting/inquiry')}
                else {navigate('/dotsAdmin/commoncode')}
            }, 1000);
            sessionStorage.setItem('BinexKey' , result.data.ID)
            sessionStorage.setItem('GroupCode', result.data.manager_group)
            dispatch(setAdminInfo({adminId : result.data.ID, account : result.data.manager_id, name : result.data.manager_name, groupId : result.data.manager_group}))
        } else {
            alert("아이디 및 비밀번호가 일치하지 않습니다.")
            setData((prev:any)=>({...prev, isOpen : false}))
        }
    }
    const handleEnter = (e:any) => {
        if(e.key === 'Enter'){handleLogin()}
    }
    return(
        <>
        <div id="wrap">
            <div className='admin_loginBox'>
                <h2>Binex ADMIN</h2>
                <div>
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_id.png" alt="login"/>
                        </label>
                        <input type="text" name="id" id="id" placeholder="아이디" onChange={handleChange} onKeyDown={handleEnter}/>
                    </div>
                    <div className="inputList">
                        <label htmlFor="">
                            <img src="/assets/images/form_pw.png" alt="password"/>
                        </label>
                        <input type="password" name="pw" id="pw" placeholder="비밀번호" onChange={handleChange} onKeyDown={handleEnter}/>
                    </div>
                    <div className="inputCheckBox">
                        <input type="checkbox" name="save" id="save" onChange={handleChange}/>
                        <label htmlFor="save">아이디 저장</label>
                    </div>

                    <button className="admin_loginBtn" onClick={handleLogin}>로그인</button>
                </div>
            </div>
        </div>
        <BackDrop open={data.isOpen}/>
        </>
    )
}