import React from "react";
import { useTranslation } from "react-i18next";

export const ProcessDevelop = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_2 active">
            <h4>{t("cdo_tab_2")}</h4>
            <p>
                {t("cdo_process_title_1")}
                <span>{t("cdo_process_title_2")}</span>
            </p>
            
            <span>{t("cdo_process_title_1_2")}</span>
            <div className="flexBox">
                <div className="left">
                    <div>
                        <dl>
                            <dt>Mammalian cell</dt>
                            <dd>{t("cdo_process_content_1")}</dd>
                            <dd>{t("cdo_process_content_2")}
                                <span>{t("cdo_process_content_2_1")}</span>
                            </dd>
                            <dd>{t("cdo_process_content_3")}</dd>
                            {/* <dd>{t("cdo_process_content_4")}</dd> */}
                        </dl>
                        <dl>
                            <dt>Microbial</dt>
                            <dd>
                                {t("cdo_process_content_4")}
                                <span>{t("cdo_process_content_4_1")}</span>
                                <span>{t("cdo_process_content_4_2")}</span>
                            </dd>
                            <dd>{t("cdo_process_content_5")}
                                <span>{t("cdo_process_content_5_1")}</span>
                            </dd>
                            <dd>{t("cdo_process_content_6")}</dd>
                        </dl>
                    </div>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/process_development.png" alt="공정개발"/>
                </div>
            </div>

            <span>{t("cdo_process_content_9")}</span>
            <div className="flexBox">
                <div className="left">
                    <ul>
                        <li>{t("cdo_process_content_10")}</li>
                        <li>{t("cdo_process_content_11")}</li>
                        <li>{t("cdo_process_content_12")}</li>
                        <li>{t("cdo_process_content_13")}
                            <span>{t("cdo_process_content_13_2")}</span>
                        </li>
                        <li>{t("cdo_process_content_14")}</li>
                        <li>{t("cdo_process_content_15")}
                            <span>{t("cdo_process_content_15_2")}</span>
                        </li>
                    </ul>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/refining_process_development.png" alt="정제공정개발"/>
                </div>
            </div>

            <div>
                <h5>Process Flow</h5>
                <p>Upstream Process Development</p>
                
                <div className="listWrap">
                    <div>
                        <span>{t("cdo_process_content_7")}</span>
                    </div>
                    <ul className="listBox">
                        <li>
                            <div>
                                <img src="/assets/images/animal_cells_1.png" alt="동물세포"/>
                            </div>
                            <div>
                                <span>
                                    Media screening<br/>
                                    (Flask)
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/animal_cells_2.png" alt="동물세포"/>
                            </div>
                            <div>
                                <span>
                                    Feed screening<br/>
                                    (Ambr 15)
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/animal_cells_3.png" alt="동물세포"/>
                            </div>
                            <div>
                                <span>
                                    Process optimization<br/>
                                    (Ambr 15)
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/animal_cells_4.png" alt="동물세포"/>
                            </div>
                            <div>
                                <span>
                                    Process evaluation<br/>
                                    (Small scale bioreactor)
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div className="listWrap">
                    <div>
                        <span>{t("cdo_process_content_8")}</span>
                    </div>
                    <ul className="listBox">
                        <li>
                            <div className="grayBox"></div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/microbe_1.png" alt="미생물"/>
                            </div>
                            <div>
                                <span>
                                    Supplement<br></br>
                                    screening
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/microbe_1.png" alt="미생물"/>
                            </div>
                            <div>
                                <span>
                                    Process parameter<br/>
                                    optimization (Ambr 250)
                                </span>
                            </div>
                        </li>
                        <li>
                            <div>
                                <img src="/assets/images/microbe_2.png" alt="미생물"/>
                            </div>
                            <div>
                                <span>
                                    Process evaluation<br/>
                                    (Small scale fermenter)
                                </span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            {/* <span>{t("cdo_process_content_9")}</span>
            <div className="flexBox">
                <div className="left">
                    <ul>
                        <li>{t("cdo_process_content_10")}</li>
                        <li>{t("cdo_process_content_11")}</li>
                        <li>{t("cdo_process_content_12")}</li>
                        <li>{t("cdo_process_content_13")}
                            <span>{t("cdo_process_content_13_2")}</span>
                        </li>
                        <li>{t("cdo_process_content_14")}</li>
                        <li>{t("cdo_process_content_15")}
                            <span>{t("cdo_process_content_15_2")}</span>
                        </li>
                    </ul>
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/refining_process_development.png" alt="정제공정개발"/>
                </div>
            </div> */}

            
            <div>
                <h5>
                    {/* Process Flow */}
                    <span>Downstream Process Development</span>
                </h5>
                
                <ul className="listBox">
                    <li>
                        <div>
                            <img src="/assets/images/screening.png" alt="Workflow"/>
                        </div>
                        <div>
                            <span>
                                Screening<br/>
                                (resin, buffer)
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/process_optimization.png" alt="Workflow"/>
                        </div>
                        <div>
                            <span>
                                Process<br/>
                                Optimization
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/process_evaluation.png" alt="Workflow"/>
                        </div>
                        <div>
                            <span>
                                Process<br/>
                                Evaluation
                            </span>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
        </>
    )
}