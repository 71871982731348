import React from "react";

export const Privacy = () => {
    // style="background-image: url('/assets/images/banner.png');"
    return(
        <>
        <div className="subPage privacy">
            <div className="banner_area">
                <div>
                    <h2>개인정보취급방침</h2>
                </div>
            </div>

            
            <div className="stickyNavBox">
                <div className="none">
                    <span></span>
                    <div>
                        <span>개인정보취급방침</span>
                    </div>
                </div>
            </div>

            <div className="contentBox">
                <div>
                    <h3>
                        <span>개인정보취급방침</span>
                    </h3>
                    <ul>
                        <li>
                            <div>
                                <span>1. 총칙</span>
                                <p>
                                    (주)바이넥스는 귀하의 개인정보보호를 매우 중요시하며, &lt;정보통신망 이용 촉진 등에 관한 법률&gt;상의 개인정보보호 규정 및 정보통신부가 제정한 &lt;개인정보보호지침&gt;을 준수하고 있습니다.
                                    (주)바이넥스는 개인정보보호정책을 통하여 귀하께서 제공하시는 개인 정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다.
                                    (주)바이넥스는 개인정보보호정책을 홈페이지 첫 화면에 공개함으로써 귀하께서 언제나 용이하게 보실 수 있도록 조치하고 있습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>2. 개인정보수집에 대한 동의</span>
                                <p>
                                    (주)바이넥스는 귀하께서 (주)바이넥스의 개인정보보호정책 또는 이용 약관의 내용에 대해 "동의"를 체크 하는 절차를 마련하여, "동의"버튼을 체크하시면 개인정보 수집에 대해 동의한 것으로 
                                    봅니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>3. 개인정보의 수집목적 및 이용목적</span>
                                <p>
                                    (주)바이넥스는 다음과 같은 목적을 위하여 개인정보를 수집하고 있습니다.
                                    (주)바이넥스는 웹상에서 제공하는 서비스의 원활한 이용과 각종 고객상담을 하기 위한 최소한의 정보를 필수 사항으로 수집합니다.
                                    단, 이용자의 기본적 인권 침해의 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다.
                                </p>
                                <p>회원님이 제공하신 모든 정보는 상기 목적에 필요한 용도 이외로는 사용되지 않으며 수집 정보의 범위나 사용 목적, 용도가 변경될 시에는 반드시 회원님께 사전 동의를 구할 것입니다.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>4. 개인 정보 수집 항목 및 보유, 이용기간</span>
                                <p>
                                    (주)바이넥스가  서비스 제공을 위해 제공받는 개인 정보는 성명, 전화번호, 이메일, 기타 회사가 필요하다고 인정하는 사항입니다.
                                    귀하의 개인 정보는 개인 정보의 수집목적 또는 제공받은 목적이 달성되면 파기됩니다. 상기 조항에도 불구하고 완료되지 않은 업무수행, 법령의 규정에 따라 보존할 필요성이 있을 경우에는 귀하의
                                    개인 정보를 계속 관리할 수 있습니다. 이와 다른 목적으로 귀하의 개인 정보를 계속 보유하여야 할 필요가 있을 경우에는 귀하의 동의를 받겠습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>5. 개인정보 자동 수집 장치의 설치/운영 및 거부의 관한 사항</span>
                                <p>(주)바이넥스는 안정적인 홈페이지 운영을 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.</p>
                                <p>
                                    쿠키는 웹사이트가 귀하의 컴퓨터 브라우저(넷스케이프, 인터넷 익스플로러 등)로 전송하는 소량의 정보입니다. 귀하께서 웹사이트에 접속을 하면 ㈜ 바이넥스의 컴퓨터는 귀하의 브라우저에 있는
                                    쿠키의 내용을 읽고, 귀하의 추가정보를 귀하의 컴퓨터에서 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
                                </p>
                                <p>
                                    쿠키는 귀하의 컴퓨터는 식별하지만 귀하를 개인적으로 식별하지는 않습니다. 또한 귀하는 쿠키에 대한 선택권이 있습니다. 웹브라우저의 옵션을 조정함으로써 모든 쿠키를 다 받아들이거나,
                                    쿠키가 설치될 때 통지를 보내도록 하거나, 아니면 모든 쿠키를 거부할 수 있는 선택권을 가질 수 있습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>6. 목적 외 사용 및 제3자에 대한 제공</span>
                                <p>(주)바이넥스는  귀하의 개인 정보를 &lt;개인정보의 수집목적 및 이용목적&gt;에서 고지한 범위 내에서 사용하며, 동 범위를 초과하여 이용하거나 타인 또는 타 기업·기관에 제공하지 않습니다.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>7. 개인정보의 열람, 정정</span>
                                <p>
                                    귀하는 언제든지 등록되어 있는 귀하의 개인정보를 열람 및 정정 요청하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 개인정보관리책임자에게 E-mail로 연락하시면
                                    조치하겠습니다. 귀하가 개인정보의 오류에 대한 정정을 요청한 경우, 정정을 완료하기 전까지 당해 개인정보를 이용하지 않습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>8. 개인정보 수집, 이용, 제공에 대한 동의철회</span>
                                <p>
                                    제보하기 등을 통해 개인정보의 수집, 이용, 제공에 대해 귀하께서 동의하신 내용을 귀하는 언제든지 철회하실 수 있습니다. 동의철회는 개인정보관리 책임자에게 E-mail로 연락하시면 즉시
                                    개인정보의 삭제 등 필요한 조치를 하겠습니다.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>9. 개인정보보호를 위한 기술적 대책</span>
                                <p>
                                    (주)바이넥스는  귀하의 개인정보를 취급함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적 대책을 강구하고 있습니다. 단, 다음의 
                                    기술적 대책에도 불구하고 해커의 침입 및 신종 바이러스 침투와 같은 사고 발생 당시의 기술로 예방, 조치할 수 없는 불가항력 사유로 인하여 발생한 개인정보의 분실, 도난, 누출, 변조 또는 훼손에 
                                    대하여는 귀하에게 책임을 지지 않습니다.
                                </p>
                                <p>
                                    귀하의 개인정보는 비밀번호에 의해 보호되며, 파일 및 전송 데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다. (주)바이넥스는
                                    백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 
                                    이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                                </p>
                                <p>
                                    (주)바이넥스는 암호알고리즘을 이용하여 네트워크 상의 개인정보를 안전하게 전송할 수 있는 보안장치(SSL 또는 SET)를 채택하고 있습니다. 해킹 등에 의해 귀하의 개인정보가 유출되는 것을 
                                    방지하기 위해, 외부로부터의 침입을 차단하는 장치를 이용하고 있으며, 각 서버마다 침입탐지시스템을 설치하여 24시간 침입을 감시하고 있습니다. 귀하는 온라인을 통한 개인정보 및 데이터 
                                    전송이 100% 안전할 수 없음을 잘 알고 있습니다. 귀하가 (주)바이넥스에 전송하거나 (주)바이넥스로부터 제공받는 정보의 보안을 100% 보장할 수는 없으며, 이러한 위험은 귀하가 부담하셔야
                                    됩니다.
                                </p>
                                <p>(주)바이넥스는 귀하의 정보를 제공받으면 온라인상의 보안이 최대한 유지될 수 있도록 최선의 노력을 다할 것입니다.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>10. 개인정보의 위탁처리</span>
                                <p>
                                    (주)바이넥스는 서비스 향상을 위해서 귀하의 개인정보를 외부에 위탁하여 처리할 수 있습니다. 개인정보의 처리를 위탁하는 경우에는 미리 그 사실을 귀하에게 고지하겠습니다. 개인정보의 처리를
                                    위탁하는 경우에는 위탁계약 등을 통하여 서비스제공자의 개인정보보호 관련 지시엄수, 개인정보에 관한 비밀유지, 제3자 제공의 금지 및 사고시의 책임부담 등을 명확히 규정하고 당해 계약내용을
                                    서면 또는 전자적으로 보관하겠습니다.
                                </p>
                            </div>
                        </li>
                        <li> 
                            <div>
                                <span>11. 의견수렴 및 불만처리</span>
                                <p>
                                    (주)바이넥스는 개인정보보호와 관련하여 귀하가 의견과 불만을 제기할 수 있는 창구를 개설하고 있습니다. 개인정보와 관련한 불만이 있으신 분은 (주)바이넥스 개인정보 관리책임자에게 의견을 
                                    주시면 접수 즉시 조치하여 처리결과를 통보해 드립니다. 또는 정부에서 설치하여 운영중인 개인정보침해신고센터(http://www.cyberprivacy.or.kr, 전화 02-1336, PC통신 go eprivacy)에 
                                    불만처리를 신청할 수 있습니다.
                                </p>
                                <p>만 14세 미만 아동의 개인정보도 성인의 개인정보와 동일하게 관리되며, 법정대리인의 동의 없이는 제3자와 공유하지 않습니다.</p>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>12. 개인정보 관리 책임자</span>
                                <p>(주)바이넥스는 개인정보에 대한 의견수렴 및 불만처리를 담당하는 개인정보 관리책임자 및 개인정보 관리담당자를 지정하고 있습니다.</p>
                                <div>
                                    <span>책임자 : 방인성 이사 / 전산팀</span>
                                    <span>전화번호 : 032-850-3238</span>
                                    <span>이메일 : isbang@bi-nex.com</span>
                                    <span>담당자 : 신현강 차장 / 전산팀</span>
                                    <span>전화번호 : 032-850-3212</span>
                                    <span>이메일 : hkshin@bi-nex.com</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div>
                                <span>13. 개인정보처리방침 변경</span>
                                <p>이 개인정보처리방침은 2022년 12월 1일부터 적용됩니다.</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
        </>
    )
}