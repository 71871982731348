import React, { useEffect, useRef } from "react";
import AnimateHeight from "react-animate-height";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";
export const DashboardSidebar = () => {
    const {type}:any = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')
    const isActiveRef = useRef<any>([])
    const groupId = sessionStorage.getItem('GroupCode')
    const isMaster = groupId === '21';
    return(
        <>
        <aside>
            <h2>
                <img src="/assets/images/binex_logo.png" alt="marineplaza logo"/>
            </h2>
            <ul className="mainMenu">
                {/* <!-- <li className="analytics">
                    <span>Analytics</span>
                </li> --> */}
                {isMaster ? 
                <li className={path[2]==='commoncode'?'contents active' : 'contents'}>
                    <span onClick={()=>navigate('/dotsAdmin/commoncode')}>공용코드관리</span>
                    <AnimateHeight duration={500} height={path[2]==='commoncode' ? "auto" : 0}>
                        <ul className="subMenu active">
                            {commonCodeMenu?.map((list:{id:number, title:string})=>(
                                <li key={list?.id} className='' ref={(e)=>isActiveRef.current[list.id]=e}>
                                    <span onClick={()=>navigate('/dotsAdmin/commoncode')}>{list?.title}</span>
                                    <button onClick={()=>navigate('/dotsAdmin/commoncode/regist')}><i className="fa-regular fa-plus"></i></button>
                                </li> 
                            ))}
                        </ul>
                    </AnimateHeight>
                </li> : ''
                }
                {groupId === '16' || isMaster ? 
                <li className={path[2]==='products'?'contents active' : 'contents'}>
                    <span onClick={()=>navigate(`/dotsAdmin/products/product`)}>제품관리</span>
                    <AnimateHeight duration={500} height={path[2]==='products' ? "auto" : 0}>
                        <ul className="subMenu active">
                            {productMenu?.map((list:{id:number, title:string,type:string})=>(
                                <li key={list?.id} className='' ref={(e)=>isActiveRef.current[list.id]=e}>
                                    <span onClick={()=>navigate(`/dotsAdmin/products/${list?.type}`)}>{list?.title}</span>
                                    <button onClick={()=>navigate(`/dotsAdmin/products/${list?.type}/regist`)}><i className="fa-regular fa-plus"></i></button>
                                </li> 
                            ))}
                        </ul>
                    </AnimateHeight>
                </li>
                : ''
                }
                {groupId === '17' || isMaster ? 
                <li className={path[2]==='invest'?'contents active' : 'contents'}>
                    <span onClick={()=>navigate(`/dotsAdmin/invest/notice`)}>투자정보관리</span>
                    <AnimateHeight duration={500} height={path[2]==='invest' ? "auto" : 0}>
                        <ul className="subMenu active">
                            {investMenu?.map((list:{id:number, title:string,type:string})=>(
                                <li key={list?.id} className='' ref={(e)=>isActiveRef.current[list.id]=e}>
                                    <span onClick={()=>navigate(`/dotsAdmin/invest/${list?.type}`)}>{list?.title}</span>
                                    <button onClick={()=>navigate(`/dotsAdmin/invest/${list?.type}/regist`)}><i className="fa-regular fa-plus"></i></button>
                                </li> 
                            ))}
                        </ul>
                    </AnimateHeight>
                </li> : ''
                }
                {groupId === '17' || isMaster ? 
                <li className={path[2]==='promotion'?'contents active' : 'contents'}>
                    <span onClick={()=>navigate(`/dotsAdmin/promotion`)}>홍보센터</span>
                    <AnimateHeight duration={500} height={path[2]==='promotion' ? "auto" : 0}>
                        <ul className="subMenu active">
                            {promotionMenu?.map((list:{id:number, title:string,type:string})=>(
                                <li key={list?.id} className='' ref={(e)=>isActiveRef.current[list.id]=e}>
                                    <span onClick={()=>navigate(`/dotsAdmin/promotion`)}>{list?.title}</span>
                                    <button onClick={()=>navigate(`/dotsAdmin/promotion/regist`)}><i className="fa-regular fa-plus"></i></button>
                                </li> 
                            ))}
                        </ul>
                    </AnimateHeight>
                </li> : ''
                }
                <li className={path[2]==='reporting'?'contents active' : 'contents'}>
                    {groupId === '18' ? 
                    <span onClick={()=>navigate(`/dotsAdmin/reporting/report?workplace=&whistleblowType=&blowStatus=&whistleBlowFromMdate=&whistleBlowToMdate=&page=1&size=10&sortColumn=date&sortOrder=desc&keyword=`)}>제보관리</span> : 
                    <span onClick={()=>navigate(`/dotsAdmin/reporting/inquiry`)}>문의관리</span>
                    }
                    <AnimateHeight duration={500} height={path[2]==='reporting' ? "auto" : 0}>
                        <ul className="subMenu active">
                            {groupId === '18' ? 
                            '' : 
                            <li className={path[3]==='inquiry'?'active':''}>
                                <span onClick={()=>navigate('/dotsAdmin/reporting/inquiry')}>문의사항</span>
                            </li>
                            }
                            {groupId === '18' ? 
                            <li className={path[3]==='report'?'active':''}>
                                <span onClick={()=>navigate(`/dotsAdmin/reporting/report?workplace=&whistleblowType=&blowStatus=&whistleBlowFromMdate=&whistleBlowToMdate=&page=1&size=10&sortColumn=date&sortOrder=desc&keyword=`)}>제보사항</span>
                            </li> : ''
                            }
                        </ul>
                    </AnimateHeight>
                </li>
                {isMaster ? 
                <li className={path[2]==='setting'?'setting active' : 'setting'}>
                    <span onClick={()=>navigate('/dotsAdmin/setting/adminstrator')}>Setting</span>
                    <AnimateHeight duration={500} height={path[2] === 'setting' ? "auto" : 0}>
                        <ul className="subMenu active">
                            <li className={path[3]==='adminstrator'?'active':''}>
                                <span onClick={()=>navigate('/dotsAdmin/setting/adminstrator')}>Administrator</span>
                                <button onClick={()=>navigate(`/dotsAdmin/setting/adminstrator/regist`)}><i className="fa-regular fa-plus"></i></button>
                            </li>
                            <li className={path[3]==='popup-management'?'active':''}>
                                <span onClick={()=>alert("개발진행중")}>MainPage Popup</span>
                            </li>
                        </ul>
                    </AnimateHeight>
                </li> : ''
                }
            </ul>
        </aside>
        </>
    )
}


export const commonCodeMenu = [
    {id: 1, title : '공용코드'}
]

export const productMenu = [
    {id: 1, title : '제품 등록 및 수정', type: 'product'}, {id: 2, title : '제품허가정보',type: 'P'}, {id: 3, title : '비만치료약물',type: 'O'}
]
export const investMenu = [
    {id: 1, title : '전자공고', type: 'notice'}
]
export const reportingMenu = [
    {id: 1, title : '제보사항', type: 'report'}, {id: 2, title : '문의사항', type : 'inquiry'}
]
export const promotionMenu = [
    {id : 1, title : '홍보센터', type : 'promotion'}
]