import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { SingleAttachedBox } from "../../../../../../components/contents/regist/singleAttachedPDFBox";
import { SingleContentsBox } from "../../../../../../components/contents/regist/singleContentsBox";
import { SingleDatePickerBox } from "../../../../../../components/contents/regist/singleDatePickerBox";
import { useGetFinanceSettlementDetailMutation, useSetFinanceSettlementMutation, useUpdFinanceSettlementMutation } from "../../../../../../store/admin/adminInvestApiSlice";
import { RegistHeader } from "../regist-header/regist-header";

export const InvestNoticeRegist = () => {
    const {id}:any = useParams()
    console.log(id)
    const navigate = useNavigate()
    const [setFinanceSettlementApi] = useSetFinanceSettlementMutation()
    const [updFinanceSettlementApi] = useUpdFinanceSettlementMutation()
    const [getFinanceSettlementDetailApi] = useGetFinanceSettlementDetailMutation()
    const initData = {faLang : 'KR', managerId : 1, maangerName:'관리자', subjectKr : '', subjectEn : '', contentKr : '', contentEn : '', attachedFileKr : '', attachedFileEn : '', date : ''}
    const [data, setData] = useState<any>(initData)
    const [fileData, setFileData] = useState<any>({
        attachedFile1Url : '', attachedFileName1 : '',attachedFile2Url : '', attachedFileName2 : '',
    })
    const getDetail=async()=>{
        const result : any = await getFinanceSettlementDetailApi({id : id})
        if(result.data.Result === 'true'){
            setData((prev:any)=>({...prev, subjectKr : result.data.List[0].fsSubjectKr, subjectEn : result.data.List[0].fsSubjectEn, contentKr : result.data.List[0].fsContentsKr, contentEn : result.data.List[0].fsContentsEn,
            date : result.data.List[0].fsMdate}));
            setFileData((prev:any)=>({...prev, attachedFile1Url : result?.data?.List[0].fsAttached1PdsFile, attachedFileName1 : result.data.List[0].fsAttached1PdsFilename,
            attachedFile2Url : result?.data?.List[0].fsAttached2PdsFile, attachedFileName2 : result.data.List[0].fsAttached2PdsFilename}))
        }
    }
    const handleSave = async() => {
        const formData : any = new FormData()
        if(id){formData.append('idx', id)}
        formData.append('faLang', data.faLang)
        formData.append('mangerId', data.managerId)
        formData.append('managerName', data.maangerName)
        formData.append('fsSubjectKr', data.subjectKr)
        formData.append('fsSubjectEn', data.subjectEn)
        formData.append('fsContentsKr', data.contentKr)
        formData.append('fsContentsEn', data.contentEn)
        formData.append('fsMdate', data.date)
        if(data?.attachedFileKr){formData.append('fsAttached1PdsFile', data.attachedFileKr)}
        if(data?.attachedFileEn){formData.append('fsAttached2PdsFile', data.attachedFileEn)}

        if(id){
            const result : any = await updFinanceSettlementApi(formData)
            if(result.data.result === true){alert("수정이 완료되었습니다."); navigate(-1)}
        }
        else {
            const result : any = await setFinanceSettlementApi(formData)
            if(result.data.result === true){alert("등록이 완료되었습니다."); navigate('/dotsAdmin/invest/notice')}
        }
    }
    useEffect(()=>{
        if(id!==undefined){getDetail()}
        else {
            const today = new Date();
            const year = today.getFullYear();
            const month = String(today.getMonth() + 1).padStart(2, "0");
            const day = String(today.getDate()).padStart(2, "0");
            const todayDate = `${year}-${month}-${day}`;
            setData((prev: any) => ({ ...prev, date: todayDate }));
        }
    }, [id])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'전자공고 등록'} backUrl={'/dotsAdmin/products/obesitymedicine'} onSave={handleSave}/>

            <table>
                <tbody>
                    <MultiInputBox title={'제목'} name={'subject'} krValue={data?.subjectKr} enValue={data?.subjectEn} setData={setData}/>
                    <SingleContentsBox title={'국문 본문'} value={data?.contentKr} name={'contentKr'} setData={setData}/>
                    <SingleContentsBox title={'영문 본문'} value={data?.contentEn} name={'contentEn'} setData={setData}/>
                    <SingleAttachedBox title={'첨부파일1'} name={'attachedFileKr'} setData={setData} detail={fileData.attachedFileName1} url={fileData.attachedFile1Url}/>
                    <SingleAttachedBox title={'첨부파일2'} name={'attachedFileEn'} setData={setData} detail={fileData.attachedFileName2} url={fileData.attachedFile2Url}/>
                    <SingleDatePickerBox title={'등록일자'} name={'date'} value={data?.date} setData={setData}/>
                </tbody>
            </table>
        </div>
        </>
    )
}