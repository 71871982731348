import React from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";

export const IdealTalent = () => {
    const {t} = useTranslation()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("talent_title")}</h3>
                    {lang === 'Kr' ? 
                    <p>
                        바이넥스는 자기 분야에서 <strong>전문성</strong>과 <strong>책임감</strong>을 갖추고, <strong>상호협력</strong>을 실천하며,<br/>
                        열린 사고와 올바른 마인드로 <strong>공동의 목표를 달성</strong>하고자 하는 노력하는 인재를 지향합니다.
                    </p> : 
                    <p>Binex is looking for individuals who have <strong>expertise </strong> and <strong> responsibility </strong> in their field, practice mutual <br></br>  <strong> cooperation</strong>, and strive  <strong> to achieve common goals </strong> with an open mind and the right mindset.</p>
                        
                    }

                <div className="tabBtnBox">
                    <div>
                        <div>
                            <div>
                                <div>
                                    {lang === 'Kr' ? <span>전문성</span> : ''}
                                    <span>Professionalism</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                {lang === 'Kr' ? <span>책임감</span> : ''}
                                    <span>Responsibility</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                {lang === 'Kr' ? <span>소통</span> : ''}
                                    <span>Communication</span>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div>
                                {lang === 'Kr' ? <span>협력</span> : ''}
                                    <span>Teamwork</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}