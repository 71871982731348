import React, { useEffect, useState } from "react";
import Select from 'react-select';
import { useGetCommonCodeListMutation } from "../../../store/admin/adminCommonCodeApiSlice";
export interface Props {setData:Function, name:string, selValue : number}
export const SingleSelectBox:React.FC<Props> = ({setData,name, selValue}) => {
    const [getCommonCodeList] = useGetCommonCodeListMutation()
    const [selectList, setSelectList] = useState<any>([])
    const getList = async() => {
        const param = {codeId : 'EC',page:1,size:99,keyword:'',sortColumn:'date',sortOrder:'desc'}
        const result : any = await getCommonCodeList(param);
        if(result){
            const formmatData = result.data.List.map((list:any, index : any)=>({
                value : list.idx,
                label : list.codeNameKr
            }))
            setSelectList(formmatData)
        }
    }
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, [name] : e.value}))
    }
    useEffect(()=>{getList()}, [])

    return(
        <>
        <tr>
            <th>{'효능명'}</th>
            <td>
                <Select
                    value={selectList?.find((x:any) => x.value === selValue)}
                    name="supplier"
                    options={selectList}
                    className="selectBox1"
                    onChange={handleChange}
                    isSearchable={true}
                    placeholder={'효능을 선택해주세요.'}
                />
            </td>
        </tr>
        </>
    )
}