import React, { useEffect, useRef, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Paginate } from "../../../../../components/paginate/paginate_admin";
import { PaginateUser } from "../../../../../components/paginate/paginate_user";
import { useGetProductPermissionHistoryMutation } from "../../../../../store/admin/adminContentsApiSlice";
import { useGetProductPermissionHistoryByUserMutation, useUpdProductPermissionViewCntMutation } from "../../../../../store/user/contentsApiSlice";
import { getCookie } from "../../../../../util/cookie/cookies";
import { SearchBox } from "./searchBox";

export const PermitChange = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const navigate = useNavigate()
    const path = location.pathname.split('/')
    const searchParam = new URLSearchParams(location.search)
    const page:any = searchParam.get('page') || 1
    const keyword = searchParam.get('keyword') || ''
    const searchType = searchParam.get('type') || 'T'
    const [getProductPermissionHistoryApi] = useGetProductPermissionHistoryByUserMutation();
    const [updProductPermissionViewCntApi] = useUpdProductPermissionViewCntMutation()
    const permissionClass = path[3] === 'permitChange' ? 'P' : 'O';
    const [data, setData] = useState<any>([])
    const [totalCount, setTotalCount] = useState<number>(0)
    // const [calculateIndex, setCalculateIndex] = useState<any>([])
    const [param, setParam] = useState<any>({user : 'U', userlang : 'KR', permissionClass : permissionClass, page : page, size : 10, keyword : keyword, searchType : searchType, sortColumn : 'date', sortOrder : 'desc'})
    const getList = async() => {
        const result : any = await getProductPermissionHistoryApi(param);
        if(result.data.Result === 'true'){setData(result.data.List); setTotalCount(result.data.totalCnt)}
    }
    const handlePage = (pageNumber:number) => {navigate(`/syntheticMedicine/productAnnouncement/${path[3]}?page=${pageNumber}&keyword=${keyword}&type=${searchType}`)}
    const handleTransView = async(id : string) => {
        const formData:any = new FormData()
        formData.append('idx', id)
        const result : any = await updProductPermissionViewCntApi(formData)
        if(result){
            navigate(`${location.pathname}/${id}`); 
        }
    }
    const calculateIndex = (currentPage:any, pageSize:number, totalCount:number, itemIndex:number) => {
        const lastIndex = totalCount - (currentPage - 1) * pageSize;
        return lastIndex - itemIndex;
    };
    useEffect(()=>{setParam((prev:any) => ({...prev, permissionClass : permissionClass}))}, [path[3]])
    useEffect(()=>{setParam((prev:any)=>({...prev, page : page, keyword : keyword, searchType : searchType}))}, [page, keyword, searchType])
    useEffect(()=>{getList()}, [location, param.page, param.keyword, param.searchType])
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    {path[3] === 'permitChange' ? <span>허가 변경사항</span> : ''}
                    {path[3] === 'obesityTreatmentMedicine' ? <span>비만치료 약물</span> : ''}
                </h3>

                <SearchBox/>
                
                <div className="tableWrap">
                    <table>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>제목</th>
                                <th>작성일</th>
                                <th>조회수</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((list:any, index : number) => {
                                return(
                                <tr key={list?.idx} onClick={()=>handleTransView(list?.idx)}>
                                    <td>{calculateIndex(page, param.size, totalCount, index)}</td>
                                    <td>
                                        <p>{lang==='Kr'?list?.permissionSubjectKr:list?.permissionSubjectEn}</p>
                                    </td>
                                    <td>{list?.permissionMdate}</td>
                                    <td>{list?.permissionViewCnt}</td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

                <div className="pagerBox">
                    <Paginate page={page} setPage={handlePage} totalCount={totalCount} size={10}/>
                </div>
            </div>

        </div>
        </>
    )
}