import React, { useCallback, useRef, useState } from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const ManufacturingFacility = () => {
    const slickRef = useRef<any>(null)
    const prev = useCallback(()=>slickRef.current.slickPrev(), [])
    const next = useCallback(()=>slickRef.current.slickNext(), [])
    const [currentSlide, setCurrentSlide] = useState<number>(0)

    const setting = {
        dots : false,
        arrows : false,
        infinte : false,
        speed : 500,
        slidesToScroll: 1,
        slidesToShow: 1,
        vertical: false,
        autoplay: true,
        autoplaySpeed: 3000,
        beforeChange : (current : number, next : number) =>
        setCurrentSlide(next)
        // centerMode: true,
    }
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    Probiotics <span>제조시설</span>
                </h3>
                <p><strong>바이넥스는 원료의약품 제조 시설과 프로바이오틱스 전용 완제의약품 제조 시설을 운영하고 있습니다.</strong></p>
                <p>
                    원료부터 완제품 제조 및 품질관리까지 가능한 바이넥스는 최신의 GMP규정에 적합한<br></br>
                    프로바이오틱스 관련 품질관리 장비를 구축하고 있으며,
                    임상용을 비롯한 상업용 제품 생산까지 가능합니다.
                </p>
                <div className="sliderWrap">
                    <ul>
                    <Slider {...setting} ref={slickRef}>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics1.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics2.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics3.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics4.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics5.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics6.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics7.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                        <li>
                            <div className="imgBox">
                                <img src={`/assets/images/manufacturing_facility_probiotics8.png`} alt="프로바이오틱스 제조시설"/>
                            </div>
                        </li>
                    </Slider>
                    </ul>
                    {/* slider Fin */}
                    <div className="btnBox">
                        <button className="prevBtn" onClick={prev}></button>
                        <button className="nextBtn" onClick={next}></button>
                    </div>
                    <div className="sliderPagerBox">
                        <span className="now">{currentSlide+1}</span>
                        <span>/</span>
                        <span>8</span>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}

const picNumber = [1,2,3,4,5,6,7,8]
