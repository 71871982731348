import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDelManagerMutation, useGetManagersMutation } from "../../../../../store/admin/adminstrators";

export const AdminStratorsList = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const page = searchParam.get('page')||1;
    const sort = searchParam.get('sortOrder')||'asc';
    const column = searchParam.get('sortColumn')||'manager_login';
    const [getAdminApi] = useGetManagersMutation()
    const [delManagerApi] = useDelManagerMutation()
    const [data, setData] = useState<any>([])
    const [param, setParam] = useState<any>({page:page, size : 10, sortOrder: sort, sortColumn:column, keyword : ''})
    const [totalCount, setTotalCount] = useState<number>(0)
    const getUsers = async() => {
        const result : any = await getAdminApi(param)
        if(result.data.result === true) {setData(result.data.list); setTotalCount(result.data.total_cnt)}
    }
    const [sortOtion, setSortOption] = useState<any>({column : 'manager_login', order : 'asc'})
    const handleSortOrder = (column:string) => {
        if(column !== ''){
            if(sortOtion.column === column){setSortOption((prev:any) => ({...prev, order : sortOtion.order === 'desc' ? 'asc' : 'desc'}))}
            else {
                setSortOption((prev:any) => ({...prev, column : column, order : 'desc'}))
            }
        }
    }
    const handlePage = (pageNumber : number) => {navigate(`/dotsAdmin/setting/adminstrator?page=${pageNumber}&sortOrder=${sort}&sortColumn=${column}`)}
    const handleSort = (order:string, column:string) => {navigate(`/dotsAdmin/setting/adminstrator?page=${page}&sortOrder=${order}&sortColumn=${column}`)}
    const handleAdminView = (id:string) => {navigate(`/dotsAdmin/setting/adminstrator/${id}`)}
    const handleDelAdmin = async(id:string) =>{
        const confirm = window.confirm("해당 관리자를 삭제하시겠습니까?");
        if(confirm){
            const result : any = await delManagerApi({id : id});
            if(result.data.result===true){getUsers()}
        }
    }
    useEffect(()=>{handleSort(sortOtion.order, sortOtion.column)},[sortOtion.order, sortOtion.column])
    useEffect(()=>{setParam((prev:any)=>({...prev, page : page, sortOrder: sort, sortColumn : column}))}, [page, sort, column])
    useEffect(()=>{getUsers()},[param.page, param.sortOrder, param.sortColumn])
    return(
        <>
         <div className="contentBox administrator">
            <h3>Setting</h3>
            <div className="flexWrap">
                <h4>
                    Administrator
                    <span className="content_count">{totalCount}</span>
                </h4>
            </div>
            
            <table className="table_hover">
                <thead>
                    <tr>
                        {adminFilter?.map((admin : any) => {
                            const isSortDisplay = admin.column === '' ? '' : 'sort'
                            return(
                                <>
                                <th>
                                    <span className="sortWrap" onClick={()=>handleSortOrder(admin.column)}>
                                        {admin.title}
                                        {sortOtion.column === admin.column ?
                                        <span className={`sort ${sortOtion.order === 'desc' ? 'desc' : 'asc'}`}></span>
                                        :<span className={isSortDisplay}></span>
                                    }
                                    </span>
                                </th>
                                </>
                            )
                        })}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((list:any) => (
                    <tr key={list?.ID}>
                        <td onClick={()=>handleAdminView(list?.ID)}>{list?.manager_name}</td>
                        <td onClick={()=>handleAdminView(list?.ID)}>{list.manager_login}</td>
                        <td onClick={()=>handleAdminView(list?.ID)}>{list.manager_mobile}</td>
                        <td onClick={()=>handleAdminView(list?.ID)}>{list.manager_phone}</td>
                        <td onClick={()=>handleAdminView(list?.ID)}>{list.manager_email}</td>
                        <td onClick={()=>handleDelAdmin(list?.ID)}><button>삭제</button></td>
                    </tr>
                    ))}
                </tbody>
            </table>
            <button className="addNewBtn" onClick={()=>navigate('/dotsAdmin/setting/adminstrator/regist')}>Add New</button>
            {/* <Paginate page={1} setPage={handlePage} totalCount={totalCount} size={param.size}/> */}
        </div>
        </>
    )
}

const adminFilter = [{idx: 1, title : '관리자 이름', column : 'manager_name'}, {idx: 2, title : '아이디', column : 'manager_login'}, {idx: 3, title : '연락처', column : 'manager_mobile'},
{idx: 4, title : '휴대전화', column : 'manager_phone'}, {idx: 5, title : '이메일', column : 'manager_email'}, {idx: 6, title : '', column : ''}]