import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/user";

export const contentsApiSlice = createApi({
  reducerPath: "contentsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 유저 제품 리스트 (효능별)
    getProductByUser : builder.mutation({
      query : (param : {user : string, searchLang : string, searchChar : string, efficacy : string, page : any, size : 10, keyword : '', sortOrder : '', sortColumn : ''}) => {
        return{
          url : `/getProductByUser.php?user=${param.user}&searchLang=${param.searchLang}&searchChar=${param.searchChar}&efficacy=${param.efficacy}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortOrder=${param.sortOrder}&sortColumn=${param.sortColumn}`
        }
      }
    }),
    // 제품허가변경사항 및 비만치료 가져오기
    getProductPermissionHistoryByUser : builder.mutation({
      query  :(param : {user : string, userlang : string, permissionClass : string, page : any, size : number, keyword : string, sortColumn:string,sortOrder:string, searchType : string}) => {
        return{
          url : `/getProductPermissionHistoryByUser.php?user=${param.user}&userlang=${param.userlang}&permissionClass=${param.permissionClass}&page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}&searchType=${param.searchType}`
        }
      }
    }),
    // 제품허가변경사항 조회수 증가
    updProductPermissionViewCnt : builder.mutation({
      query : (body : {idx: string}) => {
        return{
          url : '/updProductPermissionViewCnt.php' , method : 'post' , body
        }
      }
    }),
    // 전자공고 리스트 조회
    getFinanceSettlementByUser : builder.mutation({
      query : (param : {page : any, size : number, useLang : string,}) =>{
        return{
          url : `/getFinanceSettlementByUser.php?page=${param.page}&size=${param.size}&useLang=${param.useLang}`
        }
      }
    }),
     // 전자공고 상세보기
     getFinanceSettlementDetail : builder.mutation({
      query : (param : {id : string}) => {
        return{
          url : `/getFinanceSettlementDetail.php?idx=${param.id}`
        }
      }
    }),
    // 전자공고 조회수 증가
    updFinanceSettlementViewCnt : builder.mutation({
      query : (body : any) => {
        return{
          url : '/updFinanceSettlementViewCnt.php', method : 'post', body
        }
      }
    }),
    // 주식정보 불러오기 (일자별시세)
    getStockInfomation : builder.mutation({
      query : (param : any) => {
        return{
          url : 'https://asp.koscom.co.kr/listservice/getStockHist?code=053030&auth_key=raLyfjSogQBrp0lwuJqR4h7g3cdYasI&gubun=K&count=10'
        }
      }
    }),
    // 주식정보 불러오기 (일반정보)
    getStockInforation2 : builder.mutation({
      query : (param : any) => {
        return{
          url : 'https://asp.koscom.co.kr/listservice/getStockBasic?code=053030&auth_key=raLyfjSogQBrp0lwuJqR4h7g3cdYasI&gubun=K&count=10'
        }
      }
    }),
    // 제보하기 
    setWhistleblow : builder.mutation({
      query : (body: any) => {
        return{
          url  :'/setWhistleBlow.php', method : 'post' , body
        }
      }
    }),

    // 문의하기
    setInquiry : builder.mutation({
      query : (body : any) => {
        return{
          url  : '/inquiry/setInquirys.php', method : 'post', body
        }
      }
    }),
    // 홍보센터 리스트
    getUserPromotionList : builder.mutation({
      query : (param : {page : any, size : number, keyword : string, keywordClass : string, column : string, order : string, lang : any}) => {
        return{
          url : `/promotion/getPromotionList.php?promLang=${param.lang}&page=${param.page}&size=${param.size}&keyword=${param.keyword}&keywordClass=${param.keywordClass}&sortColumn=${param.column}&sortOrder=${param.order}`
        }
      }
    }),
    // 홍보센터 상세보기
    getUserPromotionDetail : builder.mutation({
      query : (param : {id : any, lang : any}) => {
        return{
          url : `/promotion/getPromotionDetail.php?promLang=${param.lang}&promId=${param.id}`
        }
      }
    })
  }),
});

export const {
  useSetWhistleblowMutation , useGetProductByUserMutation, useSetInquiryMutation, useGetProductPermissionHistoryByUserMutation, useUpdProductPermissionViewCntMutation, useGetFinanceSettlementByUserMutation, useGetFinanceSettlementDetailMutation,
  useUpdFinanceSettlementViewCntMutation, useGetStockInfomationMutation, useGetStockInforation2Mutation, useGetUserPromotionListMutation, useGetUserPromotionDetailMutation
} = contentsApiSlice;