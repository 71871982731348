import React from "react";
import { useTranslation } from "react-i18next";

export const Vision = () => {
    const {t} = useTranslation()
    return(<>
        <div className="contentBox">
            <div>
                <h3>{t("vision_title_1")} <span>{t("vision_title_2")}</span></h3>
                <div>
                    <p>
                        <strong>From Bio Base Camp To Global Bio HUB</strong>
                    </p>
                </div>
                <p>
                {t("vision_content_1")} <br></br>
                {t("vision_content_1_2")}
                </p>
                <p>
                {t("vision_content_2")}
                </p>
            </div>
        </div>
    </>)
}