import React from "react";
import { useTranslation } from "react-i18next";

export const OverVeiw = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>{t("overview_title_1")}<span>{t("overview_title_2")}</span></h3>
                <ul>
                    <li>
                        <span>{t("overview_sub_title_1")}</span>
                        <span>1985.06.04</span>
                    </li>
                    <li>
                        <span>{t("overview_sub_title_2")}</span>
                        <span>{t("overview_content_1")}</span>
                    </li>
                    <li>
                        <span>{t("overview_sub_title_3")}</span>
                        <span>
                        {t("overview_content_2")}<br/>
                        {t("overview_content_3")}
                        </span>
                    </li>
                    <li>
                        <span>{t("overview_sub_title_4")}</span>
                        <span>
                        {t("overview_content_4")}
                        </span>
                    </li>
                </ul>
                <p>
                {t("overview_content_5")}
                </p>
            </div>
        </div>  
        </>
    )
}