import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { CheckBox_Admin } from "../../../../../../components/checkBox/admin_checkBox";
import { Paginate } from "../../../../../../components/paginate/paginate_admin";
import { useGetProductMutation } from "../../../../../../store/admin/adminContentsApiSlice";
import { ListFilter } from "../list-element/list-filter";
import { ListHeader } from "../list-element/list-header";
export interface Props {data:any; getList:any}
export const Product:React.FC<Props> = ({data,getList}) => {
    const navigate = useNavigate()
    return(
        <>
        
        <tbody>
            {data?.map((list:any) => (
                <tr className="active" key={list?.idx}>
                    
                    <td onClick={()=>navigate(`/dotsAdmin/products/product/${list?.idx}`)}>
                        <img src={`${list?.productImageFile}`} alt="모델링 썸네일 이미지"/>
                    </td>

                    <td onClick={()=>navigate(`/dotsAdmin/products/product/${list?.idx}`)}>{list?.productNameKr}</td>
                    
                    <td onClick={()=>navigate(`/dotsAdmin/products/product/${list?.idx}`)}>{list?.productMdate}</td>

                    <CheckBox_Admin idx={list?.idx} openYn={list?.productActiveStatus} getList={getList}/>

                </tr>
            ))}
        </tbody>
           
        </>
    )
}