import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const ListFilterReport = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    const currentDate = `${year}-${month}-${day}`;
    const searchParam = new URLSearchParams(location.search)
    const workplace = searchParam.get('workplace')||'';
    const blowType = searchParam.get('whistleblowType')||'';
    const blowStatus = searchParam.get('blowStatus') || '';
    const startDate = searchParam.get('whistleBlowFromMdate')||'2024-01-01';
    const endDate = searchParam.get('whistleBlowToMdate')||currentDate;
    const [type, setType] = useState<string>(blowType)
    const [place, setPlace] = useState<string>(workplace)
    const [status, setStatus] = useState<string>(blowStatus)
    const [sDate, setSdate] = useState<string>(startDate)
    const [eDate, setEdate] = useState<string>(endDate)
    const handleSearch = () => {
        navigate(`/dotsAdmin/reporting/report?workplace=${place}&whistleblowType=${type}&blowStatus=${status}&whistleBlowFromMdate=${sDate}&whistleBlowToMdate=${eDate}&page=1&size=10&sortColumn=date&sortOrder=desc&keyword=`)
    }
    return(
        <>
        <div className="grayBox">
            <div>
                <span>제보유형</span>
                <div>
                    <div className="" onClick={()=>setType('')}>
                        <input type="radio" name="name" id="typeAll" checked={type === ''}/>
                        <label htmlFor="typeAll">전체</label>
                    </div>
                    <div className="" onClick={()=>setType('A')}>
                        <input type="radio" name="name" id="anonymous" checked={type === 'A'}/>
                        <label htmlFor="anonymous">익명</label>
                    </div>
                    <div className="" onClick={()=>setType('R')}>
                        <input type="radio" name="name" id="realName" checked={type === 'R'}/>
                        <label htmlFor="realName">실명</label>
                    </div>
                </div>
            </div>
            <div>
                <span>제보사업장</span>
                <div>
                    <div onClick={()=>setPlace('')}>
                        <input type="radio" name="factory" id="placeAll" checked={place === ''}/>
                        <label htmlFor="placeAll">전체</label>
                    </div>
                    <div onClick={()=>setPlace('O')}>
                        <input type="radio" name="factory" id="osong" checked={place === 'O'}/>
                        <label htmlFor="osong">오송공장</label>
                    </div>
                    <div onClick={()=>setPlace('S')}>
                        <input type="radio" name="factory" id="songdo" checked={place === 'S'}/>
                        <label htmlFor="songdo">송도공장</label>
                    </div>
                    <div onClick={()=>setPlace('B')}>
                        <input type="radio" name="factory" id="busan" checked={place === 'B'}/>
                        <label htmlFor="busan">부산공장</label>
                    </div>
                </div>
            </div>
            <div>
                <span>처리상태</span>
                <div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('')} checked={status === ''} name="" id="statsAll" />
                        <label htmlFor="statsAll">전체</label>
                    </div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('W')} checked={status === 'W'} name="" id="W" />
                        <label htmlFor="W">접수대기</label>
                    </div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('R')} checked={status === 'R'} name="" id="R" />
                        <label htmlFor="R">접수</label>
                    </div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('I')} checked={status === 'I'} name="" id="I" />
                        <label htmlFor="I">조사중</label>
                    </div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('C')} checked={status === 'C'} name="" id="C" />
                        <label htmlFor="C">완료</label>
                    </div>
                    <div>
                        <input type="radio" onChange={()=>setStatus('B')} checked={status === 'B'} name="" id="B" />
                        <label htmlFor="B">반려</label>
                    </div>
                </div>
            </div>
            <div>
                <span>등록일자 기간설정</span>
                <div className="dateBox">
                    <div className="input_date_box">
                        <input type="date" name="" onChange={(e)=>setSdate(e.target.value)} value={sDate} id="date"/>
                    </div>
                    <div className="input_date_box">
                        <input type="date" name="" onChange={(e)=>setEdate(e.target.value)} value={eDate} id="date"/>
                    </div>
                    <button onClick={handleSearch}>조회</button>
                </div>
            </div>
        </div>
        </>
    )
}