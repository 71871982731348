import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../../../util/cookie/cookies";

export const ReportingGuide = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    바이넥스는 투명하고 윤리적인 기업으로 도약하기 위해
                    <span>윤리강령에 어긋나는 비리 사항에 대해 제보를 받고 있습니다.</span>
                </h3>
                : 
                <h3>Binex is committed to transparency and ethical conduct, and therefore,<span>we encourage the reporting of any misconduct that violates our code of ethics</span></h3>
                }

                <dl>
                    <dt>{t("report_guide_title_1")}</dt>
                    <dd>{t("report_guide_content_1")}</dd>
                    <dd>{t("report_guide_content_2")}</dd>
                </dl>
                <dl>
                    <dt>{t("report_guide_title_2")}</dt>
                    <dd>{t("report_guide_content_3")}</dd>
                    <dd>{t("report_guide_content_4")}</dd>
                </dl>
                <dl>
                    <dt>{t("report_guide_title_3")}</dt>
                    <dd>
                    {t("report_guide_content_5")}<br/>
                    {t("report_guide_content_6")}
                    </dd>
                    <dd>{t("report_guide_content_7")}</dd>
                    <dd>{t("report_guide_content_8")}</dd>
                </dl>

                <div className="linkBox">
                    <div className="whistleblower_protection_box" onClick={()=>navigate('/ethical/reportingAndprocedure/informantProtection')}>
                        <div>
                            <span>{t("report_guide_title_4")}</span>
                            <span>{t("report_guide_title_5")}</span>
                        </div>
                    </div>
                    <div className="report_box" onClick={()=>navigate('/ethical/reportingAndprocedure/report')}>
                        <div>
                            <span>{t("report_guide_title_6")}</span>
                            <span>{t("report_guide_title_7")}</span>
                        </div>
                    </div>
                    <div className="processing_procedure_box" onClick={()=>navigate('/ethical/reportingAndprocedure/processingProcedure')}>
                        <div>
                            <span>{t("report_guide_title_8")}</span>
                            <span>{t("report_guide_title_9")}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}