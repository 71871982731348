import React from "react";
import { useTranslation } from "react-i18next";

export const AnalyticalDevelop = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="content_3 active">
            <h4>{t("cdo_tab_3")}</h4>
            <p>{t("cdo_analytical_title")}</p>

            <div className="flexBox">
                <div className="left">
                    <ul className="checkList">
                        <li>{t("cdo_analytical_content_1")}</li>
                        <li>{t("cdo_analytical_content_2")}</li>
                        <li>{t("cdo_analytical_content_3")}</li>
                        <li>{t("cdo_analytical_content_4")}</li>
                    </ul>

                    {/* <dl>
                        <dt>{t("cdo_analytical_content_1")}</dt>
                        <dd>
                            Western blot, DNA agarose-gel electrophoresis, SDS-PAGE,<br></br>
                            CE-SDS, IEF-gel electrophoresis, icIEF/cIEF
                        </dd>
                    </dl>
                    <dl>
                        <dt>{t("cdo_analytical_content_2")}</dt>
                        <dd>HPLC(SE, HI, IEX, RP, Affinity), UPLC(SE, RP)</dd>
                    </dl>
                    <dl>
                        <dt>{t("cdo_analytical_content_3")}</dt>
                        <dd>Ligand binding ELISA, Cell based assay</dd>
                    </dl>
                    <dl>
                        <dt>{t("cdo_analytical_content_4")}</dt>
                        <dd>HCP ELISA, Residual protein A, HCD(qPCR), Endotoxin</dd>
                    </dl>
                    <dl>
                        <dt>{t("cdo_analytical_content_5")}</dt>
                        <dd>UV(A280)</dd>
                    </dl> */}
                </div>
                <div className="imgBox right">
                    <img src="/assets/images/analytical_method_development.png" alt="분석법 개발"/>
                </div>
            </div>

            <div className="tableWrap">
                <table>
                    <thead>
                        <tr>
                            <th>{t("cdo_analytical_th_1")}</th>
                            <th>{t("cdo_analytical_th_2")}</th>
                            <th>{t("cdo_analytical_th_3")}</th>
                            <th>{t("cdo_analytical_th_4")}</th>
                            <th>{t("cdo_analytical_th_5")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ul>
                                    <li>Western blot</li>
                                    <li>
                                        DNA agarose-gel<br></br>
                                        electrophoresis
                                    </li>
                                    <li>SDS-PAGE</li>
                                    <li>CE-SDS</li>
                                    <li>IEF-gel electrophoresis</li>
                                    <li>icIEF/cIEF</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>
                                        HPLC (SE, HI, IEX, RP, 
                                        Affinity)
                                        </li>
                                    <li>UPLC (SE, RP)</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>Binding ELISA</li>
                                    <li>Cell based assay</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>HCP (ELISA)</li>
                                    <li>HCD (qPCR)</li>
                                    <li>Residual Protein A</li>
                                    <li>Endotoxin</li>
                                </ul>
                            </td>
                            <td>
                                <ul>
                                    <li>UV(A<sub>280</sub>)</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            
            <div>
                <h5>Service Offerings</h5>
                
                <ul className="listBox">
                    <li>
                        <div>
                            <img src="/assets/images/method_development.png" alt="Services offerings"/>
                        </div>
                        <div>
                            <span>
                                Method<br></br>
                                Development
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/method_qualification.png" alt="Services offerings"/>
                        </div>
                        <div>
                            <span>
                                Method<br></br>
                                Qualification/Validation
                            </span>
                        </div>
                    </li>
                    <li>
                        <div>
                            <img src="/assets/images/analytical_support.png" alt="Services offerings"/>
                        </div>
                        <div>
                            <span>
                                Analytical<br></br>
                                Support
                            </span>
                        </div>
                    </li>
                </ul>

            </div>
        </div>
        </>
    )
}