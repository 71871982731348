import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin/manager";

export const adminStratorsApiSlice = createApi({
  reducerPath: "adminStratorsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 관리자 로그인
    getManagerLogin : builder.mutation({
      query : (body : any) => {
        return{
          url : `/getManagerLogin.php` , method : 'post' , body
        }
      }
    }),
    // 관리자 정보호출
    getAdminInfo : builder.mutation({
      query : (param : {id: string}) => {
        return{
          url : `/getAdminInfo.php?ID=${param.id}`
        }
      }
    }),
    // 관리자 리스트 불러오기
    getManagers : builder.mutation({
      query: (param : {page:any, size:number, keyword : string, sortColumn : string, sortOrder : string}) => {
        return{
          url : `/getManagers.php?page=${param.page}&size=${param.size}&keyword=${param.keyword}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}`
        }
      }
    }),
    // 관리자 상세보기
    getAdminDetail : builder.mutation({
      query : (param : {id: string}) => {
        return{
          url  : `/getManagerDetails.php?ID=${param.id}`
        }
      }
    }),
    // 관리자 등록
    setManager : builder.mutation({
      query : (body : any) => {
        return{
          url : '/setManagers.php', method : 'post', body
        }
      }
    }),
    //관리자 삭제
    delManager : builder.mutation({
      query : (param : {id : string}) => {
        return{
          url : `/delManagers.php?ID=${param.id}`
        }
      }
    }),
    // 관리자 수정
    uptManager : builder.mutation({
      query : (body : any) => {
        return{
          url : '/uptManagers.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetManagerLoginMutation, useSetManagerMutation, useGetManagersMutation, useDelManagerMutation, useGetAdminDetailMutation, useUptManagerMutation, useGetAdminInfoMutation
} = adminStratorsApiSlice;