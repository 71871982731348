import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../../util/cookie/cookies";
import { header_binex, header_binex_about, header_biomedicine_cdo, header_biomedicine_cmo, header_ehthical_1, header_ehthical_2, header_invest, header_syntheticMedicine_1, header_syntheticMedicine_2 } from "../../../../data/headerData";
import { Lang } from "./lang";
import { third_syntheticMedicineItem_1 } from "../../../../data/navBoxData";

const Header_Pc = () => {
    const { t } = useTranslation();
    const location = useLocation()
    const langBoxRef = useRef<any>(null)
    const lang = getCookie('BINEXLANG')
    const [state, setState] = useState<boolean>(false)
    const [megaMenu, setMegaMenu] = useState<boolean>(false)
    const navigate = useNavigate()
    const handleTransBinex = (type : string, mainPath : string, subPath : string, path : string) => {
        if(type==='second'){navigate(`/${mainPath}/${path}`)}
        else {navigate(`/${mainPath}/${subPath}/${path}`)}
        setMegaMenu(false)
    }
    useEffect(()=>{
        const handleOutSide = (e:any) =>{if(langBoxRef.current&&!langBoxRef.current.contains(e.target)){setState(false);}}
        document.addEventListener('mousedown', handleOutSide);
        return()=> {document.removeEventListener('mousedown', handleOutSide)}
    })
    useEffect(()=>{setMegaMenu(false)}, [location])
    return(
        <>
        <div className="header_pc">
            <h1>
                <span onClick={()=>navigate('/')} style={{cursor: 'pointer'}}>
                    logo
                </span>
            </h1>

            <div>
                <nav className={megaMenu?'active':''}>
                    <ul onMouseOver={()=>setMegaMenu(true)} onMouseLeave={()=>setMegaMenu(false)}>
                        <li>
                            <span>{lang==='Kr' ? '바이넥스':'BINEX'}</span>
                        </li>
                        <li>
                            <span>{lang==='Kr' ? '바이오의약품 CDMO':'Biologics CDMO'}</span>
                        </li>
                        <li>
                            <span>{lang==='Kr' ? '합성의약품':'Chemical'}</span>
                        </li>
                        <li>
                            <span>{lang==='Kr' ? '투자정보':'Investment'}</span>
                        </li>
                        <li>
                            <span>{lang==='Kr' ? '윤리경영':'Ethical Management'}</span>
                        </li>
                        <li>
                            <span>{lang==='Kr' ? '채용정보':'Recruitment'}</span>
                        </li>
                    </ul>
                    <div className="megaMenu">
                        <div>
                            <div>leftArea</div>
                            <div>
                                <ul className="nav" onMouseOver={()=>setMegaMenu(true)} onMouseLeave={()=>setMegaMenu(false)}>
                                    <li>
                                        <ul>
                                            <li>
                                                <span onClick={()=>navigate('/binex/about/overView')}>{lang==='Kr' ? '회사소개':'Company'}</span>
                                                <ul>
                                                    {header_binex_about.map((x:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'binex', 'about', x.path)}>
                                                            {lang==='Kr'? x.title_kr:x.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            {header_binex.map((x:{title_kr:string;title_en:string;path:string;},index:number)=>(
                                            <li key={index} onClick={()=>handleTransBinex('second','binex', '', x.path)}>
                                                <span>{lang==='Kr'? x.title_kr:x.title_en}</span>
                                            </li>
                                            ))}
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <span onClick={()=>handleTransBinex('second', 'bioMedicine', '', 'bussinessOverview')}>{lang==='Kr' ? 'CDMO 사업개요':'CDMO Business'}</span>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate('/bioMedicine/cdmoservice?tab=1')}>CDO</span>
                                                <ul>
                                                    {header_biomedicine_cdo.map((y:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li 
                                                            key={index} 
                                                            onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=${y.path}`)}
                                                        >
                                                            <div dangerouslySetInnerHTML={{__html : lang==='Kr'? y.title_kr:y.title_en}}></div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate('/bioMedicine/cdmoproduction?tab=1')}>CMO</span>
                                                <ul>
                                                    {header_biomedicine_cmo.map((y:{title_kr:string;title_en:string;path:string}, index:number) => (
                                                        <li key={index} onClick={()=>navigate(`/bioMedicine/cdmoproduction?tab=${y.path}`)}>
                                                            {lang==='Kr'? y.title_kr:y.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>Quality</span>
                                                <ul>
                                                    <li onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>
                                                        {lang==='Kr'? '품질시스템':'Quality System'}
                                                    </li>
                                                    <li onClick={()=>navigate(`/bioMedicine/quailty?tab=2`)}>
                                                        {lang==='Kr'? '품질관리':'Quality Control'}
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'search', 'productname')}>
                                                <span>{lang==='Kr' ? '제품검색':'Products'}</span>
                                            </li>
                                            {lang === 'Kr' ? 
                                            <>
                                            <li>
                                                <span onClick={()=>navigate('/syntheticMedicine/productAnnouncement/permitChange')}>제품 공지사항</span>
                                                <ul>
                                                    {header_syntheticMedicine_1.map((z:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'productAnnouncement', z.path)}>
                                                            {lang==='Kr'? z.title_kr:z.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate('/syntheticMedicine/probiitics/manufacturingFacility')}>Probiotics</span>
                                                <ul>
                                                    {header_syntheticMedicine_2.map((z:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'probiitics', z.path)}>
                                                            {lang==='Kr'? z.title_kr:z.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li onClick={()=>handleTransBinex('second', 'syntheticMedicine', '', 'busanFactory')}>
                                                <span>제조시설(부산공장)</span>
                                            </li></>
                                            : ''
                                            }
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            {lang === 'Kr' ?
                                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'electronicDisclosure')}>
                                                <span>{lang==='Kr' ? '전자공시':'Electronic Disclosure'}</span>
                                            </li>
                                            : ''
                                            }
                                            {lang === 'Kr' ?
                                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'electronicNotice')}>
                                                <span>{lang==='Kr' ? '전자공고':'Electric Notice'}</span>
                                            </li>
                                            : ''
                                            }
                                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'financialInformation')}>
                                                <span>{lang==='Kr' ? '재무정보':'Financial Information'}</span>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate('/invest/stockInformation/stockPrice')}>{lang==='Kr' ? '주식정보':'Stock Information'}</span>
                                                <ul>
                                                    {header_invest.map((a:{title_kr:string,title_en:string,path:string},index:number) => (
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'invest', 'stockInformation', a.path)}>
                                                            {lang==='Kr' ? a.title_kr : a.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'internalInformationManagement')}>
                                                <span>{lang==='Kr' ? '내부정보관리규정':'Internal Information Control Regulation'}</span>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li>
                                                <span onClick={()=>navigate('/ethical/codeOfehicsAndimplement/codeOfethics')}>{lang==='Kr' ? '윤리강령 및 실천지침':'Code of Ethics and Pratice Guidelines'}</span>
                                                <ul>
                                                    {header_ehthical_1.map((a:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'ethical', 'codeOfehicsAndimplement', a.path)}>
                                                            {lang==='Kr'? a.title_kr:a.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                            <li>
                                                <span onClick={()=>navigate('/ethical/reportingAndprocedure/reportGuide')}>{lang==='Kr' ? '제보안내 및 절차':'Reporting and Process'}</span>
                                                <ul>
                                                    {header_ehthical_2.map((a:{title_kr:string;title_en:string;path:string},index:number)=>(
                                                        <li key={index} onClick={()=>handleTransBinex('third', 'ethical', 'reportingAndprocedure', a.path)}>
                                                            {lang==='Kr'? a.title_kr:a.title_en}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        <ul>
                                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'idealTalent')}>
                                                <span>{lang==='Kr' ? '인재상':'Ideal Candidate'}</span>
                                            </li>
                                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'benefits')}>
                                                <span>{lang==='Kr' ? '복리후생':'Benefits'}</span>
                                            </li>
                                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'employment')}>
                                                <span>{lang==='Kr' ? '채용안내':'Careers'}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                                <div>rightArea</div>
                            </div>
                        </div>
                    </div>
                </nav>
                <div className="rightBox">
                    <button className="contactUsBtn" onClick={()=>navigate('/contactUs')}>{lang==='Kr' ? '문의하기':'Inquiry'}</button>
                    <div className="langBox" ref={langBoxRef}>
                        <button className={state?'active':''} onClick={()=>setState(prev=>!prev)}>
                            {lang==='Kr' ? 'KOR' : 'ENG'}
                        </button>
                        <Lang type={'pc'} setState={setState}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Header_Pc;