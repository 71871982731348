import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AnonymousReport } from "./reportType/anonymous";
import { RealNameReport } from "./reportType/realNameReport";
import { getCookie } from "../../../../../util/cookie/cookies";

export const ReportIt = () => {
    const {t} = useTranslation()
    const [state, setState] = useState<{isOpen:boolean,type:string}>({isOpen : false, type: 'R'})
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    투명하고 공정한 윤리 기업문화 실현을 위한
                    <span> 임직원들의 협조에 감사드립니다.</span>
                </h3>
                : 
                <h3>
                    <span> We express our gratitude for the cooperation of our employees </span>in achieving a transparent and fair ethical corporate culture
                </h3>
                }

                
                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>setState((prev)=>({...prev, isOpen : false, type : 'R'}))}>
                            <button className={!state.isOpen?'active':''}>{t("report_reporting_tab_1")}</button>
                        </div>
                        <div onClick={()=>setState((prev)=>({...prev, isOpen : true, type : 'A'}))}>
                            <button className={state.isOpen?'active':''}>{t("report_reporting_tab_2")}</button>
                        </div>
                    </div>
                </div>
                <div className="noticeBox">
                    <div>
                        <div>
                            <div>
                                <p>* {t("report_reporting_sub_1")}</p>
                                <p>* {t("report_reporting_sub_2")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tabContentBox">
                    {state.type === 'R' ? <RealNameReport/> : ''} 
                    {state.type === 'A' ? <AnonymousReport/> : ''} 
                </div>
            </div>
        </div>
        </>
    )
}