import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCookie } from "../../../util/cookie/cookies";
import { Main } from "../main/main";
import { SiteMap } from "../siteMap/siteMap";
import { Binex } from "../subPage/binex";
import { BioMedicineCDMO } from "../subPage/bioMedicineCDMO";
import { ContactUs } from "../subPage/contactUs";
import { EthicalManagement } from "../subPage/ethicalManagement";
import { InvestInfomation } from "../subPage/invest";
import { RecruitmentInformation } from "../subPage/recruitmentInformation";
import { SyntheticMedicine } from "../subPage/syntheticMedicine";
import Footer from "./footer/footer";
import Header from "./header/header";
import Header_Pc from "./header/pc";
import { Privacy } from "../subPage/privacy";


const LayOut = () => {
    const {secondUrl, thirdUrl,id} : any = useParams()
    const navi = useNavigate()
    const location = useLocation()
    const path = location.pathname.split('/')[1];
    const lang = getCookie('BINEXLANG')
    return(
        <>
        <div className={lang==='Kr'?"user_wrap kr":"user_wrap en"}>
            
            <Header/>
            {location.pathname === '/' ? <Main/> : ''}
            {location.pathname === '/siteMap' ? <SiteMap/> : ''}
            {location.pathname === '/contactUs' ? <ContactUs/> : ''}
            {location.pathname === `/binex/${secondUrl}` ? <Binex/> : ''}
            {location.pathname === `/binex/${secondUrl}/${thirdUrl}` ? <Binex/> : ''}
            {location.pathname === `/bioMedicine/${secondUrl}` ? <BioMedicineCDMO/> : ''}
            {location.pathname === `/bioMedicine/${secondUrl}/${thirdUrl}` ? <BioMedicineCDMO/> : ''}
            {location.pathname === `/syntheticMedicine/${secondUrl}` ? <SyntheticMedicine/> : ''}
            {location.pathname === `/syntheticMedicine/${secondUrl}/${thirdUrl}` ? <SyntheticMedicine/> : ''}
            {location.pathname === `/syntheticMedicine/${secondUrl}/${thirdUrl}/${id}` ? <SyntheticMedicine/> : ''}
            {location.pathname === `/ethical/${secondUrl}` ? <EthicalManagement/> : ''}
            {location.pathname === `/ethical/${secondUrl}/${thirdUrl}` ? <EthicalManagement/> : ''}
            {location.pathname === `/recruitmentInfomation/${secondUrl}` ? <RecruitmentInformation/> : ''}
            {location.pathname === `/invest/${secondUrl}` ? <InvestInfomation/> : ''}
            {location.pathname === `/invest/${secondUrl}/${thirdUrl}` ? <InvestInfomation/> : ''}
            {location.pathname === '/privacy'? <Privacy/> : ''}
            {location.pathname !== '/' ? <Footer/> : ''}
        </div>
        </>
    )
}

export default LayOut;