import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { header_binex, header_binex_about, header_biomedicine_cdo, header_biomedicine_cmo, header_ehthical_1, header_ehthical_2, header_syntheticMedicine_1, header_syntheticMedicine_2 } from "../../../../data/headerData";
import { getCookie } from "../../../../util/cookie/cookies";
import { Lang } from "./lang";

const Header_Moblie = () => {
    const scrollRef = useRef<any>(null)
    const location = useLocation()
    const navigate = useNavigate()
    const lang = getCookie('BINEXLANG')
    const [state, setState] = useState<boolean>(false)
    const handleTransBinex = (type : string, mainPath : string, subPath : string, path : string) => {
        if(type==='second'){navigate(`/${mainPath}/${path}`)}
        else {navigate(`/${mainPath}/${subPath}/${path}`)}
    }
    useEffect(()=>{setState(false)},[location])
    useEffect(()=>{scrollRef.current.scrollTo({top : 0, behavior : 'smooth'})},[lang])
    return(
        <>
        <div className={state?'header_mo active':'header_mo'}>
            <div className="top">
                <h1>
                    <span onClick={()=>navigate('/')} style={{cursor: 'pointer'}}>
                        logo
                    </span>
                </h1>
                <button className="hamburger" onClick={()=>setState(prev=>!prev)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
            </div>
            <div className="nav" ref={scrollRef}>
                <ul>
                    <li>
                        <span>
                            <span>{lang==='Kr'?'바이넥스':'Binex'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li>
                                <span onClick={()=>navigate('/binex/about/overView')}>{lang==='Kr'?'회사소개':'Company'}</span>
                                <ul>
                                    {header_binex_about.map((x:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>handleTransBinex('third', 'binex', 'about', x.path)}>
                                            {lang==='Kr'? x.title_kr:x.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            {header_binex.map((x:{title_kr:string;title_en:string;path:string;},index:number)=>(
                            <li key={index} onClick={()=>handleTransBinex('second','binex', '', x.path)}>
                                <span>{lang==='Kr'? x.title_kr:x.title_en}</span>
                            </li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        <span>
                            <span>{lang==='Kr'?'바이오의약품 CDMO':'Biologics CDMO'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li>
                                <span onClick={()=>handleTransBinex('second', 'bioMedicine', '', 'bussinessOverview')}>{lang==='Kr'?'CDMO 사업개요':'CDMO Business'}</span>
                            </li>
                            <li>
                                <span onClick={()=>navigate('/bioMedicine/cdmoservice?tab=1')}>CDO</span>
                                {/* <ul>
                                    {header_biomedicine_cdo.map((y:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=${y.path}`)}>
                                            {lang==='Kr'? y.title_kr:y.title_en}
                                        </li>
                                    ))}
                                </ul> */}
                                <ul>
                                    <li onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=1`)}>
                                        {lang==='Kr'? '세포주 개발':'Cell Line Development'}
                                    </li>
                                    <li onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=2`)}>
                                        {lang==='Kr'? '공정 개발':'Process Development'}
                                    </li>
                                    <li onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=3`)}>
                                        {lang==='Kr'? '분석법 개발':'Analytical Method Development'}
                                    </li>
                                    <li onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=5`)}>
                                        {lang==='Kr'? '연구용 시료생산':'Non-GMP Production'}
                                    </li>
                                    <li onClick={()=>navigate(`/bioMedicine/cdmoservice?tab=4`)}>
                                        {lang==='Kr'? '단백질 구조 및 특성분석 (바이오분석지원센터)':'Bio Analysis Center'}
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span onClick={()=>navigate('/bioMedicine/cdmoproduction?tab=1')}>CMO</span>
                                <ul>
                                    {header_biomedicine_cmo.map((y:{title_kr:string;title_en:string;path:string}, index:number) => (
                                        <li key={index} onClick={()=>navigate(`/bioMedicine/cdmoproduction?tab=${y.path}`)}>
                                            {lang==='Kr'? y.title_kr:y.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <span onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>Quality</span>
                                <ul>
                                    <li onClick={()=>navigate(`/bioMedicine/quailty?tab=1`)}>
                                        {lang==='Kr'? '품질시스템':'Quality System'}
                                    </li>
                                    <li onClick={()=>navigate(`/bioMedicine/quailty?tab=2`)}>
                                        {lang==='Kr'? '품질관리':'Quality Control'}
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>
                            <span>{lang === 'Kr' ? '합성의약품' : 'Chemical'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'search', 'productname')}>
                                <span>{lang === 'Kr'?"제품검색":'Products'}</span>
                            </li>
                            {lang === 'Kr' ? 
                            <>
                            <li>
                                <span onClick={()=>navigate('/syntheticMedicine/productAnnouncement/permitChange')}>제품 공지사항</span>
                                <ul>
                                    {header_syntheticMedicine_1.map((z:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'productAnnouncement', z.path)}>
                                            {lang==='Kr'? z.title_kr:z.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <span onClick={()=>navigate('/syntheticMedicine/probiitics/manufacturingFacility')}>Probiotics</span>
                                <ul>
                                    {header_syntheticMedicine_2.map((z:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>handleTransBinex('third', 'syntheticMedicine', 'probiitics', z.path)}>
                                            {lang==='Kr'? z.title_kr:z.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li onClick={()=>handleTransBinex('second', 'syntheticMedicine', '', 'busanFactory')}>
                                <span>제조시설(부산공장)</span>
                            </li>
                            </> : ''
                            }
                        </ul>
                    </li>
                    <li>
                        <span>
                            <span>{lang==='Kr'?'투자정보':'Investment'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            {lang === 'Kr' ?
                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'electronicDisclosure')}>
                                <span>{lang==='Kr'?'전자공시':'Electronic Disclosure'}</span>
                            </li>
                            : ''
                            }
                            {lang === 'Kr' ?
                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'electronicNotice')}>
                                <span>{lang==='Kr'?'전자공고':'Electric Notice'}</span>
                            </li>
                            : ''
                            }
                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'financialInformation')}>
                                <span>{lang==='Kr'?'재무정보':'Financial Information'}</span>
                            </li>
                            <li>
                                <span onClick={()=>navigate('/invest/stockInformation/stockPrice')}>{lang==='Kr'?'주식정보':'Stock Information'}</span>
                                <ul>
                                    <li onClick={()=>navigate('/invest/stockInformation/stockPrice')}>{lang==='Kr'?'주가정보':'Stock Price Information'}</li>
                                    <li onClick={()=>navigate('/invest/stockInformation/stockStatus')}>{lang==='Kr'?'주식현황':'Stock Status'}</li>
                                    <li onClick={()=>navigate('/invest/stockInformation/general')}>{lang==='Kr'?'일반현황':'General Information'}</li>
                                </ul>
                            </li>
                            <li onClick={()=>handleTransBinex('second', 'invest', '', 'internalInformationManagement')}>
                                <span>{lang==='Kr' ? '내부정보관리규정':'Internal Information Control Regulation'}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>
                            <span>{lang==='Kr'?'윤리경영':'Ethical Management'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li>
                                <span onClick={()=>navigate('/ethical/codeOfehicsAndimplement/codeOfethics')}>{lang==='Kr'?'윤리강령 및 실천지침':'Code of Ethics and Pratice Guidelines'}</span>
                                <ul>
                                    {header_ehthical_1.map((a:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>handleTransBinex('third', 'ethical', 'codeOfehicsAndimplement', a.path)}>
                                            {lang==='Kr'? a.title_kr:a.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                            <li>
                                <span onClick={()=>navigate('/ethical/reportingAndprocedure/reportGuide')}>{lang==='Kr'?'제보안내 및 절차':'Reporting and Process'}</span>
                                <ul>
                                    {header_ehthical_2.map((a:{title_kr:string;title_en:string;path:string},index:number)=>(
                                        <li key={index} onClick={()=>handleTransBinex('third', 'ethical', 'reportingAndprocedure', a.path)}>
                                            {lang==='Kr'? a.title_kr:a.title_en}
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span>
                            <span>{lang==='Kr' ? '채용정보':'Recruitment'}</span>
                            <span><img src="/assets/images/header_subArrow.png" alt="arrow"/></span>
                        </span>
                        <ul className="mo_sub_menu">
                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'idealTalent')}>
                                <span>{lang==='Kr' ? '인재상':'Ideal Candidate'}</span>
                            </li>
                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'benefits')}>
                                <span>{lang==='Kr' ? '복리후생':'Benefits'}</span>
                            </li>
                            <li onClick={()=>handleTransBinex('second', 'recruitmentInfomation', '', 'employment')}>
                                <span>{lang==='Kr' ? '채용안내':'Careers'}</span>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div className="rightBox">
                    <Lang type={'mo'} setState={''}/>
                </div>
            </div>
        </div>
        </>
    )
}

export default Header_Moblie;