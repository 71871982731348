import React from "react";
import { useTranslation } from "react-i18next";

export const InternalInformationManagement = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3>
                    <span>{t("invest_internal_information_title")}</span>
                </h3>
                <ul>
                    <li>
                        <h4>{t("invest_internal_information_main_1")}</h4>
                        <div>
                            <span>{t("invest_internal_information_sub_1")}</span>
                            <p>
                            {t("invest_internal_information_content_1")}
                            </p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_2")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_2")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_3")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_4")}
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("invest_internal_information_content_5")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_3")}</span>
                            <p>{t("invest_internal_information_content_6")}</p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("invest_internal_information_main_2")}</h4>
                        <div>
                            <span>{t("invest_internal_information_sub_4")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_7")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_8")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_5")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_9")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_10")}
                                    <ol className="subNumbList">
                                        <li>
                                            <span>1</span>
                                            {t("invest_internal_information_content_11")}
                                        </li>
                                        <li>
                                            <span>2</span>
                                            {t("invest_internal_information_content_12")}
                                        </li>
                                        <li>
                                            <span>3</span>
                                            {t("invest_internal_information_content_13")}
                                        </li>
                                        <li>
                                            <span>4</span>
                                            {t("invest_internal_information_content_14")}
                                        </li>
                                        <li>
                                            <span>5</span>
                                            {t("invest_internal_information_content_15")}
                                        </li>
                                        <li>
                                            <span>6</span>
                                            {t("invest_internal_information_content_16")}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_17")}
                                    <ol className="subNumbList">
                                        <li>
                                            <span>1</span>
                                            {t("invest_internal_information_content_18")}
                                        </li>
                                        <li>
                                            <span>2</span>
                                            {t("invest_internal_information_content_19")}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("invest_internal_information_content_20")}
                                </li>
                                <li>
                                    <span>⑤</span>
                                    {t("invest_internal_information_content_21")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_6")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_22")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_23")}
                                    <ol className="subNumbList">
                                        <li>
                                            <span>1</span>
                                            {t("invest_internal_information_content_24")}
                                        </li>
                                        <li>
                                            <span>2</span>
                                            {t("invest_internal_information_content_25")}
                                        </li>
                                        <li>
                                            <span>3</span>
                                            {t("invest_internal_information_content_26")}
                                        </li>
                                        <li>
                                            <span>4</span>
                                            {t("invest_internal_information_content_27")}
                                        </li>
                                    </ol>
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_7")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_28")}
                                    <ol className="subNumbList">
                                        <li>
                                            <span>1</span>
                                            {t("invest_internal_information_content_29")}
                                        </li>
                                        <li>
                                            <span>2</span>
                                            {t("invest_internal_information_content_30")}
                                        </li>
                                        <li>
                                            <span>3</span>
                                            {t("invest_internal_information_content_31")}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_32")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_7_2")}</span>
                            <p>
                            {t("invest_internal_information_content_33")}
                            </p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_7_3")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_34")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_35")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_36")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_8")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_37")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_38")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_39")}
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h4>{t("invest_internal_information_main_3")}</h4>
                        <div>
                            <span>{t("invest_internal_information_sub_9")}</span>
                            <p>{t("invest_internal_information_content_40")}</p>
                            <ol className="subNumbList">
                                <li>
                                    <span>1</span>
                                    {t("invest_internal_information_content_41")}
                                </li>
                                <li>
                                    <span>2</span>
                                    {t("invest_internal_information_content_42")}
                                </li>
                                <li>
                                    <span>3</span>
                                    {t("invest_internal_information_content_43")}
                                </li>
                                <li>
                                    <span>4</span>
                                    {t("invest_internal_information_content_44")}
                                </li>
                                <li>
                                    <span>5</span>
                                    {t("invest_internal_information_content_45")}
                                </li>
                                <li>
                                    <span>6</span>
                                    {t("invest_internal_information_content_46")}
                                </li>
                                <li>
                                    <span>7</span>
                                    {t("invest_internal_information_content_47")}
                                </li>
                                <li>
                                    <span>8</span>
                                    {t("invest_internal_information_content_48")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_9_2")}</span>
                            <p>
                            {t("invest_internal_information_content_49")}
                            </p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_10")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_50")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_51")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_10_2")}</span>
                            <p>{t("invest_internal_information_content_52")}</p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_11")}</span>
                            <p>{t("invest_internal_information_content_53")}</p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_12")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_54")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_55")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_56")}
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("invest_internal_information_content_57")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_12_2")}</span>
                            <p>{t("invest_internal_information_content_58")}</p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_13")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_59")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_60")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_61")}
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("invest_internal_information_content_62")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_13_2")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_63")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_64")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_13_3")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_65")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_66")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_67")}
                                </li>
                            </ol>
                        </div>
                    </li>
                    <li>
                        <h4>{t("invest_internal_information_main_4")}</h4>
                        <div>
                            <span>{t("invest_internal_information_sub_14")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_68")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_69")}
                                </li>
                                <li>
                                    <span>③</span>
                                    {t("invest_internal_information_content_70")}
                                    <ol className="subNumbList">
                                        <li>
                                            <span>1</span>
                                            {t("invest_internal_information_content_71")}
                                        </li>
                                        <li>
                                            <span>2</span>
                                            {t("invest_internal_information_content_72")}
                                        </li>
                                        <li>
                                            <span>3</span>
                                            {t("invest_internal_information_content_73")}
                                        </li>
                                        <li>
                                            <span>4</span>
                                            {t("invest_internal_information_content_74")}
                                        </li>
                                        <li>
                                            <span>5</span>
                                            {t("invest_internal_information_content_75")}
                                        </li>
                                    </ol>
                                </li>
                                <li>
                                    <span>④</span>
                                    {t("invest_internal_information_content_76")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_15")}</span>
                            <p>{t("invest_internal_information_content_77")}</p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_16")}</span>
                            <p>{t("invest_internal_information_content_78")}</p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("invest_internal_information_main_5")}</h4>
                        <div>
                            <span>{t("invest_internal_information_sub_17")}</span>
                            <ol className="numbList">
                                <li>
                                    <span>①</span>
                                    {t("invest_internal_information_content_79")}
                                </li>
                                <li>
                                    <span>②</span>
                                    {t("invest_internal_information_content_80")}
                                </li>
                            </ol>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_18")}</span>
                            <p>{t("invest_internal_information_content_81")}</p>
                        </div>
                        <div>
                            <span>{t("invest_internal_information_sub_19")}</span>
                            <p>{t("invest_internal_information_content_82")}</p>
                        </div>
                    </li>
                    <li>
                        <h4>{t("invest_internal_information_sub_20")}</h4>
                        <p><strong>{t("invest_internal_information_content_83")}</strong></p>
                    </li>
                </ul>
            </div>
        </div>
        </>
    )
}