import React from "react";
import { useTranslation } from "react-i18next";

export const StockStatus = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3><span>{t("invest_stock_status_title")}</span></h3>

                <div>
                    <h4>
                        &nbsp;
                        <span>{t("invest_stock_status_content_1")}</span>
                    </h4>
                    <div className="tableWrap">

                        <table>
                            <thead>
                                <tr>
                                    <th>{t("invest_stock_status_content_2")}</th>
                                    <th>{t("invest_stock_status_content_3")}</th>
                                    <th>{t("invest_stock_status_content_4")}</th>
                                    <th>{t("invest_stock_status_content_5")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("invest_stock_status_content_6")}</td>
                                    <td>31,761,048</td>
                                    <td>-</td>
                                    <td>31,761,048</td>
                                </tr>
                                <tr>
                                    <td>{t("invest_stock_status_content_7")}</td>
                                    <td>836,512</td>
                                    <td>-</td>
                                    <td>836,512</td>
                                </tr>
                                <tr>
                                    <td>{t("invest_stock_status_content_8")}</td>
                                    <td>30,924,536</td>
                                    <td>-</td>
                                    <td>30,924,536</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}