
// 바이넥스
export const header_binex = [
    {title_kr : 'CI', title_en: 'CI', path : 'ci'},
    {title_kr : '연혁', title_en: 'History', path : 'history'},
    {title_kr : '오시는 길', title_en: 'Location', path : 'location'},
    {title_kr : '홍보센터', title_en : '', path : 'promotion-center'}
]

export const header_binex_about = [
    {title_kr : '회사개요', title_en: 'Overview', path : 'overView'},
    {title_kr : '비전', title_en: 'Vision', path : 'vision'},
    {title_kr : '인사말', title_en: 'Greeting', path : 'greetings'},
    {title_kr : '사업분야', title_en: 'Business', path : 'businessArea'},
]

// 바이오의약품 CDMO
export const header_biomedicine_cdo = [
    {title_kr : '세포주 개발', title_en: 'Cell Line Development', path : '1'},
    {title_kr : '공정 개발', title_en: 'Process Development', path : '2'},
    {title_kr : '분석법 개발', title_en: 'Analytical Method Development', path : '3'},
    {title_kr : '연구용 시료생산', title_en: 'Non-GMP Production', path : '5'},
    {title_kr : `단백질 구조 및 특성분석 <br/> (바이오분석지원센터)`, title_en: 'Bio Analysis Center', path : '4'}
]

export const header_biomedicine_cmo = [
    {title_kr : '원제의약품', title_en: 'Drug Substance', path : '1'},
    {title_kr : '완제의약품', title_en: 'Drug Product', path : '2'},
    // {title_kr : 'Quality', title_en: 'Quality', path : '3'},
]

export const header_syntheticMedicine_1 = [
    {title_kr : '허가 변경사항', title_en: 'Approval Status', path : 'permitChange'},
    {title_kr : '비만치료 약물', title_en: 'Anti-obesity Drug', path : 'obesityTreatmentMedicine'},
]

export const header_syntheticMedicine_2 = [
    {title_kr : '제조시설', title_en: 'Production Line', path : 'manufacturingFacility'},
    {title_kr : '미생물 동정 분석 서비스', title_en: 'Bacteria Identification Service', path : 'microbialIdentificationAnalysisService'},
]

export const header_ehthical_1 = [
    {title_kr : '윤리강령', title_en: 'Code of Ethics', path : 'codeOfethics'},
    {title_kr : '실천지침', title_en: 'Pratice Guidelines', path : 'practiceGuideline'}
]

export const header_ehthical_2 = [
    {title_kr : '제보안내', title_en: 'Introduction', path : 'reportGuide'},
    {title_kr : '제보자 보호', title_en: 'Whistleblower Protection', path : 'informantProtection'},
    {title_kr : '제보하기', title_en: 'Reporting', path : 'report'},
    {title_kr : '처리절차', title_en: 'Process', path : 'processingProcedure'},
]

export const header_invest = [
    {title_kr : '주가정보', title_en : 'Stock Price Information', path : 'stockPrice'},
    {title_kr : '주식현황', title_en : 'Stock Status', path : 'stockStatus'},
    {title_kr : '일반현황', title_en : 'General Information', path : 'general'},
]

