import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AdminLogin } from "./manager";

export const Admin = () => {
    const navigate = useNavigate()
    const storeId = sessionStorage.getItem('BinexKey');
    useEffect(()=>{
        if(storeId){
            navigate('/dotsAdmin/commoncode')
        }
    })
    return(
        <>
        <AdminLogin/>
        </>
    )
}