import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin";

export const adminContentsApiSlice = createApi({
  reducerPath: "adminContentsApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 제품정보가져오기
    getProduct : builder.mutation({
      query : (param : {user : string,page:any,size:number,keyword:string,sortColumn:string,sortOrder:string}) => {
        return{
          url : `/product/getProductList.php?user=${param.user}&page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    // 제품 저장
    setProduct : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/setProduct.php', method : 'post', body
        }
      }
    }),
    // 제품 상세보기
    getProductDetail : builder.mutation({
      query : (param : {idx : any}) => {
        return{
          url : `/product/getProductDetail.php?idx=${param.idx}`
        }
      }
    }),
    // 제품 수정
    updProduct : builder.mutation({
      query : (body: any) => {
        return{
          url : '/product/updProduct.php', method : 'post', body
        }
      }
    }),
    // 제품 공개여부 수정
    updProductActiveStatus : builder.mutation({
      query : (body : any) =>{
        return{
          url : `/product/updProductActiveStatus.php` , method : 'post' , body
        }
      }
    }),
    // 제품허가변경사항 및 비만치료 가져오기
    getProductPermissionHistory : builder.mutation({
      query  :(param : {user : string, userlang : string, permissionClass : string, page : any, size : number, keyword : string, sortColumn:string,sortOrder:string}) => {
        return{
          url : `/product/getProductPermissionHistory.php?user=${param.user}&userlang=${param.userlang}&permissionClass=${param.permissionClass}&page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    // 제품허가변경사항 및 비만치료 등록
    setProductPermissionHistory : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/setProductPermissionHistory.php', method:'post',body
        }
      }
    }),
    // 제품허가변경사항 및 비만치료 상세보기
    getProductPermissionHistoryDetail : builder.mutation({
      query : (param : {idx: any}) => {
        return{
          url : `/product/getProductPermissionHistoryDetail.php?idx=${param.idx}`
        }
      }
    }),
    // 제품허가변경사항 및 비만치료 상태수정
    updProductPermissionHistory : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProductPermissionHistory.php' , method : 'post' , body
        }
      }
    }),
    updProductPermissionActiveStatus : builder.mutation({
      query : (body : any) => {
        return{
          url : '/product/updProductPermissionActiveStatus.php', method : 'post', body
        }
      }
    })
  }),
});

export const {
  useGetProductMutation, useGetProductPermissionHistoryMutation, useSetProductPermissionHistoryMutation, useSetProductMutation, useGetProductDetailMutation, useGetProductPermissionHistoryDetailMutation, useUpdProductPermissionHistoryMutation,
  useUpdProductMutation, useUpdProductActiveStatusMutation, useUpdProductPermissionActiveStatusMutation
} = adminContentsApiSlice;