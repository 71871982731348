import React from "react";
import { useTranslation } from "react-i18next";

export const GeneralInformation = () => {
    const {t} = useTranslation()
    return(
        <>
        <div className="contentBox">
            <div>
                <h3><span>{t("invest_general_status_title")}</span></h3>
                
                <div>
                    <h4>
                        &nbsp;
                        <span>{t("invest_general_status_content_1")}</span>
                    </h4>
                    <div className="tableWrap">
                        <table>
                            <tbody>
                                <tr>
                                    <th>{t("invest_general_status_content_2")}</th>
                                    <td>{t("invest_general_status_content_3")}</td>
                                    <th>{t("invest_general_status_content_4")}</th>
                                    <td>1985.06.04</td>
                                    <th>{t("invest_general_status_content_5")}</th>
                                    <td>{t("invest_general_status_content_6")}</td>
                                </tr>
                                <tr>
                                    <th>{t("invest_general_status_content_7")}</th>
                                    <td>{t("invest_general_status_content_8")}</td>
                                    <th>{t("invest_general_status_content_9")}</th>
                                    <td>2001.08.07</td>
                                    <th>{t("invest_general_status_content_10")}</th>
                                    <td>{t("invest_general_status_content_11")}</td>
                                </tr>
                                <tr>
                                    <th>{t("invest_general_status_content_12")}</th>
                                    <td>608</td>
                                    <th>{t("invest_general_status_content_13")}</th>
                                    <td>{t("invest_general_status_content_14")}</td>
                                    <th>{t("invest_general_status_content_15")}</th>
                                    <td>{t("invest_general_status_content_16")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        </>
    )
}