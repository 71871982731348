import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
export interface Props {efficacyList : any}
export const SearchEfficacyBox:React.FC<Props> = ({efficacyList}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParam = new URLSearchParams(location.search)
    const efficacy : any = searchParam.get('efficacy') || '';
    const handleSelctEfficacy = (efficacy : string) => {
        navigate(`/syntheticMedicine/search/productefficacy?page=1&size=10&efficacy=${efficacy}`)
    }
    return( 
        <>
        <div className="grayBox efficacy">
            <ul>
                {efficacyList?.map((list:any) => (
                <li className={parseInt(efficacy )=== list?.efficacyId ? 'active' : ''} onClick={()=>handleSelctEfficacy(list?.efficacyId)}>
                    <span>{list?.efficacyNameKr}({list?.productCount})</span>
                </li>
                ))}
            </ul>
        </div>
        </>
    )
}