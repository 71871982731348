import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getCookie } from "../../../../../util/cookie/cookies";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const BusinessArea = () => {
    const lang = getCookie('BINEXLANG')
    const {t} = useTranslation()
    const slickRef1 = useRef<any>(null)
    const slickRef2 = useRef<any>(null)
    const slickRef3 = useRef<any>(null)
    const [state, setState] = useState<boolean>(false)
    const scrollRef = {
        songDo : useRef<any>(null), osong : useRef<any>(null), busan : useRef<any>(null)
    }
    const [picState, setPicState] = useState<any>({pic1 : 1, pic2 : 1, pic3 : 1})
    const handleScroll = (factory : string) => {
        if(factory === 'songDo'){ scrollRef.songDo.current.scrollIntoView({behavior : 'smooth', block : 'start', inline : 'nearest'})}
        else if(factory === 'osong'){ scrollRef.osong.current.scrollIntoView({behavior : 'smooth', block : 'start', inline : 'nearest'})}
        else if(factory === 'busan'){ scrollRef.busan.current.scrollIntoView({behavior : 'smooth', block : 'start', inline : 'nearest'})}
    }
    const prev1 = useCallback(()=>slickRef1.current.slickPrev(), [])
    const next1 = useCallback(()=>slickRef1.current.slickNext(), [])
    const prev2 = useCallback(()=>slickRef2.current.slickPrev(), [])
    const next2 = useCallback(()=>slickRef2.current.slickNext(), [])
    const prev3 = useCallback(()=>slickRef3.current.slickPrev(), [])
    const next3 = useCallback(()=>slickRef3.current.slickNext(), [])
    return(
        <>
        <div className="contentBox">
            <div>
                {lang === 'Kr' ? 
                <h3>
                    <span>바이넥스는 지속적인 품질향상을 통해 </span>
                    건강한 사회를 만들고자 노력하고 있습니다.
                </h3>
                : 
                <h3>Binex is striving to create a healthy society through <span>continuous quality improvement</span></h3>
                    
                }

                <div className="tabBtnBox">
                    <div>
                        <div onClick={()=>setState(false)}>
                            <button className={!state?'active':''}>{t("businessarea_title_1")}</button>
                        </div>
                        <div onClick={()=>setState(true)}>
                            <button className={state?'active':''}>{t("businessarea_title_2")}</button>
                        </div>
                    </div>
                </div>
                

                <div className="tabContentBox">
                    <div className={!state?'active':''}>
                        <div>
                            <div className="imgBox left">
                                <img src="/assets/images/bio_medicine.png" alt="바이오의약품"/>
                            </div>
                            <div className="right">
                                <div>
                                    <div>
                                        <h4>{t("businessarea_tab_1")}</h4>
                                        <div>
                                            <button onClick={()=>handleScroll('songDo')}>{t("businessarea_factory_1")}</button>
                                            <button onClick={()=>handleScroll('osong')}>{t("businessarea_factory_2")}</button>
                                        </div>
                                    </div>
                                    {/* 여기는 송도공장 스크롤 pointer */}
                                    {lang === 'Kr' ? 
                                    <p ref={scrollRef.songDo}>
                                        다양한 바이오의약품(항체, 이중항체, Fc-fusion단백질, pDNA 등) 개발 및 GMP 생산 경험과 노하우를<br></br>
                                        기반으로 초기 세포주 개발부터 공정개발, GMP 생산, 품질시험, 허가 지원까지 바이오의약품 개발 전 주기를<br></br>
                                        지원하는 One-stop CDMO 서비스를 제공합니다.
                                    </p>
                                    : 
                                    <p ref={scrollRef.songDo}>Based on our diverse experience and expertise in the development and GMP production of various biopharmaceuticals including antibodies, bispecific antibodies, Fc-fusion proteins, and pDNA, we provide a comprehensive one-stop CDMO service that supports the entire process of biopharmaceutical development, from initial cell line development to process development, GMP production, quality testing, and regulatory support for approval. </p>
                                        
                                    }

                                    <p>
                                    {t("businessarea_cotent_2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sliderWrap songdo">
                            <ul>
                            <Slider {...setting} ref={slickRef1}>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_songdo1.jpg`} alt="오송"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_songdo2.jpg`} alt="오송"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_songdo3.jpg`} alt="오송"/>
                                    </div>
                                </li>
                            </Slider>
                            </ul>
                            {/* <!-- slider Fin --> */}

                            <div className="btnBox">
                                <button className="prevBtn" onClick={prev1}></button>
                                <button className="nextBtn" onClick={next1}></button>
                            </div>
                            {/* <!-- btnBox --> */}
                        </div>

                        <div className="sliderWrap osong" ref={scrollRef.osong}>
                            <ul>
                            <Slider {...setting} ref={slickRef2}>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_osong1.jpg`} alt="오송"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_osong2.jpg`} alt="오송"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_osong3.png`} alt="오송"/>
                                    </div>
                                </li>
                            </Slider>
                            </ul>
                            {/* <!-- slider Fin --> */}

                            <div className="btnBox">
                                <button className="prevBtn" onClick={prev2}></button>
                                <button className="nextBtn" onClick={next2}></button>
                            </div>
                            {/* <!-- btnBox --> */}
                        </div>
                    </div>
                    {/* <!-- tabContent_1 Fin --> */}

                    <div className={state?'active':''}>
                        <div>
                            <div className="imgBox left">
                                <img src="/assets/images/synthetic_medicines.png" alt="합성의약품"/>
                            </div>
                            <div className="right">
                                <div>
                                    <div>
                                        <h4>{t("businessarea_tab_2")}</h4>
                                        <div>
                                            <button onClick={()=>handleScroll('busan')}>{t("businessarea_factory_3")}</button>
                                        </div>
                                        {/* <!-- 슬라이드 영역으로 스크롤 --> */}
                                    </div>
                                    <p>
                                    {t("businessarea_cotent_3")}
                                    </p>
                                    <p ref={scrollRef.busan}>
                                    {t("businessarea_cotent_4")}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="sliderWrap busan">
                            <ul>
                            <Slider {...setting} ref={slickRef3}>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_busan1.png`} alt="부산"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_busan2.png`} alt="부산"/>
                                    </div>
                                </li>
                                <li>
                                    <div className="imgBox">
                                        <img src={`/assets/images/business_areas_busan3.png`} alt="부산"/>
                                    </div>
                                </li>
                            </Slider>
                            </ul>

                            <div className="btnBox">
                                <button className="prevBtn" onClick={prev3}></button>
                                <button className="nextBtn" onClick={next3}></button>
                            </div>
                            {/* <!-- btnBox --> */}
                        </div>
                    </div>
                    {/* <!-- tabContent_2 Fin --> */}
                </div>
            </div>
        </div>
        </>
    )
}

const setting = {
    dots : false,
    arrows : false,
    infinte : false,
    speed : 500,
    slidesToScroll: 1,
    slidesToShow: 1,
    vertical: false,
    autoplay: true,
    autoplaySpeed: 3000,
    // centerMode: true,
}