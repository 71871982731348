import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { MultiInputBox } from "../../../../../../components/contents/regist/multiInputBox";
import { SingleAttachedBox } from "../../../../../../components/contents/regist/singleAttachedPDFBox";
import { SingleInputBox } from "../../../../../../components/contents/regist/singleInputBox";
import { SingleTextAreaBox } from "../../../../../../components/contents/regist/singleTextAreaBox";
import { useGetInquiryDetailMutation } from "../../../../../../store/admin/adminReportingApiSlice";
import { RegistHeader } from "../regist-header/regist-header";
import { MultiAttachedFileBox } from "../../../../../../components/contents/multiAttachedFileBox";
import { InquiryStatusBox } from "../../../../../../components/contents/regist/inquiry_status_selectBox";

export const InquiryView = () => {
    const {id}:any = useParams()
    const [getInquiryDetailsApi] = useGetInquiryDetailMutation()
    const initData = {status : '', name : '', company : '', position : '', email : '', phone : '', date : '', contents : '', attachedFile : [], replyStatus : 'U'}
    const [data, setData] = useState<any>([])
    const getDetail = async() => {
        const result : any = await getInquiryDetailsApi({idx: id})
        if(result.data.result === true){
            setData((prev:any) => ({...prev, name : result.data.inquiry_name, company : result.data.inquiry_company_name, position : result.data.inquiry_position, email : result.data.inquiry_email,
            phone : result.data.inquiry_phone, date : result.data.inquiry_mdate, contents : result.data.inquiry_content, attachedFile : result.data.inquiry_pdsFile,
            replyStatus : result.data.inquiry_reply_status}))
        }
    }
    useEffect(()=>{
        getDetail()
    }, [id])
    return(
        <>
        <div className="contentBox upload">
            <h3>Content</h3>

            <RegistHeader title={'문의내용 상세보기'} backUrl={'/dotsAdmin/reporting/inquiry'} onSave={''}/>

            <table>
                <tbody>
                    <InquiryStatusBox status={data?.replyStatus}/>
                    <SingleInputBox title={'문의자명'} name={''} value={data?.name} setData={setData}/>
                    <MultiInputBox title={'회사&직급'} name={''} krValue={data?.company} enValue={data?.position} setData={setData}/>
                    <SingleInputBox title={'이메일'} name={''} value={data?.email} setData={setData}/>
                    <SingleInputBox title={'전화번호'} name={''} value={data?.phone} setData={setData}/>
                    <SingleInputBox title={'문의날짜'} name={''} value={data?.date} setData={setData}/>
                    <SingleTextAreaBox title={'문의내용'} value={data?.contents}/>
                    <MultiAttachedFileBox files={data?.attachedFile}/>
                </tbody>
            </table>
        </div>
        </>
    )
}