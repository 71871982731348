import React, { useLayoutEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { NavigationBox } from "../../../../components/subPage/navBox";
import { getCookie } from "../../../../util/cookie/cookies";
import { BusanFactory } from "./busanFactory";
import { ManufacturingFacility } from "./probiotics/manufacturingFacility";
import { MicrobialAnaylsisService } from "./probiotics/microbialAnalysisService";
import { PermitChange } from "./productAnnouncement/productAnnouncement";
import { PermitChangeView } from "./productAnnouncement/view-productAnnouncement";
import { ProductSearch } from "./synthetic-productSearch";

export const SyntheticMedicine = () => {
    const lang = getCookie('BINEXLANG')
    const location = useLocation()
    const {thirdUrl, id} = useParams()
    const [state, setState] = useState<{classname:string;title_kr:string;title_en:string}>({classname : '', title_kr : '',title_en:''})
    useLayoutEffect(()=>{
        switch(location.pathname){
            case '/syntheticMedicine/search/productname': setState((prev)=>({...prev, classname:'product_search product_name',title_kr:'제품검색',title_en:'Products'}));break;
            case '/syntheticMedicine/search/productefficacy': setState((prev)=>({...prev, classname:'product_search efficacy',title_kr:'제품검색',title_en:'Products'}));break;
            case '/syntheticMedicine/productAnnouncement/permitChange': setState((prev)=>({...prev, classname:'product_license_changes borad',title_kr:'허가 변경사항',title_en:''}));break;
            case `/syntheticMedicine/productAnnouncement/permitChange/${id}`: setState((prev)=>({...prev, classname:'product_license_changes_view boardView',title_kr:'허가 변경사항',title_en:''}));break;
            case '/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine': setState((prev)=>({...prev, classname:'obesity_treatment_drugs borad',title_kr:'비만치료 약물',title_en:''}));break;
            case `/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine/${id}`: setState((prev)=>({...prev, classname:'product_license_changes_view boardView',title_kr:'비만치료 약물',title_en:''}));break;
            case '/syntheticMedicine/probiitics/manufacturingFacility': setState((prev)=>({...prev, classname:'probiotics manufacturing_facility',title_kr:'제조시설',title_en:''}));break;
            case '/syntheticMedicine/probiitics/microbialIdentificationAnalysisService': setState((prev)=>({...prev, classname:'probiotics microbial_dynamic_analysis_service',title_kr:'미생물 동정 분석 서비스',title_en:''}));break;
            case '/syntheticMedicine/busanFactory': setState((prev)=>({...prev, classname:'busan',title_kr:'제조시설(부산공장)',title_en:''}));break;
        }
    },[location])
    return(
        <>
        <div className={`subPage synthetic_drugs ${state.classname}`}>
        {/* <!-- 제품명 검색 탭일 때 : product_name, 효능별 검색 탭일 때 : efficacy 클래스 추가 --> */}
            <div className="banner_area">
                <div>
                    <h2>{lang==='Kr'?state.title_kr:state.title_en}</h2>
                </div>
            </div>
            <NavigationBox/>
            {location.pathname === `/syntheticMedicine/search/${thirdUrl}` ? <ProductSearch/> : ''}
            {location.pathname === '/syntheticMedicine/productAnnouncement/permitChange' ? <PermitChange/> : ''}
            {location.pathname === `/syntheticMedicine/productAnnouncement/permitChange/${id}` ? <PermitChangeView/> : ''}
            {location.pathname === '/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine' ? <PermitChange/> : ''}
            {location.pathname === `/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine/${id}` ? <PermitChangeView/> : ''}
            {location.pathname === '/syntheticMedicine/probiitics/manufacturingFacility' ? <ManufacturingFacility/> : ''}
            {location.pathname === '/syntheticMedicine/probiitics/microbialIdentificationAnalysisService' ? <MicrobialAnaylsisService/> : ''}
            {location.pathname === '/syntheticMedicine/busanFactory' ? <BusanFactory/> : ''}
        </div>
        </>
    )
}