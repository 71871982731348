import React from "react";
import { useNavigate } from "react-router-dom";
import { setAdminInfo } from "../../../../store/admin/adminInfoSlice";
import { useAppDispatch, useAppSelector } from "../../../../store/storeHooks";

export const DashboardHeader = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const adminInfo = useAppSelector(state  => state.loginAdmin.adminInfo)
    const handleLogout = () => {
        sessionStorage.removeItem('BinexKey')
        sessionStorage.removeItem('GroupCode')
        dispatch(setAdminInfo({account : '', adminId : '', name : '', groupId : ''}))
        navigate('/dotsAdmin')
    }
    return(
        <>
        <div className="dotsHeader">
            <div className="header">
                <div>
                    <div>
                        <div className="profileImg"></div>
                        <div>
                            <span>{adminInfo.name}</span>
                            <i className="fa-regular fa-chevron-down"></i>
                        </div>
                    </div>
                    <div>
                        <span onClick={()=>window.open('https://bi-nex.com')}>홈페이지 바로가기<i className="fa-regular fa-chevron-right"></i></span>
                        <button onClick={handleLogout}>로그아웃</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}