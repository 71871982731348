import React from "react";
// All
export const firstNavItem = [
    {title_kr : '바이넥스', title_en : 'BINEX', location : 'binex' ,initUrl : '/binex/about/overView'},
    {title_kr : '바이오의약품 CDMO', title_en : 'Biologics CDMO', location : 'bioMedicine', initUrl : '/bioMedicine/bussinessOverview'},
    {title_kr : '합성의약품', title_en : 'Chemical', location : 'syntheticMedicine', initUrl : '/syntheticMedicine/search/productname'},
    {title_kr : '투자정보', title_en : 'Investment', location : 'invest', initUrl : '/invest/electronicDisclosure'},
    {title_kr : '윤리경영', title_en : 'Ethical Management', location : 'ethical', initUrl : '/ethical/codeOfehicsAndimplement/codeOfethics'},
    {title_kr : '채용정보', title_en : 'Recruitment', location : 'recruitmentInfomation', initUrl : '/recruitmentInfomation/idealTalent'},
    {title_kr : '문의하기', title_en : 'Inquiry', location : 'contactUs', initUrl : '/contactUs'}
]
//================================================

// (주) 바이넥스 
export const second_binexItem = [
    {title_kr : '회사소개', title_en : 'Company', location : 'about', initUrl : '/binex/about/overView'},
    {title_kr : 'CI', title_en : 'CI', location : 'ci', initUrl : '/binex/ci'},
    {title_kr : '연혁', title_en : 'History', location : 'history', initUrl : '/binex/history'},
    {title_kr : '오시는 길', title_en : 'Location', location : 'location', initUrl : '/binex/location'},
    {title_kr : '홍보센터', title_en : 'Promotion Center', location : 'promotion-center', initUrl : '/binex/promotion-center'},
]

// (주) 바이넥스 - 회사소개
export const third_aboutItem = [
    {title_kr:  '회사개요', title_en : 'Overview', location : 'overView', initUrl : '/binex/about/overView'},
    {title_kr:  '비전', title_en : 'Vision', location : 'vision', initUrl : '/binex/about/vision'},
    {title_kr:  '인사말', title_en : 'Greeting', location : 'greetings', initUrl : '/binex/about/greetings'},
    {title_kr:  '사업분야', title_en : 'Business', location : 'businessArea', initUrl : '/binex/about/businessArea'},
]
//================================================

// 바이오의약품 CDMO
export const second_bioMedicineItem = [
    {title_kr : 'CDMO 사업개요', title_en : 'CDMO Business', location : 'bussinessOverview', initUrl : '/bioMedicine/bussinessOverview'},
    {title_kr : 'CDO', title_en : 'CDO', location : 'cdmoservice', initUrl : '/bioMedicine/cdmoservice?tab=1'},
    {title_kr : 'CMO', title_en : 'CMO', location : 'cdmoproduction', initUrl : '/bioMedicine/cdmoproduction?tab=1'},
    {title_kr : 'Quailty', title_en : 'Quailty', location : 'quailty', initUrl : '/bioMedicine/quailty?tab=1'},
]
//================================================

// 합성의약품
export const second_syntheticMedicineItem = [
    {title_kr : '제품검색', title_en : 'Products', location : 'search', initUrl : '/syntheticMedicine/search/productname'},
    {title_kr : '제품 공지사항', title_en : '', location : 'productAnnouncement', initUrl : '/syntheticMedicine/productAnnouncement/permitChange'},
    {title_kr : 'Probiotics', title_en : '', location : 'probiitics', initUrl : '/syntheticMedicine/probiitics/manufacturingFacility'},
    {title_kr : '제조시설(부산공장)', title_en : '', location : 'busanFactory', initUrl : '/syntheticMedicine/busanFactory'},
]

// 합성의약품 - 제품허가사항변경
export const third_syntheticMedicineItem_1 = [
    {title_kr : '허가 변경사항', title_en : '', location : 'permitChange', initUrl : '/syntheticMedicine/productAnnouncement/permitChange'},
    {title_kr : '비만치료 약물', title_en : '', location : 'obesityTreatmentMedicine', initUrl : '/syntheticMedicine/productAnnouncement/obesityTreatmentMedicine'},
]

// 합성의약품 - ProbioTics
export const third_syntheticMedicineItem_2 = [
    {title_kr : '제조시설', title_en : '', location : 'manufacturingFacility', initUrl : '/syntheticMedicine/probiitics/manufacturingFacility'},
    {title_kr : '미생물 동정 분석 서비스', title_en : '', location : 'microbialIdentificationAnalysisService', initUrl : '/syntheticMedicine/probiitics/microbialIdentificationAnalysisService'},
]

//================================================

// 윤리경영
export const second_ethical = [
    {title_kr : '윤리강령 및 실천지침', title_en : 'Code of Ethics and Pratice Guidelines', location : 'codeOfehicsAndimplement', initUrl : '/ethical/codeOfehicsAndimplement/codeOfethics'},
    {title_kr : '제보안내 및 절차', title_en : 'Reporting and Process', location : 'reportingAndprocedure' , initUrl : '/ethical/reportingAndprocedure/reportGuide'}
]
// 윤리경령 - 운영강령 및 실천지침
export const third_ethical_1 = [
    {title_kr: '윤리강령', title_en : 'Code of Ethics', location : 'codeOfethics', initUrl : '/ethical/codeOfehicsAndimplement/codeOfethics'},
    {title_kr: '실천지침', title_en : 'Pratice Guidelines', location : 'practiceGuideline', initUrl : '/ethical/codeOfehicsAndimplement/practiceGuideline'},
]
// 윤리강령 - 제보하기
export const third_ethical_2 = [
    {title_kr: '제보안내', title_en : 'Introduction', location : 'reportGuide', initUrl:'/ethical/reportingAndprocedure/reportGuide'},
    {title_kr: '제보자 보호', title_en : 'Whistleblower Protection', location : 'informantProtection', initUrl:'/ethical/reportingAndprocedure/informantProtection'},
    {title_kr: '제보하기', title_en : 'Reporting', location : 'report', initUrl:'/ethical/reportingAndprocedure/report'},
    {title_kr: '처리절차', title_en : 'Process', location : 'processingProcedure', initUrl:'/ethical/reportingAndprocedure/processingProcedure'},
]

export const second_recruitmentInfomation = [
    {title_kr: '인재상', title_en : 'Ideal Candidate', location : 'idealTalent', initUrl : '/recruitmentInfomation/idealTalent'},
    {title_kr: '복리후생', title_en : 'Benefits', location : 'benefits', initUrl : '/recruitmentInfomation/benefits'},
    {title_kr: '채용안내', title_en : 'Careers', location : 'employment', initUrl : '/recruitmentInfomation/employment'},
]

//================================================

// 투자정보
export const second_invest = [
    {title_kr: '전자공시', title_en : '', location : 'electronicDisclosure', initUrl : '/invest/electronicDisclosure'},
    {title_kr: '전자공고', title_en : 'Electric Notice', location : 'electronicNotice', initUrl : '/invest/electronicNotice'},
    {title_kr: '재무정보', title_en : 'Financial Information', location : 'financialInformation', initUrl : '/invest/financialInformation'},
    {title_kr: '주식정보', title_en : 'Stock Information', location : 'stockInformation', initUrl : '/invest/stockInformation/stockPrice'},
    {title_kr: '내부정보관리규정', title_en : 'Internal Information Control Regulation', location : 'internalInformationManagement', initUrl : '/invest/internalInformationManagement'},
]
// 투자정보 - 주식정보
export const third_invest = [
    {title_kr: '주가정보', title_en : 'Stock Price Information', location : 'stockPrice', initUrl:'/invest/stockInformation/stockPrice'},
    {title_kr: '주식현황', title_en : 'Stock Status', location : 'stockStatus', initUrl:'/invest/stockInformation/stockStatus'},
    {title_kr: '일반현황', title_en : 'General Information', location : 'general', initUrl:'/invest/stockInformation/general'}
]