import React from "react";
import Select from 'react-select';
import styled from "styled-components";
export interface Props {value : string, setData : Function}
export const AdminSelectBox:React.FC<Props> = ({value, setData}) => {
    const StyledSelect = styled(Select)`
    width : 79%; font-size : 14px; 
    `;
    const handleChange = (e:any) => {
        setData((prev:any)=>({...prev, admin_groupId : e.value}))
    }
    return(
        <>
        <li>
            <label htmlFor="">{'그룹설정'}</label>
            <StyledSelect
                value={AdminGroupList?.find((x:any) => x.value === parseInt(value))}
                name="supplier"
                options={AdminGroupList}
                className="selectBox1"
                onChange={handleChange}
                isSearchable={true}
                placeholder={'그룹을 선택해주세요.'}
            />
        </li>
        </>
    )
}


export const AdminGroupList = [
    {value : 21, label : '마스터'},
    {value : 16, label : '바이넥스1'},
    {value : 17, label : '바이넥스2'},
    {value : 18, label : '바이넥스3'},
    {value : 19, label : '바이넥스4'},
    {value : 20, label : '바이넥스5'},
]