import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = "https://bi-nex.com/api/controller/admin";

export const adminCommonCodeApiSlice = createApi({
  reducerPath: "adminCommonCodeApiSlice",
  baseQuery: fetchBaseQuery({ baseUrl: baseUrl }),
  endpoints: (builder) => ({
    // 공용코드 가져오기
    getCommonCodeList : builder.mutation({
      query : (param : {codeId : string,page:any,size:number,keyword:string,sortColumn:string,sortOrder:string}) => {
        return{
          url : `/common/getCommonCodeList.php?codeId=${param.codeId}&page=${param.page}&size=${param.size}&sortColumn=${param.sortColumn}&sortOrder=${param.sortOrder}&keyword=${param.keyword}`
        }
      }
    }),
    //공용코드 저장하기
    setCommonCode : builder.mutation({
      query : (body : any) => {
        return{
          url: 'common/setCommonCode.php',method:'post',body
        }
      }
    }),
    // 공용코드 상세보기
    getCommonCodeDetail : builder.mutation({
      query : (param : {idx : any}) => {
        return{
          url : `common/getCommonCodeDetail.php?idx=${param.idx}`
        }
      }
    }),
    // 공용코드 수정
    updCommonCode : builder.mutation({
      query : (body  :any) => {
        return{
          url : 'common/updCommonCode.php', method : 'post', body
        }
      }
    }),
    // 공용코드 삭제
    delCommonCode : builder.mutation({
      query : (param : {id : string}) => {
        return{
          url : `common/delCommonCode.php?idx=${param.id}`
        }
      }
    })
  }),
});

export const {
  useGetCommonCodeListMutation, useSetCommonCodeMutation, useGetCommonCodeDetailMutation, useUpdCommonCodeMutation, useDelCommonCodeMutation
} = adminCommonCodeApiSlice;